import { RestApiError } from 'error/restError';
import axios from 'axios';
import qs from 'qs';
import cookie from 'helpers/cookie';

export function itemGet(payload) {
	const params = payload;

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/entity_item_get`,
		data: qs.stringify({ params }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}

export function itemAdd(payload) {
	const params = payload;

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/entity_item_add`,
		data: qs.stringify({ params }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}

export function itemDelete(payload) {
	const params = payload;

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/entity_item_delete`,
		data: qs.stringify({ params }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}

export function itemUpdate(payload) {
	const params = payload;

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/entity_item_update`,
		data: qs.stringify({ params }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			console.log(response.data);
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}
