import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';

/** Other components */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';

/** Sub components */

/** Actions */
import { vkAdsAccountsFetchStart } from 'actions/vkActions';

/** Helpers */
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const VkForm = ({
	cost,
	isFetching,
	SettingsModel,
	loadingState,
	setLoadingState,
	setSourceStepsCompletedState,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const [activeStep, setActiveStep] = useState(0);
	const [stepEditState, setStepEditState] = useState(false);
	const [vkAdsAccountIdState, setVkAdsAccountIdState] = useState(false);

	const vkAdsAccountIdSettings = useMemo(
		() => cost.sourceData.accountId || null,
	);

	const fetchAdsAccounts = () => dispatch(vkAdsAccountsFetchStart({}));

	useEffect(() => {
		fetchAdsAccounts();
	}, []);

	const vkAdsAccountsIsFetching = useSelector(state => {
		return !!state.vk_ads_accounts.fetching;
	});

	const vkAdsAccountList = useSelector(state => state.vk_ads_accounts.items);

	const vkAccountIdSave = () => {
		const { name: vkAdsAccountName = '' } = vkAdsAccountList.find(
			account => account.id === vkAdsAccountIdState,
		);

		SettingsModel.costs = {
			id: cost.id,
			source: CONFIG.COST_SOURCE_VKONTAKTE,
			sourceData: {
				accountId: vkAdsAccountIdState,
				accountName: vkAdsAccountName,
			},
		};

		setStepEditState(false);

		setLoadingState({
			...loadingState,
			costs: { [cost.id]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(false);
	};

	const backToVkAdsAccount = () => {
		setStepEditState(0);
	};

	const vkAdsAccountIdEdit = useCallback(event => {
		setVkAdsAccountIdState(event.target.value);
	});

	useEffect(() => {
		if (vkAdsAccountIdSettings && stepEditState !== 0 && !isFetching) {
			setActiveStep(1);
		}

		if (stepEditState === 0) setActiveStep(0);
	});

	useEffect(() => {
		setSourceStepsCompletedState(!!vkAdsAccountIdSettings);
	}, [vkAdsAccountIdSettings]);

	const vkAdsAccountValue = useMemo(() => {
		return vkAdsAccountIdState !== false
			? vkAdsAccountIdState
			: vkAdsAccountIdSettings
				? vkAdsAccountIdSettings
				: 'default';
	}, [vkAdsAccountIdState, vkAdsAccountIdSettings]);

	const vkAccountIdSaveDisabled = useMemo(() => {
		return (
			!vkAdsAccountIdState ||
			vkAdsAccountIdState == vkAdsAccountIdSettings ||
			vkAdsAccountIdState == 'default' ||
			isFetching
		);
	}, [vkAdsAccountIdState, vkAdsAccountIdSettings, isFetching]);

	return (
		<Stepper activeStep={activeStep} orientation="vertical">
			<Step key={0}>
				{vkAdsAccountIdSettings &&
					vkAdsAccountList.some(
						account => account.id == vkAdsAccountIdSettings,
					) ? (
					<StepLabel>
						{
							vkAdsAccountList.find(
								account => account.id == vkAdsAccountIdSettings,
							)['name']
						}
						{vkAdsAccountIdSettings ? (
							<Button onClick={backToVkAdsAccount}>
								<EditIcon fontSize="small" />
							</Button>
						) : null}
					</StepLabel>
				) : (
					<StepLabel>Vk Ads account</StepLabel>
				)}
				<StepContent>
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">
							ads account
						</InputLabel>
						<Select
							label="ads account"
							style={{ width: 450 }}
							onChange={vkAdsAccountIdEdit}
							inputProps={{ 'aria-label': 'Without label' }}
							value={vkAdsAccountValue}
						>
							<MenuItem value="default">-</MenuItem>
							{vkAdsAccountList.map((account, i) => (
								<MenuItem value={account.id} key={account.id}>
									{`${account.name}`}
								</MenuItem>
							))}
							{vkAdsAccountsIsFetching ? (
								<CircularProgressStyled size={24} />
							) : null}
						</Select>
					</FormControl>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={vkAccountIdSave}
								className={classes.button}
								disabled={vkAccountIdSaveDisabled}
							>
								{t('Save')}
								<SaveIcon />
								{isFetching ? <CircularProgressStyled size={24} /> : null}
							</Button>
						</div>
					</div>
				</StepContent>
			</Step>
		</Stepper>
	);
};

export default VkForm;
