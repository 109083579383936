import { RestApiError } from 'error/restError';

import axios from 'axios';
import cookie from 'helpers/cookie';

export function yaAuthRevoke() {
	const data = {};

	return axios({
		method: 'get',
		url: `${process.env.REACT_APP_DOMAIN}/ya_auth/revoke`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
		data,
	}).then(response => {
		if (!response.data.success) {
			throw new RestApiError(response.data);
		}

		return response.data.data;
	});
}

export function yaAuthCheck() {
	const data = {};

	return axios({
		method: 'get',
		url: `${process.env.REACT_APP_YA_API}/check_auth/`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
		data,
	}).then(response => {
		if (!response.data.success) {
			throw new RestApiError(response.data);
		}

		return response.data.data;
	});
}

export function getAdsAccounts() {
	const data = {};

	return axios({
		method: 'get',
		url: `${process.env.REACT_APP_YA_API}/adaccounts/`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
		data,
	}).then(response => {
		if (!response.data.success) {
			throw new RestApiError(response.data);
		}

		return response.data.data;
	});
}
