import {
	AUTH_CHECK_GA_FETCH_START,
	AUTH_CHECK_GA_FETCH_SUCCESS,
	AUTH_CHECK_GA_FETCH_ERROR,
	AUTH_CHECK_FB_FETCH_START,
	AUTH_CHECK_FB_FETCH_SUCCESS,
	AUTH_CHECK_FB_FETCH_ERROR,
	AUTH_CHECK_VK_FETCH_START,
	AUTH_CHECK_VK_FETCH_SUCCESS,
	AUTH_CHECK_VK_FETCH_ERROR,
	AUTH_CHECK_YA_FETCH_START,
	AUTH_CHECK_YA_FETCH_SUCCESS,
	AUTH_CHECK_YA_FETCH_ERROR,
} from 'actions/authActions';

export const initialState = {
	ga: false,
	fb: false,
	vk: false,
	ya: false,
};

export default (state = { ...initialState, fetching: false }, action) => {
	switch (action.type) {
		case AUTH_CHECK_GA_FETCH_START:
			return {
				...state,
				fetching: true,
			};

		case AUTH_CHECK_GA_FETCH_SUCCESS:
			return {
				...state,
				ga: true,
				fetching: false,
			};

		case AUTH_CHECK_GA_FETCH_ERROR:
			return {
				...state,
				ga: false,
				fetching: false,
			};

		case AUTH_CHECK_FB_FETCH_START:
			return {
				...state,
				fetching: true,
			};

		case AUTH_CHECK_FB_FETCH_SUCCESS:
			return {
				...state,
				fb: true,
				fetching: false,
			};

		case AUTH_CHECK_FB_FETCH_ERROR:
			return {
				...state,
				fb: false,
				fetching: false,
			};
		case AUTH_CHECK_VK_FETCH_START:
			return {
				...state,
				fetching: true,
			};

		case AUTH_CHECK_VK_FETCH_SUCCESS:
			return {
				...state,
				vk: true,
				fetching: false,
			};

		case AUTH_CHECK_VK_FETCH_ERROR:
			return {
				...state,
				vk: false,
				fetching: false,
			};

		case AUTH_CHECK_YA_FETCH_START:
			return {
				...state,
				fetching: true,
			};

		case AUTH_CHECK_YA_FETCH_SUCCESS:
			return {
				...state,
				ya: true,
				fetching: false,
			};

		case AUTH_CHECK_YA_FETCH_ERROR:
			return {
				...state,
				ya: false,
				fetching: false,
			};
		default:
			return state;
	}
};
