import React, {
	Component,
	useState,
	forwardRef,
	useEffect,
	useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Icons  */

/** Other components */
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Badge from '@material-ui/core/Badge';

/** Custom components */
import TableStyled from 'components/common/TableStyled';
import TableCellStyled from 'components/common/TableCellStyled';

/** Sub components */
import CostItem from './CostItem';

/** Helpers */
import { fitWindow } from 'helpers/utils';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({}));

const CostsList = ({
	SettingsModel,
	setLoadingState,
	loadingState,
	isFetching,
	costs,
}) => {
	const classes = useStyles();

	const costDelete = useCallback(id => {
		SettingsModel.costDelete(id);

		setLoadingState({
			...loadingState,
			costs: { [id]: true },
		});

		SettingsModel.saveSettings();
	});

	return (
		<TableContainer>
			<TableStyled>
				<TableHead>
					<TableRow>
						<TableCellStyled></TableCellStyled>
						<TableCellStyled>Cost Source</TableCellStyled>
						<TableCellStyled></TableCellStyled>
						<TableCellStyled></TableCellStyled>
						<TableCellStyled></TableCellStyled>
						<TableCellStyled></TableCellStyled>
						<TableCellStyled>GA Data Set</TableCellStyled>
						<TableCellStyled>GA Account</TableCellStyled>
						<TableCellStyled>GA Counter</TableCellStyled>
						<TableCellStyled>GA View</TableCellStyled>
						<TableCellStyled></TableCellStyled>
						<TableCellStyled></TableCellStyled>
					</TableRow>
				</TableHead>

				<TableBody>
					{costs.map((cost, i) => (
						<CostItem
							cost={cost}
							isFetching={!!isFetching('costs', cost.id)}
							SettingsModel={SettingsModel}
							loadingState={loadingState}
							setLoadingState={setLoadingState}
							costDelete={costDelete.bind(null, cost.id)}
							key={i}
						/>
					))}
				</TableBody>
			</TableStyled>
		</TableContainer>
	);
};

export default CostsList;
