import React, {
	Component,
	useState,
	forwardRef,
	useEffect,
	useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import ViewColumn from '@material-ui/icons/ViewColumn';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

/** Other components */
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';

/** Custom components */

/** Actions */
import { logsFetchStart } from 'actions/logsActions';

/** Helpers */
import { fitWindow } from 'helpers/utils';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	card: {
		maxWidth: 600,
	},
	cardBullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	cardTitle: {
		fontSize: 14,
	},
	cardPos: {
		marginBottom: 14,
	},
	table: {
		'& .MuiToolbar-root': {
			height: 40,
		},
		'& .MuiToolbar-regular': {
			minHeight: 0,
		},
		'& .MuiTableCell-root': {
			padding: 15,
		},
	},
}));

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Logs = () => {
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const logs = useSelector(state => state.logs);

	const dispatch = useDispatch();
	const fetchLogsList = () => dispatch(logsFetchStart());

	useEffect(() => {
		fetchLogsList();
	}, []);

	const refreshLog = useCallback(() => {
		fetchLogsList();
	}, []);

	const renderStatusValue = useCallback((code, error_code) => {
		if (code === 'SUCCESS') return '✅';

		if (error_code == 'WARNING_MP_PARAMS_NO_CLA') return '💡';

		if (RegExp('(.*)ERROR(.*)').test(code)) return '🚨';

		if (code === 'SUSPEND' || code === 'START' || code == 'NEW') return '⌛';

		if (code === 'STARTED') return '⌛';
	});

	const renderTrStatus = useCallback(code => {
		if (code === 'COMPLETED') return <Chip label={code} color="primary" />;
		if (code === 'CANCELED') return <Chip label={code} color="secondary" />;
		return <Chip label={code} color="secondary" />;
	});

	/**
	 * Origin constants in
	 * nodejs container api/helpers/Constants
	 */
	const errorTypes = {
		ERROR_REDIS: t('ERROR_REDIS'),
		ERROR_MP_PARAMS: t('ERROR_MP_PARAMS'),
		WARNING_MP_PARAMS_NO_CLA: t('WARNING_MP_PARAMS_NO_CLA'),
		ERROR_MP_PARAMS_CID_REQUIRED: t('ERROR_MP_PARAMS_CID_REQUIRED'),
		ERROR_MP_PARAMS_TID_REQUIRED: t('ERROR_MP_PARAMS_TID_REQUIRED'),
		ERROR_MP_PARAMS_CID_WRONG_FORMAT: t('ERROR_API_DB'),
		ERROR_MP_PARAMS_COST_REQUIRED: t('ERROR_MP_PARAMS_COST_REQUIRED'),
		ERROR_API_DB: t('ERROR_API_DB'),
		ERROR_B24_CALL: t('ERROR_B24_CALL'),
		ERROR_GET_SETTINGS: t('ERROR_GET_SETTINGS'),
		ERROR_SEND_TO_GA: t('ERROR_SEND_TO_GA'),
		ERROR_TRACKER_DATA: t('ERROR_TRACKER_DATA'),
	};

	const columns = [
		{
			title: 'STATUS',
			field: 'status_code',
			filtering: false,
			width: 100,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			render: rowData => {
				return (
					<>{renderStatusValue(rowData.status_code, rowData.error_code)}</>
				);
			},
		},
		{
			title: 'MEASUREMENT PROTOCOL PARAMS',
			filtering: false,
			field: 'mp_data_dump',
			width: 600,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			render: rowData => {
				let mp_data_dump =
					JSON.parse(rowData.mp_data_dump) ||
					('mp_data_dump_ga4' in rowData &&
						JSON.parse(rowData.mp_data_dump_ga4)) ||
					{};
				return Object.keys(mp_data_dump)
					.filter(key =>
						['client_id', 'measurement_id', 'el'].some(param => param == key),
					)
					.map((param, i) => {
						return (
							<span key={i}>
								<Chip
									label={`${param}`}
									size="small"
									variant={'outlined'}
									color={!mp_data_dump[param] ? 'secondary' : 'default'}
								/>
								:{''}
								{mp_data_dump[param] ? (
									<Chip label={mp_data_dump[param]} size="small" />
								) : (
									''
								)}
								&nbsp;&nbsp;&nbsp;
							</span>
						);
					});
			},
		},
		{
			title: 'GA4 Transaction',
			field: 'transaction_status',
			width: 65,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData =>
				rowData.transaction_status
					? renderTrStatus(rowData.transaction_status)
					: '',
		},
		/*
		{
			title: 'FB Conversions API (beta)',
			field: 'transaction_fb_status',
			width: 65,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData =>
				rowData.transaction_fb_status
					? renderTrStatus(rowData.transaction_fb_status)
					: '',
		},
		{
			title: 'YA Conversions API (beta)',
			field: 'transaction_ya_status',
			width: 65,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData =>
				rowData.transaction_ya_status
					? renderTrStatus(rowData.transaction_ya_status)
					: '',
		},
		*/
		{
			title: 'DEAL/LEAD',
			field: 'crm_record_type',
			width: 100,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData => {
				const recordTypeName = rowData.crm_record_type;
				const avatarClass =
					recordTypeName == 'deal' ? classes.orange : classes.purple;
				return <Avatar>{recordTypeName.slice(0, 1).toUpperCase()}</Avatar>;
			},
			lookup: { lead: 'lead', deal: 'deal' },
		},
		{
			title: 'STAGE',
			field: 'crm_record_status',
			width: 130,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData => (
				<Chip label={rowData.crm_record_status} variant="outlined" />
			),
		},
		{
			title: 'CRM ID',
			field: 'crm_record_id',
			filtering: false,
			width: 120,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			render: rowData => (
				<a href="#">
					<Chip label={`${rowData.crm_record_id}`} />
				</a>
			),
		},
		{
			title: 'LOG ID',
			field: 'id',
			filtering: false,
			width: 120,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			render: rowData => (
				<a href="#">
					<Chip label={`${rowData.id}`} />
				</a>
			),
		},
		{
			title: 'TITLE',
			field: 'crm_record_name',
			filtering: false,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
		},
	];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Button onClick={refreshLog}>
					<CachedIcon />
				</Button>
				<div className={classes.table}>
					<MaterialTable
						title="Logs"
						options={{
							showTitle: false,
							search: true,
							paging: true,
							filtering: false,
							pageSize: 10,
							pageSizeOptions: [],
							showTextRowsSelected: false,
							showSelectAllCheckbox: false,
							fixedColumns: {
								left: 0,
								right: 0,
							},
							rowStyle: {
								backgroundColor: '#EEE',
							},
						}}
						icons={tableIcons}
						columns={columns}
						data={logs.items}
						detailPanel={[
							{
								tooltip: 'Detail',
								width: 1000,
								render: rowData => {
									let mp_data_dump =
										JSON.parse(rowData.mp_data_dump) ||
										('mp_data_dump_ga4' in rowData &&
											JSON.parse(rowData.mp_data_dump_ga4)) ||
										{};

									let conversion_fb_dump =
										JSON.parse(rowData.conversion_fb_dump) || {};

									let conversion_ya_dump =
										JSON.parse(rowData.conversion_ya_dump) || {};

									return (
										<Grid container className={classes.root} spacing={2}>
											<Grid item xs={4}>
												<Card className={classes.card}>
													<CardContent>
														<Typography
															className={classes.cardTitle}
															color="textSecondary"
															gutterBottom
														>
															ℹ️ Measurement Protocol (Google Analytics 4)
															<br /> ℹ️ Parameters
															<a
																target="_blank"
																href="https://developers.google.com/analytics/devguides/collection/protocol/ga4/sending-events?client_type=gtag"
															>
																&nbsp;🔍 in detail
															</a>
															<br />
															<br />
														</Typography>
														<Typography variant="body2" component="div">

															{Object.keys(mp_data_dump).length === 0
																? 'No data'
																: <JSONPretty
																	id="json-pretty"
																	data={mp_data_dump}></JSONPretty>
																	}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
											{rowData.error_code ? (
												<Grid item xs={4}>
													<Card className={classes.card}>
														<CardContent>
															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️ Google Analytics. Result Description
															</Typography>
															<Typography variant="body2" component="div">
																{rowData.error_code in errorTypes
																	? errorTypes[rowData.error_code]
																	: 'error'}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											) : (
												''
											)}

											{'result' in conversion_fb_dump ? (
												<Grid item xs={4}>
													<Card className={classes.card}>
														<CardContent>
															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️ What is that?
																<a
																	target="_blank"
																	href="https://b242ga.ru/blog/facebook_conversions"
																>
																	&nbsp;🔍 in detail
																</a>
																<br />
															</Typography>

															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️ FB Conversions API
																<a
																	target="_blank"
																	href="https://developers.facebook.com/docs/marketing-api/conversions-api/"
																>
																	&nbsp;🔍 in detail
																</a>
																<br />
															</Typography>
															<Typography variant="body2" component="div">
																<div>
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		ℹ️ Payload
																		<a
																			target="_blank"
																			href="https://developers.facebook.com/docs/marketing-api/conversions-api/payload-helper"
																		>
																			&nbsp;🔍 in detail
																		</a>
																		:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={conversion_fb_dump['conversion_data']}
																	></JSONPretty>
																	<br />
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		Result:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={conversion_fb_dump['result']}
																	></JSONPretty>
																</div>
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											) : (
												''
											)}
											{'result' in conversion_ya_dump ? (
												<Grid item xs={4}>
													<Card className={classes.card}>
														<CardContent>
															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️ YA Conversions API
																<a
																	target="_blank"
																	href="https://yandex.ru/dev/metrika/doc/api2/practice/offline-conv.html#offline-conv"
																>
																	&nbsp;🔍 in detail
																</a>
																<br />
															</Typography>
															<Typography variant="body2" component="div">
																<div>
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		ℹ️ Payload
																		<a
																			target="_blank"
																			href="https://yandex.ru/dev/metrika/doc/api2/management/offline_conversion/upload.html#upload"
																		>
																			&nbsp;🔍 in detail
																		</a>
																		:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={conversion_ya_dump['conversion_data']}
																	></JSONPretty>
																	<br />
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		Result:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={conversion_ya_dump['result']}
																	></JSONPretty>
																</div>
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											) : (
												''
											)}
										</Grid>
									);
								},
							},
						]}
						onRowClick={(event, rowData, togglePanel) => togglePanel()}
						isLoading={logs.fetching}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default Logs;
