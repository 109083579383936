import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';

/** Icons  */

/** Other components */
import Drawer from '@material-ui/core/Drawer';

/** Sub components */
import GaForm from '../GaForm';
import FacebookForm from './Form';
import AllStepCompletedInfo from '../AllStepCompletedInfo';
import FacebookApiGaMappingInfo from './ApiGaMappingInfo';
import UtmSettings from './UtmSettings';

/** Actions */

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

const FacebookCostsSettings = ({
	open = false,
	toggleEditForm,
	cost,
	isFetching,
	SettingsModel,
	loadingState,
	setLoadingState,
	authCheckAction,
	authStateKey,
	AuthForm,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const [gaStepsCompetedState, setGaStepsCompetedState] = useState(false);
	const [sourceStepsCompletedState, setSourceStepsCompletedState] = useState(
		false,
	);

	const fetchAuthCheck = () => dispatch(authCheckAction({}));

	const authList = useSelector(state => state.auth);

	useEffect(() => {
		fetchAuthCheck();
	}, []);

	const allStepsCompleted = useMemo(() => {
		return gaStepsCompetedState && sourceStepsCompletedState && !isFetching;
	}, [gaStepsCompetedState, sourceStepsCompletedState, isFetching]);

	if (authStateKey in authList && !authList[authStateKey]) {
		return (
			<Drawer anchor={'right'} open={open} onClose={toggleEditForm}>
				<AuthForm />
			</Drawer>
		);
	}

	return (
		<Drawer anchor={'right'} open={open} onClose={toggleEditForm}>
			<GaForm
				cost={cost}
				open={open}
				SettingsModel={SettingsModel}
				setGaStepsCompetedState={setGaStepsCompetedState}
				setLoadingState={setLoadingState}
				isFetching={isFetching}
			/>
			<FacebookForm
				cost={cost}
				isFetching={isFetching}
				SettingsModel={SettingsModel}
				loadingState={loadingState}
				setLoadingState={setLoadingState}
				setSourceStepsCompletedState={setSourceStepsCompletedState}
			/>
			{allStepsCompleted ? (
				<span>
					<AllStepCompletedInfo />
					<div className="blockquote-well-done">
						<p>
							<b>
								{t('Mapping Google Analytics API Fields', {
									source: 'Facebook API Fields',
									link:
										'https://developers.facebook.com/docs/marketing-api/insights/parameters/v10.0',
								})}
								:
							</b>
						</p>
						<p>
							<FacebookApiGaMappingInfo />
						</p>
					</div>
					<div className="blockquote-well-done">
						<p>
							<b>{t('Utm Settings Facebook')}:</b>
						</p>
						<p>
							<UtmSettings />
						</p>
					</div>
				</span>
			) : null}
		</Drawer>
	);
};

export default FacebookCostsSettings;
