import { styled } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const CircularProgressStyled = styled(CircularProgress)({
	position: 'absolute',
	top: '50%',
	left: '50%',
	marginTop: -12,
	marginLeft: -12,
});

export default CircularProgressStyled;
