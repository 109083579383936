import {
  LEADS_USER_FIELDS_FETCH_START,
  LEADS_USER_FIELDS_FETCH_SUCCESS,
  LEADS_USER_FIELDS_FETCH_ERROR
} from "actions/leadsActions";

export const initialState = {
  items: []
};

export default (state = { ...initialState, fetching: false }, action) => {
  switch (action.type) {
    case LEADS_USER_FIELDS_FETCH_START:
      return {
        ...state,
        fetching: true
      };
    case LEADS_USER_FIELDS_FETCH_SUCCESS:
      return {
        ...state,
        items: [...action.payload],
        fetching: false
      };
    case LEADS_USER_FIELDS_FETCH_ERROR:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
};
