import { RestApiError } from 'error/restError';

import axios from 'axios';
import cookie from 'helpers/cookie';

export function getLogs() {
	return axios({
		method: 'get',
		url: `${process.env.REACT_APP_B242GA_API}/logs`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}
