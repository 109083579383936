import React, { Component, useState, useCallback } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

import InputLabel from '@material-ui/core/InputLabel';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Skeleton from '@material-ui/lab/Skeleton';

import ProgressForButton from 'components/common/CircularProgressStyled';

import CONFIG from 'helpers/const';

const useStyles = makeStyles({
	textField: {
		borderColor: 'green',
		borderWidth: 2,
	},
});

const SourceItem = ({
	source,
	SettingsModel,
	isFetching,
	setLoadingState,
	loadingState,
}) => {
	console.log(source);
	const [sourceEditState, setSourceEditState] = useState({
		[CONFIG.SOURCE_UTM_SOURCE]: source[CONFIG.SOURCE_UTM_SOURCE] || '',
		[CONFIG.SOURCE_UTM_MEDIUM]: source[CONFIG.SOURCE_UTM_MEDIUM] || '',
		[CONFIG.SOURCE_UTM_CAMPAIGN]: source[CONFIG.SOURCE_UTM_CAMPAIGN] || '',
	});
	const [editModeState, setEditModeState] = useState(false);

	const sourceEdit = (field, event) => {
		setSourceEditState({
			...sourceEditState,
			[field]: event.target.value,
		});
	};

	const enableEdit = () => {
		setSourceEditState({
			[CONFIG.SOURCE_UTM_SOURCE]: source[CONFIG.SOURCE_UTM_SOURCE] || '',
			[CONFIG.SOURCE_UTM_MEDIUM]: source[CONFIG.SOURCE_UTM_MEDIUM] || '',
			[CONFIG.SOURCE_UTM_CAMPAIGN]: source[CONFIG.SOURCE_UTM_CAMPAIGN] || '',
		});
		setEditModeState(true);
	};

	const resetEdit = () => {
		setSourceEditState({
			[CONFIG.SOURCE_UTM_SOURCE]: source[CONFIG.SOURCE_UTM_SOURCE] || '',
			[CONFIG.SOURCE_UTM_MEDIUM]: source[CONFIG.SOURCE_UTM_MEDIUM] || '',
			[CONFIG.SOURCE_UTM_CAMPAIGN]: source[CONFIG.SOURCE_UTM_CAMPAIGN] || '',
		});
		setEditModeState(false);
	};

	const sourceSave = () => {
		SettingsModel.sources = {
			STATUS_ID: source.STATUS_ID,
			[CONFIG.SOURCE_UTM_SOURCE]: sourceEditState[CONFIG.SOURCE_UTM_SOURCE],
			[CONFIG.SOURCE_UTM_MEDIUM]: sourceEditState[CONFIG.SOURCE_UTM_MEDIUM],
			[CONFIG.SOURCE_UTM_CAMPAIGN]: sourceEditState[CONFIG.SOURCE_UTM_CAMPAIGN],
		};

		setLoadingState({
			...loadingState,
			sources: { [source.STATUS_ID]: true },
		});

		setEditModeState(false);
		setSourceEditState({});

		SettingsModel.saveSettings();
	};

	console.log('render SourceItem');

	const disableState = !editModeState;
	const isSourceFetching = isFetching('sources', source.STATUS_ID);

	return (
		<TableRow style={{ height: 115 }}>
			<TableCell style={{ width: 20 }}>{source.NAME}</TableCell>
			{isFetching('sources', source.STATUS_ID) ? (
				<>
					<TableCell>
						<Skeleton animation={'wave'} />
					</TableCell>
					<TableCell>
						<Skeleton animation={'wave'} />
					</TableCell>
					<TableCell>
						<Skeleton animation={'wave'} />
					</TableCell>
				</>
			) : (
				<>
					<TableCell>
						<InputLabel>utm_source</InputLabel>
						<TextField
							value={`${CONFIG.SOURCE_UTM_SOURCE in sourceEditState
									? sourceEditState[CONFIG.SOURCE_UTM_SOURCE]
									: source[CONFIG.SOURCE_UTM_SOURCE]
										? source[CONFIG.SOURCE_UTM_SOURCE]
										: ''
								}`}
							label=""
							margin="normal"
							variant="outlined"
							onChange={sourceEdit.bind(this, CONFIG.SOURCE_UTM_SOURCE)}
							style={{ borderColor: 'red' }}
							disabled={disableState}
						/>
					</TableCell>
					<TableCell>
						<InputLabel>utm_medium</InputLabel>
						<TextField
							value={`${CONFIG.SOURCE_UTM_MEDIUM in sourceEditState
									? sourceEditState[CONFIG.SOURCE_UTM_MEDIUM]
									: source[CONFIG.SOURCE_UTM_MEDIUM]
										? source[CONFIG.SOURCE_UTM_MEDIUM]
										: ''
								}`}
							margin="normal"
							variant="outlined"
							onChange={sourceEdit.bind(this, CONFIG.SOURCE_UTM_MEDIUM)}
							disabled={disableState}
						/>
					</TableCell>
					<TableCell>
						<InputLabel>utm_campaign</InputLabel>
						<TextField
							value={`${CONFIG.SOURCE_UTM_CAMPAIGN in sourceEditState
									? sourceEditState[CONFIG.SOURCE_UTM_CAMPAIGN]
									: source[CONFIG.SOURCE_UTM_CAMPAIGN]
										? source[CONFIG.SOURCE_UTM_CAMPAIGN]
										: ''
								}`}
							margin="normal"
							variant="outlined"
							onChange={sourceEdit.bind(this, CONFIG.SOURCE_UTM_CAMPAIGN)}
							disabled={disableState}
						/>
					</TableCell>
				</>
			)}

			<TableCell style={{ width: 30 }}>
				<Button
					color="inherit"
					onClick={enableEdit}
					disabled={isSourceFetching || editModeState}
				>
					<EditIcon />
				</Button>

				<Button color="inherit" onClick={resetEdit} disabled={!editModeState}>
					<ClearIcon />
				</Button>

				<Button
					onClick={sourceSave}
					disabled={!editModeState}
					color={editModeState ? 'primary' : 'default'}
				>
					<SaveIcon />
					{isSourceFetching ? <ProgressForButton size={24} /> : null}
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default SourceItem;
