import {
    LEAD_CRM_FIELDS_UPDATE_START,
    LEAD_CRM_FIELDS_UPDATE_SUCCESS,
    LEAD_CRM_FIELDS_UPDATE_ERROR
  } from "actions/leadsActions";
  
  export const initialState = {
    entity_id: false,
    data: {}
  };
  
  export default (state = { ...initialState, fetching: false }, action) => {
    switch (action.type) {
      case LEAD_CRM_FIELDS_UPDATE_START:
        return {
          ...state,
          entity_id: action.payload.entity_id,
          data: action.payload.data,
          fetching: true
        };
  
      case LEAD_CRM_FIELDS_UPDATE_SUCCESS:
        return {
          ...state,
          entity_id: initialState.entity_id,
          data: initialState.data,
          fetching: false
        };
  
      case LEAD_CRM_FIELDS_UPDATE_ERROR:
        return {
          ...state,
          entity_id: initialState.entity_id,
          data: initialState.data,
          fetching: false
        };
      default:
        return state;
    }
  };
  