export const SETTINGS_GET_START = 'SETTINGS_GET_START';
export const SETTINGS_GET_SUCCESS = 'SETTINGS_GET_SUCCESS';
export const SETTINGS_GET_ERROR = 'SETTINGS_GET_ERROR';

export const SETTINGS_INIT_START = 'SETTINGS_INIT_START';
export const SETTINGS_INIT_SUCCESS = 'SETTINGS_INIT_SUCCESS';
export const SETTINGS_INIT_ERROR = 'SETTINGS_INIT_ERROR';

export const SETTINGS_UPDATE_START = 'SETTINGS_UPDATE_START';
export const SETTINGS_UPDATE_SUCCESS = 'SETTINGS_UPDATE_SUCCESS';
export const SETTINGS_UPDATE_ERROR = 'SETTINGS_UPDATE_ERROR';

export const settingsInitStart = payload => {
	return {
		type: SETTINGS_INIT_START,
		payload,
	};
};

export const settingsInitSuccess = payload => {
	return {
		type: SETTINGS_INIT_SUCCESS,
		payload,
	};
};

export const settingsInitError = payload => {
	return {
		type: SETTINGS_INIT_ERROR,
		payload,
	};
};

export const settingsGetStart = payload => {
	return {
		type: SETTINGS_GET_START,
		payload,
	};
};

export const settingsGetSuccess = payload => {
	return {
		type: SETTINGS_GET_SUCCESS,
		payload,
	};
};

export const settingsGetError = payload => {
	return {
		type: SETTINGS_GET_ERROR,
		payload,
	};
};

export const settingsUpdateStart = payload => {
	return {
		type: SETTINGS_UPDATE_START,
		payload,
	};
};

export const settingsUpdateSuccess = payload => {
	return {
		type: SETTINGS_UPDATE_SUCCESS,
		payload,
	};
};

export const settingsUpdateError = payload => {
	return {
		type: SETTINGS_UPDATE_ERROR,
		payload,
	};
};
