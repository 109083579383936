import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ru from 'lang/ru';
import en from 'lang/en';

const resources = {
	en,
	ru,
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'en',

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

window.BX24.init(function () {
	const portalLang = window.BX24.getLang();
	const currLang = ['ru', 'ua'].includes(portalLang) ? 'ru' : 'en';

	i18n.changeLanguage(currLang);
	window.localStorage.setItem('b242gaLang', currLang);
});

export default i18n;
