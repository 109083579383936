import React, { Component, useEffect } from "react";

import Container from "@material-ui/core/Container";

const Main = props => {
  return (
    <Container
      style={{ paddingLeft: "0px", paddingRight: "0px", minWidth: "100%" }}
    >
      <div style={{ padding: 24, background: "#ffffff" }}>{props.children}</div>
    </Container>
  );
};

export default Main;
