import React, { Component } from 'react';

export const fitWindow = () => {
	if (
		window.BX24 === null ||
		!('BX24' in window) ||
		!('resizeWindow' in window.BX24)
	)
		return false;

	const clientWidth = document.getElementById('root').clientWidth;
	const clientHeight = document.getElementById('root').clientHeight;
	window.BX24.resizeWindow(clientWidth + 20, clientHeight + 500);
};

export const checkFetching = loadingState => (key, id) => {
	let isFetching = false;

	if (key in loadingState && typeof loadingState[key] === 'object') {
		isFetching = id in loadingState[key] ? loadingState[key][id] : false;
	}

	if (
		key in loadingState &&
		typeof loadingState[key] === 'object' &&
		id === false
	) {
		isFetching = Object.keys(loadingState[key]).some(
			id => !!loadingState[key][id],
		);
	}

	if (key in loadingState && typeof loadingState[key] !== 'object')
		isFetching = loadingState[key];

	return isFetching;
};

export const utm2fields = (userFields, counterField, cidField, payload) => {
	const fields = {
		UTM_SOURCE: ('utm_src' in payload.data && payload.data.utm_src) || '',
		UTM_MEDIUM: ('utm_medium' in payload.data && payload.data.utm_medium) || '',
		UTM_CONTENT: ('utm_mdm' in payload.data && payload.data.utm_mdm) || '',
		UTM_CAMPAIGN: ('utm_cmp' in payload.data && payload.data.utm_cmp) || '',
		UTM_TERM: ('utm_trm' in payload.data && payload.data.utm_trm) || '',
	};

	const userFieldCidData =
		userFields.items.find(field => field.ID == cidField) || false;

	if (
		userFieldCidData &&
		'FIELD_NAME' in userFieldCidData &&
		'gaCid' in payload.data
	)
		fields[userFieldCidData['FIELD_NAME']] = payload.data.gaCid;

	const userFieldCounterData =
		userFields.items.find(field => field.ID == counterField) || false;

	if (
		userFieldCounterData &&
		'FIELD_NAME' in userFieldCounterData &&
		'ga_counter' in payload.data
	)
		fields[userFieldCounterData['FIELD_NAME']] = payload.data.ga_counter;

	return fields;
};

export const randomHash = () => {
	return (
		Math.random().toString(36).substring(2, 15) +
		Math.random().toString(36).substring(2, 15)
	);
};

export const replaceMarkers = (str = '', markers = {}) => {
	let resultString = '';

	Object.keys(markers).map(marker => {
		let parts = str.split(marker);
		for (var i = 1; i < parts.length; i += 2) {
			parts[i] = <span key={i}>{markers[marker]}</span>;
		}

		resultString = parts.join();
	});

	return <b>{resultString}</b>;
};
