import { put, delay } from 'redux-saga/effects';
import {
	notificationHide,
	notificationWarning,
	notificationError,
	notificationSuccess,
} from 'actions/notificationsActions';

const notifyAction = {
	SUCCESS: notificationSuccess,
	WARNING: notificationWarning,
	ERROR: notificationError,
};

export function* showNotification(notifyType, message) {
	if (!(notifyType in notifyAction)) return false;

	yield put(notifyAction[notifyType]({ message }));
	yield delay(10000);
	yield put(notificationHide());
}
