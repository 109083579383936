import {
	takeEvery,
	call,
	put,
	take,
	fork,
	cancel,
	select,
	cancelled,
	delay,
} from 'redux-saga/effects';

import {
	dealCategoryList,
	dealCategoryStageList,
	dealUserFieldsList,
	dealUserFieldGetBatch,
} from '../api/deals';

import { dealCrmFieldUpdate } from 'api/deals';

import { utm2fields } from 'helpers/utils';
import { showNotification } from 'helpers/notifications';

import {
	dealsCategoryFetchSuccess,
	dealsCategoryFetchError,
	dealsCategoryStageFetchSuccess,
	dealsCategoryStageFetchError,
	dealsUserFieldsFetchSuccess,
	dealsUserFieldsFetchError,
	dealCrmFieldUpdateSuccess,
	dealCrmFieldUpdateError,
	DEALS_CATEGORY_FETCH_START,
	DEALS_CATEGORY_STAGE_FETCH_START,
	DEALS_USER_FIELDS_FETCH_START,
	DEAL_CRM_FIELDS_UPDATE_START,
} from 'actions/dealsActions';

export function* dealsCategoryListFlow() {
	while (true) {
		yield take(DEALS_CATEGORY_FETCH_START);

		try {
			const result = yield call(dealCategoryList);
			yield put(dealsCategoryFetchSuccess(result.data));
		} catch (error) {
			yield put(dealsCategoryFetchError(error));
		}
	}
}

function* getCategoryStageList(payload) {
	try {
		const result = yield call(dealCategoryStageList, payload);

		yield put(dealsCategoryStageFetchSuccess(result.data));
	} catch (error) {
		yield put(dealsCategoryStageFetchError({ data: payload, error }));
	}
}

export function* dealsCategoryStageListFlow() {
	while (true) {
		const { payload } = yield take(DEALS_CATEGORY_STAGE_FETCH_START);
		yield fork(getCategoryStageList, payload);
	}
}

export function* dealsUserFieldListFlow() {
	while (true) {
		yield take(DEALS_USER_FIELDS_FETCH_START);

		try {
			let result = yield call(dealUserFieldsList, ['string', 'enumeration']);

			const userFieldsList = result.data;

			let ids = [];

			for (let { ID } of userFieldsList) ids.push(ID);

			result = yield call(dealUserFieldGetBatch, ids);

			const userFieldsListFull = result.data;

			yield put(dealsUserFieldsFetchSuccess(userFieldsListFull));
		} catch (error) {
			yield put(dealsUserFieldsFetchError(error));
		}
	}
}

export function* dealCrmUpdateFlow() {
	while (true) {
		const { payload } = yield take(DEAL_CRM_FIELDS_UPDATE_START);
		const state = yield select();

		const id = payload.entity_id;
		const { deals_ga_counter_field, deals_ga_cid_field } = state.settings;

		const fields = utm2fields(
			state.deals_user_fields,
			deals_ga_counter_field,
			deals_ga_cid_field,
			payload,
		);

		try {
			const result = yield call(dealCrmFieldUpdate, { id, fields });

			if (result) {
				yield fork(
					showNotification,
					'SUCCESS',
					'Utm data saved! Refresh the page and you will see the result!',
				);
				yield put(dealCrmFieldUpdateSuccess(result));
			}

			if (!result) {
				yield fork(showNotification, 'ERROR', 'Utm data saving error!');
			}
		} catch (error) {
			yield fork(
				showNotification,
				'ERROR',
				`${error.name}: ${error.error_description}`,
			);
			yield put(dealCrmFieldUpdateError(error));
		}
	}
}
