import React from 'react';
import { useDragLayer } from 'react-dnd';

import GoalDragPreview from 'components/pages/Crm/GoalDragPreview';

/** Helpers */
import CONFIG from 'helpers/const';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	layer: {
		position: 'fixed',
		pointerEvents: 'none',
		zIndex: 10000,
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
	},
	dragging: props => {
		if (!props.initialOffset || !props.currentOffset) {
			return {
				display: 'none',
			};
		}

		const { x, y } = props.currentOffset;

		const transform = `translate(${x}px, ${y}px)`;

		return {
			transform,
			WebkitTransform: transform,
		};
	},
});

export const GoalDragLayer = () => {
	const {
		itemType,
		isDragging,
		item,
		initialOffset,
		currentOffset,
	} = useDragLayer(monitor => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	function renderItem() {
		if (itemType == CONFIG.GOAL_DRAG_TYPE)
			return <GoalDragPreview title={item.goal.name} />;

		return null;
	}

	const classes = useStyles({ initialOffset, currentOffset });

	if (!isDragging) {
		return false;
	}

	return (
		<div className={classes.layer}>
			<div className={classes.dragging}>{renderItem()}</div>
		</div>
	);
};
