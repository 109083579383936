import React from 'react';

import { styled } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

//import { green } from '@material-ui/core/colors';
//style={{ color: green[500] }}

import CircularProgressStyled from './CircularProgressStyled';

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

const SaveButtonStyled = styled(Button)({});

const SaveButton = ({ isFetching, handler, disabled, label }) => {
	const { t } = useTranslationWithParse();

	return (
		<SaveButtonStyled
			variant="contained"
			color="primary"
			onClick={handler}
			disabled={disabled}
		>
			{t('Save')}
			<SaveIcon />
			{isFetching ? (
				<CircularProgressStyled color={'default'} size={24} />
			) : null}
		</SaveButtonStyled>
	);
};

export default SaveButton;
