import {
  DEALS_CATEGORY_FETCH_START,
  DEALS_CATEGORY_FETCH_SUCCESS,
  DEALS_CATEGORY_FETCH_ERROR
} from "actions/dealsActions";

const initialState = {
  items: [
    {
      ID: 0,
      NAME: "Общее",
      SORT: 10
    }
  ]
};

export default (state = { ...initialState, fetching: {} }, action) => {
  switch (action.type) {
    case DEALS_CATEGORY_FETCH_START:
      return state;

    case DEALS_CATEGORY_FETCH_SUCCESS:
      let items = action.payload.length ? action.payload : state.items;
      return {
        ...state,
        items
      };

    case DEALS_CATEGORY_FETCH_ERROR:
      return state;

    default:
      return state;
  }
};
