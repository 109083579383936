import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';

/** Other components */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';

/** Sub components */

/** Actions */
import {
	gaAccountSummariesFetchStart,
	gaCustomDataSourcesFetchStart,
} from 'actions/gaActions';
import { authCheckFbFetchStart } from 'actions/authActions';

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
}));

const GaForm = ({
	open,
	cost,
	isFetching,
	SettingsModel,
	loadingState,
	setLoadingState,
	setGaStepsCompetedState,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const [activeStep, setActiveStep] = useState(0);
	const [stepEditState, setStepEditState] = useState(false);

	const [gaAccountIdState, setGaAccountIdState] = useState(false);
	const [gaAccountNameState, setGaAccountNameState] = useState('');
	const [webPropertyIdState, setWebPropertyIdState] = useState(false);
	const [internalWebPropertyIdState, setInternalWebPropertyIdState] = useState(
		false,
	);
	const [profileIdState, setProfileIdState] = useState(false);
	const [profileNameState, setProfileNameState] = useState(false);
	const [gaCustomDataSourceIdState, setGaCustomDataSourceIdState] = useState(
		false,
	);
	const [
		gaCustomDataSourceNameState,
		setGaCustomDataSourceNameState,
	] = useState('');

	const gaAccountIdSettings = useMemo(() => cost.accountId || null);
	const webPropertyIdSettings = useMemo(() => cost.webPropertyId || null);
	const profileIdSettings = useMemo(() => cost.profileId || null);
	const gaCustomDataSourceIdSettings = useMemo(
		() => cost.customDataSourceId || null,
	);

	useEffect(() => {
		if ('accountId' in cost && cost.accountId)
			setGaAccountIdState(cost.accountId);

		if ('accountName' in cost && cost.accountName)
			setGaAccountNameState(cost.accountName);

		if ('profileId' in cost && cost.profileId)
			setProfileIdState(cost.profileId);

		if ('profileName' in cost && cost.profileName)
			setProfileNameState(cost.profileName);

		if ('webPropertyId' in cost && cost.webPropertyId)
			setWebPropertyIdState(cost.webPropertyId);

		if ('internalWebPropertyId' in cost && cost.internalWebPropertyId)
			setInternalWebPropertyIdState(cost.internalWebPropertyId);

		if ('customDataSourceId' in cost && cost.customDataSourceId)
			setGaCustomDataSourceIdState(cost.customDataSourceId);

		if ('customDataSourceName' in cost && cost.customDataSourceName)
			setGaCustomDataSourceNameState(cost.customDataSourceName);
	}, [cost]);

	const fetchAccountSummaries = () =>
		dispatch(gaAccountSummariesFetchStart({}));

	const fetchCustomDataSources = () =>
		dispatch(
			gaCustomDataSourcesFetchStart({
				accountId: gaAccountIdState,
				webPropertyId: webPropertyIdState,
			}),
		);

	useEffect(() => {
		fetchAccountSummaries();
	}, []);

	useEffect(() => {
		if (gaAccountIdState && webPropertyIdState && open)
			fetchCustomDataSources();
	}, [gaAccountIdState, webPropertyIdState, open]);

	const reloadCustomDataSource = useCallback(() => {
		if (gaAccountIdState && webPropertyIdState && open)
			fetchCustomDataSources();
	}, [gaAccountIdState, webPropertyIdState, open]);

	const accountSummaries = useSelector(state => state.ga_account_summaries);

	const webPropertiesList = useSelector(state => {
		if (
			'items' in state.ga_account_summaries &&
			state.ga_account_summaries.items.length > 0 &&
			gaAccountIdSettings
		) {
			const currentAccount =
				state.ga_account_summaries.items.find(
					account => account.id == gaAccountIdSettings,
				) || {};

			if ('webProperties' in currentAccount)
				return currentAccount.webProperties;
		}

		return [];
	});

	const profileList = useSelector(state => {
		if (
			'items' in state.ga_account_summaries &&
			state.ga_account_summaries.items.length > 0 &&
			gaAccountIdSettings &&
			webPropertyIdSettings
		) {
			const webProperties =
				state.ga_account_summaries.items.find(
					account => account.id == gaAccountIdSettings,
				).webProperties || [];

			const currentWebProperty =
				webProperties.find(property => property.id == webPropertyIdSettings) ||
				{};

			if ('profiles' in currentWebProperty) return currentWebProperty.profiles;
		}

		return [];
	});

	const customDataSourcesList = useSelector(state => {
		return gaAccountIdState && webPropertyIdState
			? state.ga_custom_data_sources.items.filter(
				source =>
					source.accountId === gaAccountIdState &&
					source.webPropertyId === webPropertyIdState,
			)
			: [];
	});

	const customDataSourcesListIsFetching = useSelector(state => {
		return !!state.ga_custom_data_sources.fetching;
	});

	const gaAccountIdSave = () => {
		const { name: accountName = '' } = accountSummaries.items.find(
			item => item.id === gaAccountIdState,
		);

		SettingsModel.costs = {
			id: cost.id,
			accountId: gaAccountIdState,
			accountName,
			webPropertyId: null,
			internalWebPropertyId: null,
			profileId: null,
			profileName: null,
			customDataSourceId: null,
			customDataSourceName: null,
		};

		/** Reset next step values */
		setWebPropertyIdState(false);
		setProfileIdState(false);
		setGaCustomDataSourceIdState(false);
		setGaStepsCompetedState(false);

		setLoadingState({
			...loadingState,
			costs: { [cost.id]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(1);
	};

	const webProperyIdSave = () => {
		const { internalWebPropertyId = '' } = webPropertiesList.find(
			webProperty => webProperty.id === webPropertyIdState,
		);

		SettingsModel.costs = {
			id: cost.id,
			accountId: gaAccountIdState,
			accountName: gaAccountNameState,
			webPropertyId: webPropertyIdState,
			internalWebPropertyId: internalWebPropertyId,
			profileId: null,
			profileName: null,
			customDataSourceId: null,
			customDataSourceName: null,
		};

		/** Reset next step values */
		setProfileIdState(false);
		setGaCustomDataSourceIdState(false);
		setGaStepsCompetedState(false);

		setLoadingState({
			...loadingState,
			costs: { [cost.id]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(2);
	};

	const profileIdSave = () => {
		const { name: profileName = '' } = profileList.find(
			profile => profile.id === profileIdState,
		);

		SettingsModel.costs = {
			id: cost.id,
			accountId: gaAccountIdState,
			accountName: gaAccountNameState,
			webPropertyId: webPropertyIdState,
			internalWebPropertyId: internalWebPropertyIdState,
			profileId: profileIdState,
			profileName,
			customDataSourceId: null,
			customDataSourceName: null,
		};

		/** Reset next step values */
		setGaCustomDataSourceIdState(false);
		setGaStepsCompetedState(false);

		setLoadingState({
			...loadingState,
			costs: { [cost.id]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(3);
	};

	const customDataSourceIdSave = () => {
		const { name: customDataSourceName = '' } = customDataSourcesList.find(
			source => source.id === gaCustomDataSourceIdState,
		);

		SettingsModel.costs = {
			id: cost.id,
			accountId: gaAccountIdState,
			accountName: gaAccountNameState,
			webPropertyId: webPropertyIdState,
			internalWebPropertyId: internalWebPropertyIdState,
			profileId: profileIdState,
			profileName: profileNameState,
			customDataSourceId: gaCustomDataSourceIdState,
			customDataSourceName,
		};

		setGaStepsCompetedState(true);

		setLoadingState({
			...loadingState,
			costs: { [cost.id]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(4);
	};

	const backToAccount = () => {
		setStepEditState(0);
	};

	const backToWebProperty = () => {
		setStepEditState(1);
	};

	const backToProfile = () => {
		setStepEditState(2);
	};

	const backToCustomDataSource = () => {
		setStepEditState(3);
	};

	const gaAccountIdEdit = useCallback(event => {
		setGaAccountIdState(event.target.value);
	});

	const webPropertyIdEdit = useCallback(event => {
		setWebPropertyIdState(event.target.value);
	});

	const profileIdEdit = useCallback(event => {
		setProfileIdState(event.target.value);
	});

	const customDataSourceIdEdit = useCallback(event => {
		setGaCustomDataSourceIdState(event.target.value);
	});

	useEffect(() => {
		const isSomeFetching = !!isFetching;

		if (gaAccountIdSettings && stepEditState === 1 && !isSomeFetching) {
			setActiveStep(1);
		}

		if (webPropertyIdSettings && stepEditState === 2 && !isSomeFetching) {
			setActiveStep(2);
		}

		if (profileIdSettings && stepEditState === 3 && !isSomeFetching) {
			setActiveStep(3);
		}

		if (
			gaCustomDataSourceIdSettings &&
			stepEditState === 4 &&
			!isSomeFetching
		) {
			setActiveStep(4);
		}

		if (gaAccountIdSettings && !webPropertyIdSettings && !stepEditState) {
			setActiveStep(1);
		}

		if (
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			!profileIdSettings &&
			!gaCustomDataSourceIdSettings &&
			!stepEditState
		)
			setActiveStep(2);

		if (
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			profileIdSettings &&
			!gaCustomDataSourceIdSettings &&
			!stepEditState
		)
			setActiveStep(3);

		if (
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			profileIdSettings &&
			gaCustomDataSourceIdSettings &&
			!stepEditState
		) {
			setActiveStep(4);
		}

		if (
			activeStep === 4 &&
			gaCustomDataSourceIdSettings &&
			!stepEditState &&
			!isSomeFetching
		)
			setGaStepsCompetedState(true);

		if (stepEditState === 0) setActiveStep(0);
	});

	const gaCustomDataSourceValue = useMemo(() => {
		return gaCustomDataSourceIdState !== false
			? gaCustomDataSourceIdState
			: gaCustomDataSourceIdSettings
				? gaCustomDataSourceIdSettings
				: 'default';
	}, [gaCustomDataSourceIdState, gaCustomDataSourceIdSettings]);

	const gaAccountValue = useMemo(() => {
		return gaAccountIdState !== false
			? gaAccountIdState
			: gaAccountIdSettings
				? gaAccountIdSettings
				: 'default';
	}, [gaAccountIdState, gaAccountIdSettings]);

	const webPropertyValue = useMemo(() => {
		return webPropertyIdState !== false
			? webPropertyIdState
			: webPropertyIdSettings
				? webPropertyIdSettings
				: 'default';
	}, [webPropertyIdState, webPropertyIdSettings]);

	const profileValue = useMemo(() => {
		return profileIdState !== false
			? profileIdState
			: profileIdSettings
				? profileIdSettings
				: 'default';
	}, [profileIdState, profileIdSettings]);

	const { profilesLinked = [] } = useMemo(() => {
		return customDataSourcesList.length > 0
			? customDataSourcesList.find(
				source =>
					source.accountId === gaAccountIdState &&
					source.webPropertyId === webPropertyIdState,
			)
			: { profilesLinked: [] };
	}, [customDataSourcesList]);

	/** If there is no data set, then this is wrong */
	const noGaCustomSourcesError = useMemo(() => {
		return !!(
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			customDataSourcesList.length === 0 &&
			activeStep === 3
		);
	}, [
		gaAccountIdSettings,
		webPropertyIdSettings,
		customDataSourcesList,
		activeStep,
	]);

	/** Some view should be linked to exist data set */
	const noProfileForGaCustomSourceError = useMemo(() => {
		return !!(
			profilesLinked.length === 0 &&
			gaCustomDataSourceValue !== 'default' &&
			activeStep === 3
		);
	}, [profilesLinked, gaCustomDataSourceValue, activeStep]);

	const gaCustomSourceError = useMemo(() => {
		return (
			!!(noGaCustomSourcesError || noProfileForGaCustomSourceError) &&
			!customDataSourcesListIsFetching
		);
	}, [
		noGaCustomSourcesError,
		noProfileForGaCustomSourceError,
		customDataSourcesListIsFetching,
	]);

	const linkToDataSetNewHref = useMemo(() => {
		if (gaAccountIdState && internalWebPropertyIdState && profileIdSettings)
			return `https://analytics.google.com/analytics/web/#/a${gaAccountIdState}w${internalWebPropertyIdState}p${profileIdSettings}/admin/dataimport/datasets`;

		return null;
	}, [gaAccountIdState, internalWebPropertyIdState, profileIdSettings]);

	const LinkToDataSetNew = useCallback(
		() => (
			<>
				🆕
				{linkToDataSetNewHref
					? t('Create Data Set', { link: linkToDataSetNewHref })
					: null}
			</>
		),
		[linkToDataSetNewHref],
	);

	const LinkToDataSetInstruction = useCallback(
		() => (
			<>
				📹
				{t('Create Data Set Example', {
					link: 'https://vimeo.com/498872592#t=35s',
				})}
			</>
		),
		[],
	);

	const linkToDataSetEditHref = useMemo(() => {
		if (gaAccountIdState && internalWebPropertyIdState && profileIdSettings)
			return `https://analytics.google.com/analytics/web/#/a${gaAccountIdState}w${internalWebPropertyIdState}p${profileIdSettings}/admin/dataimport/datasets/${gaCustomDataSourceIdState}/edit`;

		return null;
	}, [gaAccountIdState, internalWebPropertyIdState, profileIdSettings]);

	const LinkToDataSetEdit = useCallback(
		() => (
			<>
				{linkToDataSetEditHref ? (
					<Link target="_blank" href={linkToDataSetEditHref}>
						✏️ Edit Data Set
					</Link>
				) : null}
			</>
		),
		[linkToDataSetEditHref],
	);

	let gaCustomSourceErrorMessage = useMemo(() => {
		if (noGaCustomSourcesError) return t(`There is no Data set`);

		if (noProfileForGaCustomSourceError) return t(`Data set has no view link`);
	}, [noProfileForGaCustomSourceError, noGaCustomSourcesError]);

	const GaCustomSourceErrorDescription = useMemo(() => {
		if (noGaCustomSourcesError) {
			return () => (
				<>
					<LinkToDataSetNew />
					<br />
					<LinkToDataSetInstruction />
				</>
			);
		}

		if (noProfileForGaCustomSourceError) {
			return () => (
				<>
					{t('Bind Data Set to the desired View')} 🔗
					<LinkToDataSetEdit />
				</>
			);
		}

		return () => null;
	}, [noProfileForGaCustomSourceError, noGaCustomSourcesError]);

	return (
		<Stepper activeStep={activeStep} orientation="vertical">
			<Step key={0}>
				<StepLabel>
					{gaAccountIdSettings &&
						accountSummaries.items.some(
							account => account.id == gaAccountIdSettings,
						) ? (
						<StepLabel>
							{gaAccountNameState}
							{gaAccountIdSettings && activeStep > 0 ? (
								<Button onClick={backToAccount}>
									<EditIcon fontSize="small" />
								</Button>
							) : null}
						</StepLabel>
					) : (
						<StepLabel>{t('Select GA account')}</StepLabel>
					)}
				</StepLabel>
				<StepContent>
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">
							{'account'}
						</InputLabel>
						<Select
							label={'account'}
							onChange={gaAccountIdEdit}
							value={gaAccountValue}
							style={{ width: 450 }}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value="default">-</MenuItem>
							{accountSummaries.items.map((account, i) => (
								<MenuItem value={account.id} key={i}>
									{account.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={gaAccountIdSave}
								className={classes.button}
								disabled={
									!gaAccountIdState ||
									gaAccountIdState == gaAccountIdSettings ||
									gaAccountIdState == 'default' ||
									isFetching
								}
							>
								{t('Save')}
								<SaveIcon />
								{isFetching ? <CircularProgressStyled size={24} /> : null}
							</Button>
						</div>
					</div>
				</StepContent>
			</Step>
			<Step key={1}>
				{webPropertyIdSettings &&
					webPropertiesList.some(
						webProperty => webProperty.id == webPropertyIdSettings,
					) ? (
					<StepLabel>
						{webPropertyIdSettings}
						{webPropertyIdSettings && activeStep !== 1 ? (
							<Button onClick={backToWebProperty}>
								<EditIcon fontSize="small" />
							</Button>
						) : null}
					</StepLabel>
				) : (
					<StepLabel>{t('Select counter')}</StepLabel>
				)}
				<StepContent>
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">
							{'counter'}
						</InputLabel>
						<Select
							label={'counter'}
							style={{ width: 450 }}
							onChange={webPropertyIdEdit}
							inputProps={{ 'aria-label': 'Without label' }}
							value={webPropertyValue}
						>
							<MenuItem value="default">-</MenuItem>
							{webPropertiesList.map(webProperty => (
								<MenuItem value={webProperty.id} key={webProperty.id}>
									{`${webProperty.name}(${webProperty.id})`}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={webProperyIdSave}
								className={classes.button}
								disabled={
									!webPropertyIdState ||
									webPropertyIdState == webPropertyIdSettings ||
									webPropertyIdState == 'default' ||
									isFetching
								}
							>
								{t('Save')}
								<SaveIcon />
								{isFetching ? <CircularProgressStyled size={24} /> : null}
							</Button>
						</div>
					</div>
				</StepContent>
			</Step>
			<Step key={2}>
				{profileIdSettings &&
					profileList.some(profile => profile.id == profileIdSettings) ? (
					<StepLabel>
						{profileNameState}
						{profileIdSettings && activeStep !== 2 ? (
							<Button onClick={backToProfile}>
								<EditIcon fontSize="small" />
							</Button>
						) : null}
					</StepLabel>
				) : (
					<StepLabel>Google Analytics view</StepLabel>
				)}
				<StepContent>
					{gaCustomSourceError ? (
						<>
							<Typography variant="caption" color="error">
								{gaCustomSourceErrorMessage}
							</Typography>
							<div className="blockquote-warning">
								<p>
									<GaCustomSourceErrorDescription />
								</p>
							</div>
						</>
					) : null}
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">view</InputLabel>
						<Select
							label="view"
							style={{ width: 450 }}
							onChange={profileIdEdit}
							inputProps={{ 'aria-label': 'Without label' }}
							value={profileValue}
						>
							<MenuItem value="default">-</MenuItem>
							{profileList.map((profile, i) => (
								<MenuItem value={profile.id} key={profile.id}>
									{`${profile.name}`}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={profileIdSave}
								className={classes.button}
								disabled={
									!profileIdState ||
									profileIdState == profileIdSettings ||
									profileIdState == 'default' ||
									isFetching
								}
							>
								{t('Save')}
								<SaveIcon />
								{isFetching ? <CircularProgressStyled size={24} /> : null}
							</Button>
						</div>
					</div>
				</StepContent>
			</Step>
			<Step key={3}>
				{gaCustomDataSourceIdSettings &&
					customDataSourcesList.some(
						source => source.id == gaCustomDataSourceIdSettings,
					) ? (
					<StepLabel>
						{gaCustomDataSourceNameState}
						{gaCustomDataSourceIdSettings && activeStep !== 2 ? (
							<Button onClick={backToCustomDataSource}>
								<EditIcon fontSize="small" />
							</Button>
						) : null}
					</StepLabel>
				) : (
					<StepLabel error={gaCustomSourceError}>
						{t('Data set for costs upload')}
						<Button onClick={reloadCustomDataSource}>
							<CachedIcon />
							{customDataSourcesListIsFetching ? (
								<CircularProgressStyled size={24} />
							) : null}
						</Button>
					</StepLabel>
				)}
				<StepContent>
					{gaCustomSourceError ? (
						<>
							<Typography variant="caption" color="error">
								{gaCustomSourceErrorMessage}
							</Typography>
							<div className="blockquote-warning">
								<p>
									<GaCustomSourceErrorDescription />
								</p>
							</div>
						</>
					) : null}

					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">
							Data set
						</InputLabel>
						<Select
							label="Data set"
							style={{ width: 450 }}
							onChange={customDataSourceIdEdit}
							inputProps={{ 'aria-label': 'Without label' }}
							value={gaCustomDataSourceValue}
						>
							<MenuItem value="default">-</MenuItem>
							{customDataSourcesList.map((source, i) => (
								<MenuItem value={source.id} key={source.id}>
									{`${source.name}`}
								</MenuItem>
							))}
							{customDataSourcesListIsFetching ? (
								<CircularProgressStyled size={24} />
							) : null}
						</Select>
					</FormControl>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={customDataSourceIdSave}
								className={classes.button}
								disabled={
									!gaCustomDataSourceIdState ||
									gaCustomDataSourceIdState == gaCustomDataSourceIdSettings ||
									gaCustomDataSourceIdState == 'default' ||
									isFetching ||
									gaCustomSourceError
								}
							>
								{t('Save')}
								<SaveIcon />
								{isFetching ? <CircularProgressStyled size={24} /> : null}
							</Button>
						</div>
					</div>
				</StepContent>
			</Step>
		</Stepper>
	);
};

export default GaForm;
