import {
	takeEvery,
	call,
	put,
	take,
	fork,
	cancel,
	select,
	cancelled,
	delay,
} from 'redux-saga/effects';

import {
	leadCategoryList,
	leadCategoryStageList,
	leadUserFieldsList,
	leadUserFieldGetBatch,
} from 'api/leads';

import { leadCrmFieldUpdate } from 'api/leads';

import { utm2fields } from 'helpers/utils';
import { showNotification } from 'helpers/notifications';

import {
	leadsCategoryFetchSuccess,
	leadsCategoryFetchError,
	leadsCategoryStageFetchSuccess,
	leadsCategoryStageFetchError,
	leadsUserFieldsFetchSuccess,
	leadsUserFieldsFetchError,
	leadCrmFieldUpdateSuccess,
	leadCrmFieldUpdateError,
	LEADS_CATEGORY_FETCH_START,
	LEADS_CATEGORY_STAGE_FETCH_START,
	LEADS_USER_FIELDS_FETCH_START,
	LEAD_CRM_FIELDS_UPDATE_START,
} from '../actions/leadsActions';

export function* leadsCategoryListFlow() {
	while (true) {
		yield take(LEADS_CATEGORY_FETCH_START);

		try {
			const result = yield call(leadCategoryList);
			yield put(leadsCategoryFetchSuccess(result.data));
		} catch (error) {
			yield put(leadsCategoryFetchError(error));
		}
	}
}

function* getCategoryStageList(payload) {
	try {
		const result = yield call(leadCategoryStageList, payload);

		yield put(leadsCategoryStageFetchSuccess(result.data));
	} catch (error) {
		yield put(leadsCategoryStageFetchError({ data: payload, error }));
	}
}

export function* leadsCategoryStageListFlow() {
	while (true) {
		const { payload } = yield take(LEADS_CATEGORY_STAGE_FETCH_START);
		yield fork(getCategoryStageList, payload);
	}
}

export function* leadsUserFieldListFlow() {
	while (true) {
		yield take(LEADS_USER_FIELDS_FETCH_START);

		try {
			let result = yield call(leadUserFieldsList, ['string', 'enumeration']);

			const userFieldsList = result.data;

			let ids = [];

			for (let { ID } of userFieldsList) ids.push(ID);

			result = yield call(leadUserFieldGetBatch, ids);

			const userFieldsListFull = result.data;

			yield put(leadsUserFieldsFetchSuccess(userFieldsListFull));
		} catch (error) {
			yield put(leadsUserFieldsFetchError(error));
		}
	}
}

export function* leadCrmUpdateFlow() {
	while (true) {
		const { payload } = yield take(LEAD_CRM_FIELDS_UPDATE_START);
		const state = yield select();

		const id = payload.entity_id;
		const { leads_ga_counter_field, leads_ga_cid_field } = state.settings;

		const fields = utm2fields(
			state.leads_user_fields,
			leads_ga_counter_field,
			leads_ga_cid_field,
			payload,
		);

		try {
			const result = yield call(leadCrmFieldUpdate, { id, fields });

			if (result) {
				yield fork(
					showNotification,
					'SUCCESS',
					'Utm data saved! Refresh the page and you will see the result!',
				);
				yield put(leadCrmFieldUpdateSuccess(result));
			}

			if (!result) {
				yield fork(showNotification, 'ERROR', 'Utm data saving error!');
			}
		} catch (error) {
			yield fork(
				showNotification,
				'ERROR',
				`${error.name}: ${error.error_description}`,
			);
			yield put(leadCrmFieldUpdateError(error));
		}
	}
}
