import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
	table: {
		minWidth: 250,
	},
});

function createData(scheme, api) {
	return { scheme, api };
}

const rows = [
	createData('ga:date', 'day'),
	createData('ga:medium', '"cpc"'),
	createData('ga:source', '"vk"'),
	createData('ga:adClicks', 'clicks'),
	createData('ga:impressions', 'impressions'),
	createData('ga:adCost', 'spent'),
	createData('ga:campaign', 'campaign_name'),
	createData('ga:keyword', '-'),
	createData('ga:adContent', 'ads_name'),
];

export default function VkApiGaMappingInfo() {
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell align="left">
							<b>Ga Data Set Field</b>
						</TableCell>
						<TableCell align="left">
							<a target="_blank" href="https://vk.com/dev/ads.getStatistics">
								<b>Vk Api Field</b>
							</a>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow key={row.scheme}>
							<TableCell align="left">{row.scheme}</TableCell>
							<TableCell align="left">{row.api}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
