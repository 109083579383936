import {
  GA_CUSTOM_DATA_SOURCES_FETCH_START,
  GA_CUSTOM_DATA_SOURCES_FETCH_SUCCESS,
  GA_CUSTOM_DATA_SOURCES_FETCH_ERROR
} from "actions/gaActions";

export const initialState = {
  items: []
};

export default (state = { ...initialState, fetching: false }, action) => {
  switch (action.type) {
    case GA_CUSTOM_DATA_SOURCES_FETCH_START:
      return {
        ...state,
        fetching: true
      };

    case GA_CUSTOM_DATA_SOURCES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        fetching: false
      };

    case GA_CUSTOM_DATA_SOURCES_FETCH_ERROR:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
};
