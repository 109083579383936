import {
  GA_GOALS_LIST_FETCH_START,
  GA_GOALS_LIST_FETCH_SUCCESS,
  GA_GOALS_LIST_FETCH_ERROR
} from "actions/gaActions";

export const initialState = {
  items: []
};

export default (state = { ...initialState, fetching: false }, action) => {
  switch (action.type) {
    case GA_GOALS_LIST_FETCH_START:
      return {
        ...state,
        fetching: true
      };

    case GA_GOALS_LIST_FETCH_SUCCESS:
      const newItemsFetchSuccess = action.payload.items.filter(
        newItem =>
          !state.items.some(
            existItem =>
              "" + existItem.id + existItem.profileId ==
              "" + newItem.id + newItem.profileId
          )
      );

      return {
        ...state,
        items: [...state.items, ...newItemsFetchSuccess],
        fetching: false
      };

    case GA_GOALS_LIST_FETCH_ERROR:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
};
