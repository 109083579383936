import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import parse from 'html-react-parser';
/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import PanToolIcon from '@material-ui/icons/PanTool';

/** Other components */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Backdrop from '@material-ui/core/Backdrop';
import Link from '@material-ui/core/Link';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';
import { AlertDialog } from 'components/common/Dialog';

/** Sub components */
import Goal from './Goal';

/** Actions */
import {
	gaAccountSummariesFetchStart,
	gaGoalsListFetchStart,
	gaStreamsListFetchStart,
	gaMeasurementProtocolSecretsListFetchStart,
} from 'actions/gaActions';

/** Helpers */
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

/** API */

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	chipContainer: {
		width: '500px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	goalsList: {
		paddingLeft: 30,
	},
}));

const GaProfile = ({
	SettingsModel,
	settingKeyStages,
	settingKeyCategories,
	stageSave,
	isFetching,
	loadingState,
	setLoadingState,
	categoryId,
	categoryList,
	stageList,
	stagesReset,
	expertMode,
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const [activeStep, setActiveStep] = useState(0);
	const [stepEditState, setStepEditState] = useState(false);

	const [gaAccountIdState, setGaAccountIdState] = useState(false);
	const [webPropertyIdState, setWebPropertyIdState] = useState(false);
	const [internalWebPropertyIdState, setInternalWebPropertyIdState] = useState(
		false,
	);
	const [streamIdState, setStreamIdState] = useState(false);
	const [streamNameState, setStreamNameState] = useState(false);

	const [
		measurementProtocolSecretNameState,
		setMeasurementProtocolSecretNameState,
	] = useState(false);

	const [
		measurementProtocolSecretKeyState,
		setMeasurementProtocolSecretKeyState,
	] = useState(false);

	const [alertDialgState, setAlertDialogState] = useState({
		open: false,
		title: '',
		description: '',
		agreeHandler: () => { },
		closeHandler: () => { },
	});

	const fetchAccountSummaries = () =>
		dispatch(gaAccountSummariesFetchStart({}));

	const fetchGoalsList = params => dispatch(gaGoalsListFetchStart(params));

	const fetchStreamsList = params => dispatch(gaStreamsListFetchStart(params));

	const fetchMeasurementProtocolSecretsList = params =>
		dispatch(gaMeasurementProtocolSecretsListFetchStart(params));

	useEffect(() => {
		fetchAccountSummaries();
	}, [dispatch]);

	const currentCategory = useMemo(() => {
		return (
			SettingsModel[settingKeyCategories].find(
				category => category.ID == categoryId,
			) || {}
		);
	}, [SettingsModel]);

	const gaAccountIdSettings = useMemo(() => {
		return CONFIG.GA_ACCOUNT_ID in currentCategory
			? currentCategory[CONFIG.GA_ACCOUNT_ID]
			: false;
	}, [SettingsModel]);

	const webPropertyIdSettings = useMemo(() => {
		return CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID in currentCategory
			? currentCategory[CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID]
			: false;
	}, [SettingsModel]);

	const internalWebPropertyIdSettings = useMemo(() => {
		return CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID in currentCategory
			? currentCategory[CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID]
			: false;
	}, [SettingsModel]);

	const profileIdSettings = useMemo(() => {
		return CONFIG.GA_ACCOUNT_PROFILE_ID in currentCategory
			? currentCategory[CONFIG.GA_ACCOUNT_PROFILE_ID]
			: false;
	}, [SettingsModel]);

	const streamIdSettings = useMemo(() => {
		return CONFIG.GA_STREAM_ID in currentCategory
			? currentCategory[CONFIG.GA_STREAM_ID]
			: false;
	}, [SettingsModel]);

	const streamNameSettings = useMemo(() => {
		return CONFIG.GA_STREAM_NAME in currentCategory
			? currentCategory[CONFIG.GA_STREAM_NAME]
			: false;
	}, [SettingsModel]);

	const measurementProtocolSecretNameSettings = useMemo(() => {
		return CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_NAME in currentCategory
			? currentCategory[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_NAME]
			: false;
	}, [SettingsModel]);

	const measurementProtocolSecretKeySettings = useMemo(() => {
		return CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_VALUE in currentCategory
			? currentCategory[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_VALUE]
			: false;
	}, [SettingsModel]);

	const accountSummaries =
		useSelector(state => state.ga_account_summaries) || [];
	const goalsIsFetching = useSelector(state => state.ga_goals.fetching);
	const streamsIsFetching = useSelector(state => state.ga_streams.fetching);
	const measurementProtocolSecretIsFetching = useSelector(
		state => state.ga_measurement_protocol_secrets.fetching,
	);
	const webPropertiesList =
		useSelector(state => {
			if (
				'items' in state.ga_account_summaries &&
				state.ga_account_summaries.items.length > 0 &&
				gaAccountIdSettings
			) {
				const currentAccount =
					state.ga_account_summaries.items.find(
						account => account.id == gaAccountIdSettings,
					) || {};

				if ('webProperties' in currentAccount)
					return currentAccount.webProperties;
			}

			return [];
		}) || [];

	const profileList = useSelector(state => {
		/*
		if (
			'items' in state.ga_account_summaries &&
			state.ga_account_summaries.items.length > 0 &&
			gaAccountIdSettings &&
			webPropertyIdSettings
		) {
			const webProperties =
				state.ga_account_summaries.items.find(
					account => account.id == gaAccountIdSettings,
				).webProperties || [];

			const currentWebProperty =
				webProperties.find(property => property.id == webPropertyIdSettings) ||
				{};

			if ('profiles' in currentWebProperty) return currentWebProperty.profiles;
		}
		*/
		return [];
	});

	const streamList = useSelector(state => state.ga_streams.items) || [];

	const goalsList =
		useSelector(state =>
			state.ga_goals.items.filter(
				goal =>
					streamIdSettings &&
					measurementProtocolSecretKeySettings &&
					goal.webPropertyId == webPropertyIdSettings,
			),
		) || [];

	const measurementProtocolSecretsList =
		useSelector(state => state.ga_measurement_protocol_secrets.items) || [];

	useEffect(() => {
		if (
			webPropertyIdSettings &&
			streamIdSettings &&
			measurementProtocolSecretKeySettings
		) {
			fetchGoalsList({
				webPropertyId: webPropertyIdSettings,
			});
		}
	}, [
		webPropertyIdSettings,
		streamIdSettings,
		measurementProtocolSecretKeySettings,
	]);

	const reloadGoals = useCallback(() => {
		if (
			webPropertyIdSettings &&
			streamIdSettings &&
			measurementProtocolSecretKeySettings
		) {
			fetchGoalsList({
				webPropertyId: webPropertyIdSettings,
			});
		}
	}, [
		webPropertyIdSettings,
		streamIdSettings,
		measurementProtocolSecretKeySettings,
	]);

	useEffect(() => {
		if (webPropertyIdSettings) {
			fetchStreamsList({
				webPropertyId: webPropertyIdSettings,
			});
		}
	}, [webPropertyIdSettings]);

	const reloadStreams = useCallback(() => {
		fetchStreamsList({
			webPropertyId: webPropertyIdSettings,
		});
	});

	useEffect(() => {
		if (streamNameSettings) {
			fetchMeasurementProtocolSecretsList({
				parent: streamNameSettings,
			});
		}
	}, [streamNameSettings]);

	const reloadMeasurementProtocolSecrets = useCallback(() => {
		fetchMeasurementProtocolSecretsList({
			parent: streamNameSettings,
		});
	});

	useEffect(() => {
		const isSomeFetching = [
			isFetching('categoryGaAccount', categoryId),
			isFetching('categoryWebProperty', categoryId),
			isFetching('categoryProfile', categoryId),
			isFetching('categoryStream', categoryId),
			isFetching('categoryMeasurementProtocolSecret', categoryId),
		].some(fetching => fetching);

		if (gaAccountIdSettings && stepEditState === 1 && !isSomeFetching) {
			setActiveStep(1);
		}

		if (webPropertyIdSettings && stepEditState === 2 && !isSomeFetching) {
			setActiveStep(2);
		}

		if (streamIdSettings && stepEditState === 3 && !isSomeFetching) {
			setActiveStep(3);
		}

		if (
			measurementProtocolSecretNameState &&
			stepEditState === 4 &&
			!isSomeFetching
		) {
			setActiveStep(4);
		}

		if (gaAccountIdSettings && !webPropertyIdSettings && !stepEditState) {
			setActiveStep(1);
		}

		if (
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			!streamIdSettings &&
			!measurementProtocolSecretNameSettings &&
			!stepEditState
		)
			setActiveStep(2);

		if (
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			streamIdSettings &&
			!measurementProtocolSecretNameSettings &&
			!stepEditState
		)
			setActiveStep(3);

		if (
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			streamIdSettings &&
			measurementProtocolSecretNameSettings &&
			!stepEditState
		)
			setActiveStep(4);

		if (stepEditState === 0) setActiveStep(0);
	});

	const gaAccountIdEdit = useCallback(event => {
		setGaAccountIdState(event.target.value);
	});

	const webPropertyIdEdit = useCallback(event => {
		setWebPropertyIdState(event.target.value);
		const { internalWebPropertyId = false } =
			webPropertiesList.length > 0
				? webPropertiesList.find(
					webProperty => webProperty.id === event.target.value,
				)
				: false;
		setInternalWebPropertyIdState(internalWebPropertyId);
	});

	const streamIdEdit = useCallback(event => {
		const name = event.target.value;

		const data = streamList.find(stream => stream.name === name);

		setStreamIdState(data?.webStreamData?.measurementId);
		setStreamNameState(data?.name);
	});

	const measurementProtocolSecretEdit = useCallback(event => {
		const displayName = event.target.value;

		const data = measurementProtocolSecretsList.find(
			measurementProtocolSecret =>
				measurementProtocolSecret.displayName === displayName,
		);

		setMeasurementProtocolSecretKeyState(data?.secretValue);
		setMeasurementProtocolSecretNameState(data?.displayName);
	});

	const allStepsCompleted = useMemo(() => {
		return gaAccountIdSettings && webPropertyIdSettings && streamIdSettings;
	}, [gaAccountIdSettings, profileIdSettings]);

	const withAlertAllSettingsReset = handler =>
		withAlertDialog(
			handler,
			true,
			t('Attention!'),
			t('All relation between goals and stages will be deleted'),
		);

	const withAlertAllGoalsReset = handler =>
		withAlertDialog(
			handler,
			allStepsCompleted,
			t('Attention!'),
			t('All relation between goals and stages will be deleted'),
		);

	const withAlertDialog = (agreeHandler, condition, title, description) => {
		return () => {
			if (condition) {
				setAlertDialogState({
					open: true,
					title,
					agreeHandler,
					description,
					closeHandler: setAlertDialogState.bind(this, {
						open: false,
						title: '',
						description: '',
						agreeHandler: () => { },
						closeHandler: () => { },
					}),
				});
			} else {
				agreeHandler();
			}
		};
	};

	const gaAccountIdSave = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.GA_ACCOUNT_ID]: gaAccountIdState,
			[CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID]: false,
			[CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID]: false,
			[CONFIG.GA_ACCOUNT_PROFILE_ID]: false,
			[CONFIG.GA_STREAM_ID]: false,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_NAME]: false,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_VALUE]: false,
		};

		if (allStepsCompleted) {
			let listForDelete = [];
			for (let { STATUS_ID } of stageList.items) {
				listForDelete.push(STATUS_ID);
			}
			stagesReset(listForDelete);
		}

		/** Reset next step values */
		setWebPropertyIdState(false);
		setStreamIdState(false);
		setMeasurementProtocolSecretNameState(false);
		setMeasurementProtocolSecretKeyState(false);
		setLoadingState({
			...loadingState,
			categoryGaAccount: { [categoryId]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(1);
	};

	const webProperyIdSave = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID]: webPropertyIdState,
			[CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID]: internalWebPropertyIdState,
			[CONFIG.GA_ACCOUNT_PROFILE_ID]: false,
			[CONFIG.GA_STREAM_ID]: false,
			[CONFIG.GA_STREAM_NAME]: false,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_NAME]: false,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_VALUE]: false,
		};

		if (allStepsCompleted) {
			let listForDelete = [];
			for (let { STATUS_ID } of stageList.items) {
				listForDelete.push(STATUS_ID);
			}
			stagesReset(listForDelete);
		}

		/** Reset next step values */
		setStreamIdState(false);
		setMeasurementProtocolSecretNameState(false);
		setMeasurementProtocolSecretKeyState(false);

		setLoadingState({
			...loadingState,
			categoryWebProperty: { [categoryId]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(2);
	};

	const streamIdSave = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.GA_STREAM_ID]: streamIdState,
			[CONFIG.GA_STREAM_NAME]: streamNameState,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_NAME]: false,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_VALUE]: false,
		};

		if (allStepsCompleted) {
			let listForDelete = [];
			for (let { STATUS_ID } of stageList.items) {
				listForDelete.push(STATUS_ID);
			}
			stagesReset(listForDelete);
		}

		setMeasurementProtocolSecretNameState(false);
		setMeasurementProtocolSecretKeyState(false);

		setLoadingState({
			...loadingState,
			categoryStream: { [categoryId]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(3);
	};

	const measurementProtocolSecretSave = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_NAME]: measurementProtocolSecretNameState,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_VALUE]: measurementProtocolSecretKeyState,
		};

		if (allStepsCompleted) {
			let listForDelete = [];
			for (let { STATUS_ID } of stageList.items) {
				listForDelete.push(STATUS_ID);
			}
			stagesReset(listForDelete);
		}

		setLoadingState({
			...loadingState,
			categoryMeasurementProtocolSecret: { [categoryId]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(4);
	};

	const resetStagesForce = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.GA_ACCOUNT_ID]: false,
			[CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID]: false,
			[CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID]: false,
			[CONFIG.GA_ACCOUNT_PROFILE_ID]: false,
			[CONFIG.GA_STREAM_ID]: false,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_NAME]: false,
			[CONFIG.GA_MEASUREMENT_PROTOCOL_SECRETS_VALUE]: false,
		};
		let listForDelete = [];
		for (let { STATUS_ID } of stageList.items) {
			listForDelete.push(STATUS_ID);
		}
		stagesReset(listForDelete);

		/** Reset next step values */
		setGaAccountIdState(false);
		setWebPropertyIdState(false);
		setStreamIdState(false);
		setMeasurementProtocolSecretNameState(false);
		setMeasurementProtocolSecretKeyState(false);

		SettingsModel.saveSettings();
		setStepEditState(0);
	};

	const backToAccount = () => {
		setStepEditState(0);
	};

	const backToWebProperty = () => {
		setStepEditState(1);
	};

	const backToStream = () => {
		setStepEditState(2);
	};

	const backToMeasurementProtocolSecret = () => {
		setStepEditState(3);
	};

	const backToProfile = () => {
		setStepEditState(2);
	};

	const backToGoals = () => {
		setStepEditState(4);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const linkGoalsEditHref = useMemo(() => {
		if (
			gaAccountIdSettings &&
			internalWebPropertyIdSettings &&
			profileIdSettings
		)
			return `https://analytics.google.com/analytics/web/#/a${gaAccountIdSettings}w${internalWebPropertyIdSettings}p${profileIdSettings}/admin/goals/table`;

		return null;
	}, [gaAccountIdSettings, internalWebPropertyIdSettings, profileIdSettings]);

	const LinkGoalsEdit = useCallback(
		() => (
			<>
				{linkGoalsEditHref ? (
					<Link target="_blank" href={linkGoalsEditHref}>
						✏️ {t('Edit goals in Google Analytics account')}
					</Link>
				) : null}
			</>
		),
		[linkGoalsEditHref],
	);

	const isNeedToReset = !!(
		gaAccountIdSettings && 'items' in accountSummaries && accountSummaries.items.length > 0 &&
		!accountSummaries.items.find(account => account.id == gaAccountIdSettings)
	);

	return (
		<div className={classes.root}>
			<AlertDialog {...alertDialgState} />
			{isNeedToReset ? (
				<div
					style={{
						textAlign: 'center',
						paddingTop: '100px',
						marginBottom: '300px',
					}}
				>
					<Button
						onClick={withAlertAllSettingsReset(resetStagesForce)}
						variant="contained"
						color={'secondary'}
					>
						{t('Need to reset settings to get started with GA4')}
					</Button>
				</div>
			) : null}
			{isNeedToReset ? null : (
				<Stepper
					activeStep={activeStep}
					disabled={isNeedToReset}
					orientation="vertical"
				>
					<Step key={0}>
						<StepLabel>
							{gaAccountIdSettings &&
								accountSummaries.items.some(
									account => account.id == gaAccountIdSettings,
								) ? (
								<StepLabel>
									{t('Google Analytics Account')}:&nbsp;
									{
										accountSummaries.items.find(
											account => account.id == gaAccountIdSettings,
										)['name']
									}
									{gaAccountIdSettings && activeStep > 0 ? (
										<Button onClick={backToAccount}>
											<EditIcon fontSize="small" />
										</Button>
									) : null}
								</StepLabel>
							) : (
								<StepLabel>{t('Google Analytics Account')}</StepLabel>
							)}
						</StepLabel>
						<StepContent>
							<FormControl variant="outlined">
								<InputLabel htmlFor="outlined-age-native-simple">
									{'account'}
								</InputLabel>
								<Select
									label={'account'}
									onChange={gaAccountIdEdit}
									value={
										gaAccountIdState !== false
											? gaAccountIdState
											: gaAccountIdSettings
												? gaAccountIdSettings
												: 'default'
									}
									style={{ width: 390 }}
									inputProps={{ 'aria-label': 'Without label' }}
								>
									<MenuItem value="default">Default</MenuItem>
									{accountSummaries.items.map((account, i) => (
										<MenuItem value={account.id} key={i}>
											{account.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<div className={classes.actionsContainer}>
								<div>
									<Button
										variant="contained"
										color="primary"
										onClick={withAlertAllGoalsReset(gaAccountIdSave)}
										className={classes.button}
										disabled={
											!gaAccountIdState ||
											gaAccountIdState == gaAccountIdSettings ||
											gaAccountIdState == 'default' ||
											isFetching('categoryGaAccount', categoryId)
										}
									>
										{t('Save')}
										<SaveIcon />
										{isFetching('categoryGaAccount', categoryId) ? (
											<CircularProgressStyled size={24} />
										) : null}
									</Button>
								</div>
							</div>
						</StepContent>
					</Step>
					<Step key={1}>
						{webPropertyIdSettings &&
							webPropertiesList.some(
								webProperty => webProperty.id == webPropertyIdSettings,
							) ? (
							<StepLabel>
								{t('Select property')}:&nbsp;
								{
									webPropertiesList.find(
										webProperty => webProperty.id == webPropertyIdSettings,
									)['name']
								}
								{webPropertyIdSettings && activeStep !== 1 ? (
									<Button onClick={backToWebProperty}>
										<EditIcon fontSize="small" />
									</Button>
								) : null}
							</StepLabel>
						) : (
							<StepLabel>{t('Select property')}</StepLabel>
						)}

						<StepContent>
							<FormControl variant="outlined">
								<InputLabel htmlFor="outlined-age-native-simple">
									{'property'}
								</InputLabel>
								<Select
									label={'property'}
									style={{ width: 390 }}
									onChange={webPropertyIdEdit}
									inputProps={{ 'aria-label': 'Without label' }}
									value={
										webPropertyIdState !== false
											? webPropertyIdState
											: webPropertyIdSettings
												? webPropertyIdSettings
												: 'default'
									}
								>
									<MenuItem value="default">Default</MenuItem>
									{webPropertiesList.map((webProperty, i) => (
										<MenuItem value={webProperty.id} key={webProperty.id}>
											{`${webProperty.name}`}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<div className={classes.actionsContainer}>
								<div>
									<Button
										variant="contained"
										color="primary"
										onClick={withAlertAllGoalsReset(webProperyIdSave)}
										className={classes.button}
										disabled={
											!webPropertyIdState ||
											webPropertyIdState == webPropertyIdSettings ||
											webPropertyIdState == 'default' ||
											isFetching('categoryWebProperty', categoryId)
										}
									>
										{t('Save')}
										<SaveIcon />
										{isFetching('categoryWebProperty', categoryId) ? (
											<CircularProgressStyled size={24} />
										) : null}
									</Button>
								</div>
							</div>
						</StepContent>
					</Step>

					<Step key={2} disabled>
						{streamIdSettings &&
							streamList
								.filter(stream => 'webStreamData' in stream)
								.some(
									stream =>
										stream.webStreamData.measurementId == streamIdSettings,
								) ? (
							<StepLabel>
								{t('Select stream')}:&nbsp;
								{
									streamList
										.filter(stream => 'webStreamData' in stream)
										.find(
											stream =>
												stream.webStreamData.measurementId == streamIdSettings,
										)['displayName']
								}
								{streamIdSettings && activeStep !== 2 ? (
									<Button onClick={backToStream}>
										<EditIcon fontSize="small" />
									</Button>
								) : null}
							</StepLabel>
						) : (
							<StepLabel>{t('Select stream')}</StepLabel>
						)}
						<StepContent>
							<FormControl variant="outlined">
								<InputLabel htmlFor="outlined-age-native-simple">
									{'stream'}
								</InputLabel>
								<Select
									label={'stream'}
									style={{ width: 390 }}
									onChange={streamIdEdit}
									inputProps={{ 'aria-label': 'Without label' }}
									disabled={
										streamsIsFetching ||
										isFetching('categoryStream', categoryId)
									}
									value={
										streamNameState !== false
											? streamNameState
											: streamNameSettings
												? streamNameSettings
												: 'default'
									}
								>
									<MenuItem value="default">Default</MenuItem>
									{streamList
										.filter(stream => 'webStreamData' in stream)
										.map((stream, i) => (
											<MenuItem value={stream.name} key={stream.name}>
												{`${stream.displayName} ${stream.webStreamData.measurementId}`}
											</MenuItem>
										))}
								</Select>
							</FormControl>
							<div className={classes.actionsContainer}>
								<div>
									<Button
										variant="contained"
										color="primary"
										onClick={streamIdSave}
										className={classes.button}
										disabled={
											!streamIdState ||
											streamIdState == streamIdSettings ||
											streamIdState == 'default' ||
											isFetching('categoryStream', categoryId) ||
											streamsIsFetching
										}
									>
										{t('Save')}
										<SaveIcon />
										{isFetching('categoryStream', categoryId) ? (
											<CircularProgressStyled size={24} />
										) : null}
									</Button>
								</div>
							</div>
						</StepContent>
					</Step>

					<Step key={3} disabled>
						{measurementProtocolSecretNameSettings &&
							measurementProtocolSecretsList.some(
								measurementProtocolSecret =>
									measurementProtocolSecret.displayName ==
									measurementProtocolSecretNameSettings,
							) ? (
							<StepLabel>
								{t('Select API key Measurement Protocol')}:&nbsp;
								{
									measurementProtocolSecretsList.find(
										measurementProtocolSecret =>
											measurementProtocolSecret.displayName ==
											measurementProtocolSecretNameSettings,
									)['displayName']
								}
								{measurementProtocolSecretNameSettings && activeStep !== 3 ? (
									<Button onClick={backToMeasurementProtocolSecret}>
										<EditIcon fontSize="small" />
									</Button>
								) : null}
							</StepLabel>
						) : (
							<StepLabel>{t('Select API key Measurement Protocol')}</StepLabel>
						)}
						<StepContent>
							<FormControl variant="outlined">
								<InputLabel htmlFor="outlined-age-native-simple">
									{'API Key'}
								</InputLabel>
								<Select
									label={'API Key'}
									style={{ width: 390 }}
									onChange={measurementProtocolSecretEdit}
									inputProps={{ 'aria-label': 'Without label' }}
									disabled={
										isFetching(
											'categoryMeasurementProtocolSecret',
											categoryId,
										) || measurementProtocolSecretIsFetching
									}
									value={
										measurementProtocolSecretNameState !== false
											? measurementProtocolSecretNameState
											: measurementProtocolSecretNameSettings
												? measurementProtocolSecretNameSettings
												: 'default'
									}
								>
									<MenuItem value="default">Default</MenuItem>
									{measurementProtocolSecretsList.map(
										(measurementProtocolSecret, i) => (
											<MenuItem
												value={measurementProtocolSecret.displayName}
												key={measurementProtocolSecret.displayName}
											>
												{`${measurementProtocolSecret.displayName}`}
											</MenuItem>
										),
									)}
								</Select>
							</FormControl>
							<div className={classes.actionsContainer}>
								<div>
									<Button
										variant="contained"
										color="primary"
										onClick={measurementProtocolSecretSave}
										className={classes.button}
										disabled={
											!measurementProtocolSecretNameState ||
											measurementProtocolSecretNameState ==
											measurementProtocolSecretNameSettings ||
											measurementProtocolSecretNameState == 'default' ||
											isFetching(
												'categoryMeasurementProtocolSecret',
												categoryId,
											) ||
											measurementProtocolSecretIsFetching
										}
									>
										{t('Save')}
										<SaveIcon />
										{isFetching(
											'categoryMeasurementProtocolSecret',
											categoryId,
										) ? (
											<CircularProgressStyled size={24} />
										) : null}
									</Button>
								</div>
							</div>
						</StepContent>
					</Step>

					<Step key={4}>
						<StepLabel>
							{t('Events choise')}
							{goalsList.length && activeStep !== 4 ? (
								<span></span>
							) : (
								<>
									<Button
										onClick={reloadGoals}
										disabled={goalsIsFetching || activeStep !== 4}
									>
										<CachedIcon />
										{goalsIsFetching ? (
											<CircularProgressStyled size={24} />
										) : null}
									</Button>
									<LinkGoalsEdit />
								</>
							)}
						</StepLabel>
					</Step>
				</Stepper>
			)}
			<div className={classes.goalsList}>
				{goalsList.map((goal, i) => (
					<Goal
						SettingsModel={SettingsModel}
						goal={goal}
						streamId={streamIdSettings}
						key={i}
						stageSave={stageSave}
					/>
				))}
				{goalsList.length == 0 && !isNeedToReset
					? t('There are no events in this property')
					: null}
			</div>
		</div>
	);
};

export default GaProfile;
