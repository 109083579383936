import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import InfoSharpIcon from '@material-ui/icons/InfoSharp';

/** Other components */
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

/** Custom component */
import SaveButton from 'components/common/SaveButton';

/** Helpers */
import CONFIG from 'helpers/const';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	option: {
		margin: 10,
		fontSize: '16px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		cursor: 'pointer',
	},
});

const GaCounter = ({
	SettingsModel,
	settingKey,
	label,
	description,
	userFiledsList,
	isFetching,
	loadingState,
	setLoadingState,
	userFieldsListInUse,
}) => {
	const classes = useStyles();

	const [gaCounterState, setGaCounterState] = useState('');

	const gaCounterEdit = useCallback(event => {
		setGaCounterState(event.target.value);
	});

	const gaCounterSave = useCallback(() => {
		SettingsModel[settingKey] = gaCounterState;
		setLoadingState({
			...loadingState,
			[settingKey]: true,
		});

		SettingsModel.saveSettings();
	}, [SettingsModel, gaCounterState, loadingState]);

	console.log('GaCounter Render');

	const gaCounterValue = gaCounterState
		? gaCounterState
		: SettingsModel[settingKey];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h5" component="h5">
					{label}
				</Typography>
				<div className="blockquote-warning">
					<p>ℹ️ {description}</p>
				</div>
			</Grid>
			<Grid item xs={6}>
				<FormControl variant="outlined">
					<InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
					<Select
						native
						value={gaCounterValue}
						onChange={gaCounterEdit}
						label={label}
						style={{ width: 500 }}
					>
						<option value={'default'} className={classes.option}>
							{' '}
							-{' '}
						</option>
						{userFiledsList.items.map((field, i) => (
							<option
								value={field.ID}
								key={`${i}_test`}
								className={classes.option}
								disabled={
									userFieldsListInUse.includes(field.ID) &&
									field.ID != gaCounterValue
								}
							>
								&nbsp;{field.EDIT_FORM_LABEL[CONFIG.LANG.toLocaleLowerCase()]}
							</option>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				<SaveButton
					handler={gaCounterSave}
					disabled={
						!gaCounterState || gaCounterState == SettingsModel[settingKey]
					}
					isFetching={isFetching(settingKey)}
					label={'Сохранить'}
				/>
			</Grid>
		</Grid>
	);
};

export default GaCounter;
