export const TRACK_ID_FETCH_START = 'TRACK_ID_FETCH_START';
export const TRACK_ID_FETCH_SUCCESS = 'TRACK_ID_FETCH_SUCCESS';
export const TRACK_ID_FETCH_ERROR = 'TRACK_ID_FETCH_ERROR';
export const TRACK_ID_NOT_FOUND = 'TRACK_ID_NOT_FOUND';

export const trackIdFetchStart = (payload) => {
    return {
        type: TRACK_ID_FETCH_START,
        payload
    }
}

export const trackIdFetchSuccess = (payload) => {
    return {
        type: TRACK_ID_FETCH_SUCCESS,
        payload
    }
}

export const trackIdFetchError = (payload) => {
    return {
        type: TRACK_ID_FETCH_ERROR,
        payload
    }
}

export const trackIdNotFound = (payload) => {
    return {
        type: TRACK_ID_NOT_FOUND,
        payload
    }
}