import React from 'react';

/** Custom components */
import PopupImage from 'components/common/PopupImage';

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

const AllStepCompletedInfo = () => {
	const { t } = useTranslationWithParse();

	return (
		<>
			<div className="blockquote-well-done">
				<p>
					⏱️{' '}
					{t('Everything is configured, data will be loaded within 24 hours.')}
				</p>
			</div>
			<div className="blockquote-warning">
				<p>
					<b>⚠️{t('Google Analytics. Check this out')}</b>
					<br />
					<br />
					{t('Data set required fileds')}
					<PopupImage
						src={'/resources/DataSetImportBehavior.jpg'}
						title={t('Import Behavior should be equal Owerwrite')}
						description={t('Costs will be overwritten')}
					/>
				</p>
			</div>
		</>
	);
};

export default AllStepCompletedInfo;
