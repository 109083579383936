import {
  SOURCES_FETCH_START,
  SOURCES_FETCH_SUCCESS,
  SOURCES_FETCH_ERROR
} from "actions/sourcesActions";

const initialState = {
  items: []
};

export default (state = { ...initialState, fetching: false }, action) => {
  switch (action.type) {
    case SOURCES_FETCH_START:
      return {
        ...state,
        fetching: true
      };

    case SOURCES_FETCH_SUCCESS:
      let items = action.payload.length ? action.payload : state.items;
      return {
        ...state,
        items,
        fetching: false
      };

    case SOURCES_FETCH_ERROR:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
};
