import {
  LEADS_CATEGORY_STAGE_FETCH_START,
  LEADS_CATEGORY_STAGE_FETCH_SUCCESS,
  LEADS_CATEGORY_STAGE_FETCH_ERROR
} from "actions/leadsActions";

let initialState = {
  items: []
};

export default (state = { ...initialState, fetching: {} }, action) => {
  switch (action.type) {
    case LEADS_CATEGORY_STAGE_FETCH_START:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.payload.id]: true
        }
      };

    case LEADS_CATEGORY_STAGE_FETCH_SUCCESS:
      return {
        ...state,
        items: [
          ...state.items,
          ...action.payload.filter(payloadItem => {
            return !state.items.some(
              stateItem => payloadItem.STATUS_ID == stateItem.STATUS_ID
            );
          })
        ],
        fetching: {
          ...state.fetching,
          [1]: false
        }
      };

    case LEADS_CATEGORY_STAGE_FETCH_ERROR:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.payload.data.id]: false
        }
      };

    default:
      return state;
  }
};
