import React, {
	Component,
	useState,
	forwardRef,
	useEffect,
	useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import PanToolIcon from '@material-ui/icons/PanTool';
import DeleteIcon from '@material-ui/icons/Delete';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';

/** Other components */
import Drawer from '@material-ui/core/Drawer';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Backdrop from '@material-ui/core/Backdrop';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Badge from '@material-ui/core/Badge';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';
import { AlertDialog } from 'components/common/Dialog';

/** Sub components */
import CostsList from './CostsList';
import SourcesList from './SourcesList';

/** Actions */
import { logsFetchStart } from 'actions/logsActions';
import { settingsUpdateStart } from 'actions/settingsActions';

/** Helpers */
import { fitWindow } from 'helpers/utils';
import { Settings } from 'models/settings';
import { checkFetching } from 'helpers/utils';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		height: 500,
		width: '100%',
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	chipContainer: {
		width: '500px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	goalsList: {
		paddingLeft: 30,
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	table: {
		minWidth: 700,
	},
	paper: {
		maxWidth: 400,
		margin: `${theme.spacing(1)}px auto`,
		padding: theme.spacing(2),
	},
}));

const Costs = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const settingsList = useSelector(state => state.settings);

	const SettingsModel = new Settings(settingsList, {
		update: payload => dispatch(settingsUpdateStart(payload)),
	});

	const defaultLoadingState = {
		costs: {},
		sourceList: {},
	};

	const [loadingState, setLoadingState] = useState(defaultLoadingState);

	useEffect(() => {
		if (!settingsList.fetching) {
			setLoadingState(defaultLoadingState);
		}
	}, [settingsList.fetching]);

	const isFetching = checkFetching(loadingState);

	return (
		<div>
			<Typography variant="h5">{t(`Costs settings title`)}</Typography>
			<div className="blockquote-info">
				<p>ℹ️ {t(`Costs settings description`)}</p>
			</div>
			<br />
			<SourcesList
				SettingsModel={SettingsModel}
				loadingState={loadingState}
				setLoadingState={setLoadingState}
				isFetching={isFetching}
			/>
			<br />
			<br />
			<br />
			<Typography variant="h5">{t('ADS sources')}</Typography>
			<br />
			<CostsList
				SettingsModel={SettingsModel}
				loadingState={loadingState}
				setLoadingState={setLoadingState}
				isFetching={isFetching}
				costs={settingsList.costs}
			/>
		</div>
	);
};

export default Costs;
