export const AUTH_CHECK_GA_FETCH_START = 'AUTH_CHECK_GA_FETCH_START';
export const AUTH_CHECK_GA_FETCH_SUCCESS = 'AUTH_CHECK_GA_FETCH_SUCCESS';
export const AUTH_CHECK_GA_FETCH_ERROR = 'AUTH_CHECK_GA_FETCH_ERROR';

export const authCheckGaFetchStart = payload => {
	return {
		type: AUTH_CHECK_GA_FETCH_START,
		payload,
	};
};

export const authCheckGaFetchSuccess = payload => {
	return {
		type: AUTH_CHECK_GA_FETCH_SUCCESS,
		payload,
	};
};

export const authCheckGaFetchError = payload => {
	return {
		type: AUTH_CHECK_GA_FETCH_ERROR,
		payload,
	};
};

export const AUTH_REVOKE_GA_FETCH_START = 'AUTH_REVOKE_GA_FETCH_START';
export const AUTH_REVOKE_GA_FETCH_SUCCESS = 'AUTH_REVOKE_GA_FETCH_SUCCESS';
export const AUTH_REVOKE_GA_FETCH_ERROR = 'AUTH_REVOKE_GA_FETCH_ERROR';

export const authRevokeGaFetchStart = payload => {
	return {
		type: AUTH_REVOKE_GA_FETCH_START,
		payload,
	};
};

export const authRevokeGaFetchSuccess = payload => {
	return {
		type: AUTH_REVOKE_GA_FETCH_SUCCESS,
		payload,
	};
};

export const authRevokeGaFetchError = payload => {
	return {
		type: AUTH_REVOKE_GA_FETCH_ERROR,
		payload,
	};
};

export const AUTH_CHECK_FB_FETCH_START = 'AUTH_CHECK_FB_FETCH_START';
export const AUTH_CHECK_FB_FETCH_SUCCESS = 'AUTH_CHECK_FB_FETCH_SUCCESS';
export const AUTH_CHECK_FB_FETCH_ERROR = 'AUTH_CHECK_FB_FETCH_ERROR';

export const authCheckFbFetchStart = payload => {
	return {
		type: AUTH_CHECK_FB_FETCH_START,
		payload,
	};
};

export const authCheckFbFetchSuccess = payload => {
	return {
		type: AUTH_CHECK_FB_FETCH_SUCCESS,
		payload,
	};
};

export const authCheckFbFetchError = payload => {
	return {
		type: AUTH_CHECK_FB_FETCH_ERROR,
		payload,
	};
};

export const AUTH_REVOKE_FB_FETCH_START = 'AUTH_REVOKE_FB_FETCH_START';
export const AUTH_REVOKE_FB_FETCH_SUCCESS = 'AUTH_REVOKE_FB_FETCH_SUCCESS';
export const AUTH_REVOKE_FB_FETCH_ERROR = 'AUTH_REVOKE_FB_FETCH_ERROR';

export const authRevokeFbFetchStart = payload => {
	return {
		type: AUTH_REVOKE_FB_FETCH_START,
		payload,
	};
};

export const authRevokeFbFetchSuccess = payload => {
	return {
		type: AUTH_REVOKE_FB_FETCH_SUCCESS,
		payload,
	};
};

export const authRevokeFbFetchError = payload => {
	return {
		type: AUTH_REVOKE_FB_FETCH_ERROR,
		payload,
	};
};

export const AUTH_CHECK_VK_FETCH_START = 'AUTH_CHECK_VK_FETCH_START';
export const AUTH_CHECK_VK_FETCH_SUCCESS = 'AUTH_CHECK_VK_FETCH_SUCCESS';
export const AUTH_CHECK_VK_FETCH_ERROR = 'AUTH_CHECK_VK_FETCH_ERROR';

export const authCheckVkFetchStart = payload => {
	return {
		type: AUTH_CHECK_VK_FETCH_START,
		payload,
	};
};

export const authCheckVkFetchSuccess = payload => {
	return {
		type: AUTH_CHECK_VK_FETCH_SUCCESS,
		payload,
	};
};

export const authCheckVkFetchError = payload => {
	return {
		type: AUTH_CHECK_VK_FETCH_ERROR,
		payload,
	};
};

export const AUTH_REVOKE_VK_FETCH_START = 'AUTH_REVOKE_VK_FETCH_START';
export const AUTH_REVOKE_VK_FETCH_SUCCESS = 'AUTH_REVOKE_VK_FETCH_SUCCESS';
export const AUTH_REVOKE_VK_FETCH_ERROR = 'AUTH_REVOKE_VK_FETCH_ERROR';

export const authRevokeVkFetchStart = payload => {
	return {
		type: AUTH_REVOKE_VK_FETCH_START,
		payload,
	};
};

export const authRevokeVkFetchSuccess = payload => {
	return {
		type: AUTH_REVOKE_VK_FETCH_SUCCESS,
		payload,
	};
};

export const authRevokeVkFetchError = payload => {
	return {
		type: AUTH_REVOKE_VK_FETCH_ERROR,
		payload,
	};
};

export const AUTH_CHECK_YA_FETCH_START = 'AUTH_CHECK_YA_FETCH_START';
export const AUTH_CHECK_YA_FETCH_SUCCESS = 'AUTH_CHECK_YA_FETCH_SUCCESS';
export const AUTH_CHECK_YA_FETCH_ERROR = 'AUTH_CHECK_YA_FETCH_ERROR';

export const authCheckYaFetchStart = payload => {
	return {
		type: AUTH_CHECK_YA_FETCH_START,
		payload,
	};
};

export const authCheckYaFetchSuccess = payload => {
	return {
		type: AUTH_CHECK_YA_FETCH_SUCCESS,
		payload,
	};
};

export const authCheckYaFetchError = payload => {
	return {
		type: AUTH_CHECK_YA_FETCH_ERROR,
		payload,
	};
};

export const AUTH_REVOKE_YA_FETCH_START = 'AUTH_REVOKE_YA_FETCH_START';
export const AUTH_REVOKE_YA_FETCH_SUCCESS = 'AUTH_REVOKE_YA_FETCH_SUCCESS';
export const AUTH_REVOKE_YA_FETCH_ERROR = 'AUTH_REVOKE_YA_FETCH_ERROR';

export const authRevokeYaFetchStart = payload => {
	return {
		type: AUTH_REVOKE_YA_FETCH_START,
		payload,
	};
};

export const authRevokeYaFetchSuccess = payload => {
	return {
		type: AUTH_REVOKE_YA_FETCH_SUCCESS,
		payload,
	};
};

export const authRevokeYaFetchError = payload => {
	return {
		type: AUTH_REVOKE_YA_FETCH_ERROR,
		payload,
	};
};
