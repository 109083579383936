export const DEALS_CATEGORY_FETCH_START = 'DEALS_CATEGORY_FETCH_START';
export const DEALS_CATEGORY_FETCH_SUCCESS = 'DEALS_CATEGORY_FETCH_SUCCESS';
export const DEALS_CATEGORY_FETCH_ERROR = 'DEALS_CATEGORY_FETCH_ERROR';

export const DEALS_CATEGORY_STAGE_FETCH_START = 'DEALS_CATEGORY_STAGE_FETCH_START';
export const DEALS_CATEGORY_STAGE_FETCH_SUCCESS = 'DEALS_CATEGORY_STAGE_FETCH_SUCCESS';
export const DEALS_CATEGORY_STAGE_FETCH_ERROR = 'DEALS_CATEGORY_STAGE_FETCH_ERROR';

export const DEALS_USER_FIELDS_FETCH_START= 'DEALS_USER_FIELDS_FETCH_START';
export const DEALS_USER_FIELDS_FETCH_SUCCESS = 'DEALS_USER_FIELDS_FETCH_SUCCESS';
export const DEALS_USER_FIELDS_FETCH_ERROR = 'DEALS_USER_FIELDS_FETCH_ERROR';

export const DEAL_CRM_FIELDS_UPDATE_START = 'DEAL_CRM_FIELDS_UPDATE_START';
export const DEAL_CRM_FIELDS_UPDATE_SUCCESS = 'DEAL_CRM_FIELDS_UPDATE_SUCCESS';
export const DEAL_CRM_FIELDS_UPDATE_ERROR = 'DEAL_CRM_FIELDS_UPDATE_ERROR';

/**
 * deals category Fetch
 */
export const dealsCategoryFetchStart = (payload) => {
    return {
        type: DEALS_CATEGORY_FETCH_START,
        payload
    }
}

export const dealsCategoryFetchSuccess = (payload) => {
    return {
        type: DEALS_CATEGORY_FETCH_SUCCESS,
        payload
    }
}

export const dealsCategoryFetchError = (payload) => {
    return {
        type: DEALS_CATEGORY_FETCH_ERROR,
        payload
    }
}

/**
 * deals category stage Fetch
 */

export const dealsCategoryStageFetchStart = (payload) => {
    return {
        type: DEALS_CATEGORY_STAGE_FETCH_START,
        payload
    }
}

export const dealsCategoryStageFetchSuccess = (payload) => {
    return {
        type: DEALS_CATEGORY_STAGE_FETCH_SUCCESS,
        payload
    }
}

export const dealsCategoryStageFetchError = (payload) => {
    return {
        type: DEALS_CATEGORY_STAGE_FETCH_ERROR,
        payload
    }
}


/**
 * deals category user fields
 */

export const dealsUserFieldsFetchStart = (payload) => {
    return {
        type: DEALS_USER_FIELDS_FETCH_START,
        payload
    }
}

export const dealsUserFieldsFetchSuccess = (payload) => {
    return {
        type: DEALS_USER_FIELDS_FETCH_SUCCESS,
        payload
    }
}

export const dealsUserFieldsFetchError = (payload) => {
    return {
        type: DEALS_USER_FIELDS_FETCH_ERROR,
        payload
    }
}

export const dealCrmFieldUpdateStart = (payload) => {
    return {
        type: DEAL_CRM_FIELDS_UPDATE_START,
        payload
    }
}

export const dealCrmFieldUpdateSuccess = (payload) => {
    return {
        type: DEAL_CRM_FIELDS_UPDATE_SUCCESS,
        payload
    }
}

export const dealCrmFieldUpdateError = (payload) => {
    return {
        type: DEAL_CRM_FIELDS_UPDATE_ERROR,
        payload
    }
}








