import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

/** Actions */
import { authCheckFbFetchStart } from 'actions/authActions';

/** Helpers */
import { handleFbAuthWindow } from 'helpers/auth';

const GaAuth = () => {
	const dispatch = useDispatch();

	const fetchAuthCheck = () => dispatch(authCheckFbFetchStart({}));

	window.authSuccess = function () {
		fetchAuthCheck();
	};

	const openFbAuth = useCallback(() => {
		handleFbAuthWindow(fetchAuthCheck);
	}, []);

	return (
		<Grid
			container
			spacing={0}
			alignItems="center"
			justify="center"
			style={{ minHeight: '30vh', width: '600px' }}
		>
			<Grid item xs={3}>
				<Button onClick={openFbAuth}>
					FB
				</Button>
			</Grid>
		</Grid>
	);
};

export default GaAuth;
