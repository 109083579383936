import { combineReducers } from 'redux';
import dealsCategoryReducer from './dealsCategoryReducer';
import dealsCategoryStageReducer from './dealsCategoryStageReducer';
import dealsUserFieldsReducer from './dealsUserFieldsReducer';
import dealCrmFieldsReducer from './dealCrmFieldsReducer';

import leadsCategoryReducer from './leadsCategoryReducer';
import leadsCategoryStageReducer from './leadsCategoryStageReducer';
import leadsUserFieldsReducer from './leadsUserFieldsReducer';
import leadCrmFieldsReducer from './leadCrmFieldsReducer';

import gaAccountSummariesReducer from './gaAccountSummariesReducer';
import gaCustomDataSourcesReducer from './gaCustomDataSourcesReducer';
import gaGoalsReducer from './gaGoalsReducer';
import gaStreamsReducer from './gaStreamsReducer';
import gaMeasurementProtocolSecretsReducer from './gaMeasurementProtocolSecretsReducer';

import fbAdsAccountsReducer from './fbAdsAccountsReducer';
import vkAdsAccountsReducer from './vkAdsAccountsReducer';
import yaAdsAccountsReducer from './yaAdsAccountsReducer';

import settingsReducer from './settingsReducer';
import sources from './sourcesReducer';
import logs from './logsReducer';
import trackId from './trackIdReducer';
import notificationsReducer from './notificationsReducer';

import authReducer from './authReducer';

const defaultReducer = combineReducers({
	settings: settingsReducer,
	deals_category: dealsCategoryReducer,
	deals_category_stage: dealsCategoryStageReducer,
	deals_user_fields: dealsUserFieldsReducer,
	deal_crm_field: dealCrmFieldsReducer,
	leads_category: leadsCategoryReducer,
	leads_category_stage: leadsCategoryStageReducer,
	leads_user_fields: leadsUserFieldsReducer,
	lead_crm_field: leadCrmFieldsReducer,
	ga_account_summaries: gaAccountSummariesReducer,
	ga_custom_data_sources: gaCustomDataSourcesReducer,
	ga_goals: gaGoalsReducer,
	ga_streams: gaStreamsReducer,
	ga_measurement_protocol_secrets: gaMeasurementProtocolSecretsReducer,
	fb_ads_accounts: fbAdsAccountsReducer,
	vk_ads_accounts: vkAdsAccountsReducer,
	ya_ads_accounts: yaAdsAccountsReducer,
	logs,
	sources,
	track_id: trackId,
	notifications: notificationsReducer,
	auth: authReducer,
});

export default defaultReducer;
