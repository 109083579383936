import { RestApiError } from "error/restError";
import axios from "axios";
import qs from "qs";
import cookie from "helpers/cookie";

export function sourcesList() {
  const params = { entityId: "SOURCE" };

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_B242GA_API}/sources_list`,
    data: qs.stringify({params}),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `bearer ${cookie.token()}`
    }
  }).then(response => {
    return response.data;
  }, error => {
    throw new RestApiError(error);
  });
}
