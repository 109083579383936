import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Icons  */

/** Other components */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

/** Custom components */

/** Sub components */
import CallTrackingItem from './CallTrackingItem';

/** Actions */
import { settingsUpdateStart } from 'actions/settingsActions';

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';
import { checkFetching } from 'helpers/utils';
import { Settings } from 'models/settings';

const CallTracking = () => {
	const { t } = useTranslationWithParse();

	const dispatch = useDispatch();

	const settingsList = useSelector(state => state.settings);

	const SettingsModel = new Settings(settingsList, {
		update: payload => dispatch(settingsUpdateStart(payload)),
	});

	const [loadingState, setLoadingState] = useState({
		call_tracking: {},
	});

	const isFetching = checkFetching(loadingState);

	useEffect(() => {
		if (!settingsList.fetching) {
			Object.keys(loadingState).forEach(settingKey => {
				if (typeof loadingState[settingKey] !== 'object') {
					loadingState[settingKey] = false;
				} else {
					Object.keys(loadingState[settingKey]).forEach(settingId => {
						loadingState[settingKey][settingId] = false;
					});
				}
			});

			setLoadingState(loadingState);
		}
	}, [settingsList]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{' '}
				<Typography variant="h5" component="h5">
					Call tracking (BETA)
				</Typography>
			</Grid>
			<Grid item xs={12}></Grid>
			<Grid item xs={8}>
				<div className="blockquote-warning">
					<p>{t('Call tracking description')}</p>
				</div>
			</Grid>

			<Grid item xs={4}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>
								<Typography variant="h5" component="h5">
									{t('Add new phone')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
						</TableRow>
						<CallTrackingItem
							key={0}
							callTrackerItem={{ id: 'default', phone: '' }}
							SettingsModel={SettingsModel}
							isFetching={isFetching}
							setLoadingState={setLoadingState}
							loadingState={loadingState}
						/>

						{SettingsModel.callTracking.map((callTrackerItem, i) => (
							<CallTrackingItem
								key={i}
								callTrackerItem={callTrackerItem}
								SettingsModel={SettingsModel}
								isFetching={isFetching}
								setLoadingState={setLoadingState}
								loadingState={loadingState}
							/>
						))}
					</TableBody>
				</Table>
			</Grid>
		</Grid>
	);
};

export default CallTracking;
