import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import { AlertDialog } from 'components/common/Dialog';
import { useTranslationWithParse } from 'helpers/translation';
import { authRevokeGaFetchStart } from 'actions/authActions';

const GaAuth = () => {
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const [warningDialogOpenState, setWarningDialogOpenState] = useState(false);

	const revokeGaAuthFetch = () => dispatch(authRevokeGaFetchStart({}));

	const handleRevokeGaAuth = useCallback(() => {
		revokeGaAuthFetch();
	}, []);

	const handleWarningDialogOpen = useCallback(() => {
		setWarningDialogOpenState(true);
	}, []);

	const handleWarningDialogClose = useCallback(() => {
		setWarningDialogOpenState(false);
	}, []);

	return (
		<>
			<AlertDialog
				open={warningDialogOpenState}
				title={`☠️ ${t('Attention!')}`}
				description={t('The Google Analytics authorization will be removed')}
				agreeHandler={handleRevokeGaAuth}
				closeHandler={handleWarningDialogClose}
			/>
			<Button
				variant="outlined"
				color="secondary"
				onClick={handleWarningDialogOpen}
			>
				<Avatar src="/resources/GaLogo.png" />
				{t('Delete GA auth')}
			</Button>
		</>
	);
};

export default GaAuth;
