import React, { Component, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Tables  */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

/** Other components */
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

/** Icons  */
import InfoSharpIcon from '@material-ui/icons/InfoSharp';

/** Custom component */
import SaveButton from 'components/common/SaveButton';

/** Actions */
import { trackIdFetchStart } from 'actions/trackIdActions';
import { dealsUserFieldsFetchStart } from 'actions/dealsActions';

/** Helpers */
import { Settings } from 'models/settings';
import { checkFetching } from 'helpers/utils';
import { useTranslationWithParse } from 'helpers/translation';
import cookie from 'helpers/cookie';

const CrmDetailTab = () => {
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const fetchTrackId = payload => dispatch(trackIdFetchStart(payload));
	const fetchDealsUserFields = () => dispatch(dealsUserFieldsFetchStart());

	const [trackIdState, setTrackIdState] = useState('');

	const trackIdStore = useSelector(state => state.track_id);

	useEffect(() => {
		fetchDealsUserFields();
	}, []);

	const trackIdEdit = event => {
		console.log(event.target.value);
		setTrackIdState(event.target.value);
	};

	const checkTrackId = useCallback(() => {
		fetchTrackId({ track_id: trackIdState, entity_id: cookie.crmEntityId() });
	});

	console.log('render CrmDetailTab');

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h5">
						TRACK ID
					</Typography>
					<div className="blockquote">
						<p>
							<InfoSharpIcon fontSize="inherit" />
							{t('Track id description')}
						</p>
					</div>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="track id"
						value={trackIdState}
						onChange={trackIdEdit.bind(this)}
						margin="normal"
						variant="outlined"
						disabled={false}
					/>
					<br />
					<SaveButton
						handler={checkTrackId}
						disabled={!trackIdState}
						isFetching={trackIdStore.fetching}
						label={'проверить'}
					/>
				</Grid>
			</Grid>
			{/*
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            Управление UTM метками
          </Typography>
          <div className="blockquote">
            <p>
              <InfoSharpIcon fontSize="inherit" />
              При нажатии на название источника, выбранные utm метки будут
              записаны в карточку
            </p>
          </div>
        </Grid>
        
        <Grid item xs={12}>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} stickyHeader>
              <TableHead></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <SaveButton
                      handler={() => true}
                      disabled={true}
                      isFetching={false}
                      label={"facebook"}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="utm_source"
                      value={"test"}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="utm_medium"
                      value={"test"}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="track_id"
                      value={"test"}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="track_id"
                      value={"test"}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="track_id"
                      value={"test"}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color={"default"}
                      size={"small"}
                      onClick={() => true}
                      disabled={false}
                    >
                      <DeleteSweepIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            Добавление новой группы меток
          </Typography>
          <div className="blockquote">
            <p>
              <InfoSharpIcon fontSize="inherit" />
              При нажатии на название источника, выбранные utm метки будут
              записаны в карточку
            </p>
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      */}
		</Grid>
	);
};

export default CrmDetailTab;
