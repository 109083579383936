import { itemGet, itemAdd, itemUpdate } from './entity.item';
import { dealUserFieldsList } from './deals';
import { leadUserFieldsList } from './leads';

import { initialState as defaultSettings } from 'reducers/settingsReducer';

import {
	SettingsNotFoundError,
	SettingsError,
	SettingsNotInitializedError,
} from 'error/settingsError';

export const settingsGet = async () => {
	const result = await itemGet({
		ENTITY: 'B242GA',
		FILTER: { NAME: 'SETTINGS' },
	});

	if (
		'0' in result.data &&
		'DETAIL_TEXT' in result.data[0] &&
		result.data[0]['DETAIL_TEXT'].trim().length > 0
	) {
		try {
			return JSON.parse(result.data[0]['DETAIL_TEXT']);
		} catch (error) {
			throw new SettingsError(error);
		}
	}

	if (
		'0' in result.data &&
		'DETAIL_TEXT' in result.data[0] &&
		result.data[0]['DETAIL_TEXT'].trim().length === 0
	)
		throw new SettingsNotInitializedError();

	throw new SettingsNotFoundError();
};

export const settingsInit = async () => {
	/** Get default value for GA_CID fields */
	const dealUserFieldsResult = await dealUserFieldsList();
	const leadUserFieldsResult = await leadUserFieldsList();

	const { ID: gaCidFieldDeal = null } = dealUserFieldsResult.data.find(
		field => field.XML_ID == process.env.REACT_APP_GA_CID_FILED_NAME,
	);

	const { ID: gaCidFieldLead = null } = leadUserFieldsResult.data.find(
		field => field.XML_ID == process.env.REACT_APP_GA_CID_FILED_NAME,
	);

	if (
		'deals_ga_cid_field' in defaultSettings &&
		!defaultSettings.deals_ga_cid_field
	)
		defaultSettings.deals_ga_cid_field = gaCidFieldDeal;

	if (
		'leads_ga_cid_field' in defaultSettings &&
		!defaultSettings.leads_ga_cid_field
	)
		defaultSettings.leads_ga_cid_field = gaCidFieldLead;

	const result = await itemGet({
		ENTITY: 'B242GA',
		FILTER: { NAME: 'SETTINGS' },
	});

	if (
		'0' in result.data &&
		'DETAIL_TEXT' in result.data[0] &&
		result.data[0]['DETAIL_TEXT'].trim().length === 0
	) {
		return await itemUpdate({
			ENTITY: 'B242GA',
			ID: result.data[0]['ID'],
			DETAIL_TEXT: JSON.stringify(defaultSettings),
		});
	}

	return await itemAdd({
		ENTITY: 'B242GA',
		NAME: 'SETTINGS',
		CODE: 'SETTINGS',
		DETAIL_TEXT: JSON.stringify(defaultSettings),
	});
};

export const settingsUpdate = async payload => {
	const result = await itemGet({
		ENTITY: 'B242GA',
		FILTER: { NAME: 'SETTINGS' },
	});

	if (!('0' in result.data) || !('DETAIL_TEXT' in result.data[0]))
		throw new SettingsNotFoundError();

	return await itemUpdate({
		ENTITY: 'B242GA',
		ID: result.data[0]['ID'],
		DETAIL_TEXT: JSON.stringify(payload),
	});
};
