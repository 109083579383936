import {
  takeEvery,
  call,
  put,
  take,
  fork,
  cancel,
  select,
  cancelled,
  delay
} from "redux-saga/effects";

import { sourcesList } from "api/sources";

import {
  sourcesFetchSuccess,
  sourcesFetchError,
  SOURCES_FETCH_START,
} from "actions/sourcesActions";

export function* sourcesListFlow() {
  while (true) {
    yield take(SOURCES_FETCH_START);

    try {
      const result = yield call(sourcesList);


      yield put(sourcesFetchSuccess(result.data));
    } catch (error) {
      yield put(sourcesFetchError(error));
    }
  }
}
