import React, {
	Component,
	useState,
	forwardRef,
	useEffect,
	useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import PanToolIcon from '@material-ui/icons/PanTool';
import DeleteIcon from '@material-ui/icons/Delete';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';

/** Other components */
import Drawer from '@material-ui/core/Drawer';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Backdrop from '@material-ui/core/Backdrop';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';

/** Sub components */

/** Actions */

/** Helpers */
import CONFIG from 'helpers/const';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		height: '70px',
		width: '100%',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const SourcesList = ({
	SettingsModel,
	setLoadingState,
	loadingState,
	isFetching,
}) => {
	const classes = useStyles();

	const addCost = useCallback(
		(source, sourceData) => {
			SettingsModel.costs = {
				id: null,
				source,
				sourceData,
				accountId: null,
				accountName: null,
				webPropertyId: null,
				customDataSourceId: null,
				customDataSourceName: null,
			};

			setLoadingState({
				...loadingState,
				sourceList: { [source]: true },
			});

			SettingsModel.saveSettings();
		},
		[SettingsModel],
	);

	return (
		<>
			{isFetching('sourceList', CONFIG.COST_SOURCE_FACEBOOK) ||
				isFetching('sourceList', CONFIG.COST_SOURCE_VKONTAKTE) ||
				isFetching('sourceList', CONFIG.COST_SOURCE_YANDEX) ? (
				<Backdrop className={classes.backdrop} open={true}>
					<CircularProgressStyled size={50} />
				</Backdrop>
			) : null}
			<Grid container spacing={2}>
				{/*
				<Grid item xs={4}>
					<Button
						onClick={addCost.bind(null, CONFIG.COST_SOURCE_FACEBOOK, {
							accountId: '',
							accountName: '',
						})}
						variant="outlined"
						className={classes.button}
						color={'primary'}
					>
						Facebook\Instagram
					</Button>
				</Grid>
				*/}
				<Grid item xs={6}>
					<Button
						onClick={addCost.bind(null, CONFIG.COST_SOURCE_VKONTAKTE, {
							accountId: '',
							accountName: '',
						})}
						variant="outlined"
						className={classes.button}
						color={'primary'}
					>
						<Avatar src="/resources/VkLogo.png" />
					</Button>
				</Grid>

				<Grid item xs={6}>
					<Button
						onClick={addCost.bind(null, CONFIG.COST_SOURCE_YANDEX, {
							accountId: '',
							accountName: '',
						})}
						variant="outlined"
						className={classes.button}
						color={'primary'}
					>
						<Avatar src="/resources/YdLogo.png" />
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default SourcesList;
