import { RestApiError } from "error/restError";

import axios from "axios";
import qs from "qs";
import cookie from "helpers/cookie";

export function get(data) {
  const params = {track_id: data.track_id};

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_B242GA_API}/track_id`,
    data: qs.stringify({params}),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `bearer ${cookie.token()}`
    }
  }).then(response => {
    return response.data;
  }, error => {
    throw new RestApiError(error);
  });
}
