import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';

const TableStyled = withStyles(theme => ({
	root: {
		tableLayout: 'fixed',
	},
}))(Table);

export default TableStyled;
