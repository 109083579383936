import React from 'react';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

const Main = props => {
	const history = useHistory();
	const { t } = useTranslationWithParse();
	const handleClick = link => history.push(link);
	const currPath = props.children.props.location.pathname;

	const menuItems = [
		{
			path: '/deals',
			label: `✍️ ${t('M.DEALS')}`,
			selected: currPath == '/deals' ? true : false,
		},
		{
			path: '/leads',
			label: `🤙 ${t('M.LEADS')}`,
			selected: currPath == '/leads' ? true : false,
		},
		{
			path: '/settings',
			label: `⚙️ ${t('M.SETTINGS')}`,
			selected: currPath == '/settings' ? true : false,
		},
		/*
		{
			path: '/generator',
			label: `☀️ ${t('M.GENERATOR')}`,
			selected: currPath == '/generator' ? true : false,
		},
		*/
		{
			path: '/logs',
			label: `🧻 ${t('M.LOGS')}`,
			selected: currPath == '/logs' ? true : false,
		},
		/*
		{
			path: '/costs',
			label: `💳 ${t('M.COSTS')}`,
			selected: currPath == '/costs' ? true : false,
		},
		*/
		{
			path: '/call-tracking',
			label: `☎️ ${t('M.CALL-T')}`,
			selected: currPath == '/call-tracking' ? true : false,
		},
		{
			path: '/email-tracking',
			label: `📨 ${t('M.EMAIL-T')}`,
			selected: currPath == '/email-tracking' ? true : false,
		},
	];

	const printMenu = () => {
		let selectedKey = 0;

		menuItems.forEach(
			(item, i) => (selectedKey = item.selected ? i : selectedKey),
		);

		return (
			<Tabs value={selectedKey} style={{ width: '100%' }}>
				{menuItems.map((item, i) => (
					<>
						<Tab
							label={item.label}
							key={i}
							onClick={handleClick.bind(null, item.path)}
						/>
					</>
				))}
			</Tabs>
		);
	};

	return (
		<Container
			style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '100%' }}
		>
			<AppBar position="fixed" color="default">
				{printMenu()}
			</AppBar>
			<br />
			<br />
			<br />
			<div style={{ padding: 24, background: '#ffffff' }}>{props.children}</div>
			<br />
			<br />
			<br />
			{/*
			<AppBar position="fixed" style={{ bottom: 0, top: 'auto' }}>
				@copyright 20202
			</AppBar>
			*/}
		</Container>
	);
};

export default Main;
