import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

import parse from 'html-react-parser';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Custom components */
import { GoalDragLayer } from 'components/common/CustomDragLayer';

/** Sub components */
import Category from 'components/pages/Crm/Category';
import CustomDimensions from 'components/pages/Crm/CustomDimensions';
import GaCid from 'components/pages/Crm/GaCid';
import GaUid from 'components/pages/Crm/GaUid';
import GaCounter from 'components/pages/Crm/GaCounter';

/** Actions */
import {
	leadsCategoryFetchStart,
	leadsUserFieldsFetchStart,
	leadsCategoryStageFetchStart,
} from 'actions/leadsActions';
import { settingsUpdateStart } from 'actions/settingsActions';

/** API */
import { getAccountSummaries } from 'api/ga';

/** Helpers */
import { Settings } from 'models/settings';
import { checkFetching } from 'helpers/utils';
import { useTranslationWithParse } from 'helpers/translation';

const Leads = () => {
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const fetchLeadsCategory = () => dispatch(leadsCategoryFetchStart());
	const fetchLeadsUserFields = () => dispatch(leadsUserFieldsFetchStart());
	const fetchLeadsCategoryStage = payload =>
		dispatch(leadsCategoryStageFetchStart(payload));

	useEffect(() => {
		fetchLeadsCategory();
		fetchLeadsUserFields();
	}, []);

	/** IMMER ?? */
	const settingsList = useSelector(state => state.settings);
	const userFiledsList = useSelector(state => {
		return state.leads_user_fields;
	});
	const categoryList = useSelector(state => state.leads_category);
	const stageList = useSelector(state => state.leads_category_stage);

	/** !!!!!!!!!!!! https://kentcdodds.com/blog/prop-drilling  */
	const SettingsModel = new Settings(settingsList, {
		update: payload => dispatch(settingsUpdateStart(payload)),
	});

	const userFieldsListInUse = useMemo(() => [
		SettingsModel.leadsGaCidField,
		SettingsModel.leadsGaUidField,
		SettingsModel.leadsGaCounterField,
	]);

	const defaultLoadingState = {
		leadsCd: {},
		leadsCdBtn: false,
		leadsGaCidField: false,
		leadsGaUidField: false,
		leadsGaCounterField: false,
		leadsStages: {},
		leadsCategories: {},

		categoryGaAccount: {},
		categoryWebProperty: {},
		categoryProfile: {},
		categoryGoals: {},
	};

	const [loadingState, setLoadingState] = useState(defaultLoadingState);

	useEffect(() => {
		if (!settingsList.fetching) {
			setLoadingState(defaultLoadingState);
		}
	}, [settingsList.fetching]);

	const isFetching = checkFetching(loadingState);

	console.log('Leads Render', stageList);

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<DndProvider
					backend={TouchBackend}
					options={{ enableMouseEvents: true }}
				>
					<GoalDragLayer />
					<Category
						SettingsModel={SettingsModel}
						categoryList={categoryList}
						stageList={stageList}
						fetchCategoryStage={fetchLeadsCategoryStage}
						settingKeyCategories={'leadsCategories'}
						settingKeyStages={'leadsStages'}
						settingsKeyCounter={'leadsGaCounterField'}
						label={t('Leads Event Settings')}
						description={t(
							'Select the direction (funnel) of leads for setting Google Analytics goals',
						)}
						userFiledsList={userFiledsList}
						isFetching={isFetching}
						loadingState={loadingState}
						setLoadingState={setLoadingState}
					/>
				</DndProvider>
			</Grid>
			<Grid item xs={6}>
				<GaCid
					SettingsModel={SettingsModel}
					settingKey={'leadsGaCidField'}
					label={t('Client Id field')}
					description={t('Client Id field description')}
					userFiledsList={userFiledsList}
					isFetching={isFetching}
					loadingState={loadingState}
					setLoadingState={setLoadingState}
					userFieldsListInUse={userFieldsListInUse}
				/>
			</Grid>
			{/**
			<Grid item xs={6}>
				<GaCounter
					SettingsModel={SettingsModel}
					settingKey={'leadsGaCounterField'}
					label={t('Tid field')}
					description={t('Tid field description')}
					userFiledsList={userFiledsList}
					isFetching={isFetching}
					loadingState={loadingState}
					setLoadingState={setLoadingState}
					userFieldsListInUse={userFieldsListInUse}
				/>
			</Grid>
			<Grid item xs={6}>
				<GaUid
					SettingsModel={SettingsModel}
					settingKey={'leadsGaUidField'}
					label={t('User Id field')}
					description={t('User Id function description')}
					userFiledsList={userFiledsList}
					isFetching={isFetching}
					loadingState={loadingState}
					setLoadingState={setLoadingState}
					userFieldsListInUse={userFieldsListInUse}
				/>
			</Grid>
			<Grid item xs={6}>
				<CustomDimensions
					SettingsModel={SettingsModel}
					settingKey={'leadsCd'}
					deleteHandler={'leadsCdDelete'}
					label={t('Custom dimension field')}
					description={t('Custom dimension field desctiption')}
					userFiledsList={userFiledsList}
					isFetching={isFetching}
					loadingState={loadingState}
					setLoadingState={setLoadingState}
				/>
			</Grid>
			 */}
		</Grid>
	);
};

export default Leads;
