import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export const useTranslationWithParse = (options = []) => {
	const { t } = useTranslation(options);

	return {
		t: function () {
			return parse(t(...arguments));
		},
	};
};
