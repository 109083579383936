import React, {
	Component,
	useState,
	forwardRef,
	useEffect,
	useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';

import { Settings } from 'models/settings';

import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import SourceItem from './SourceItem';

/** Actions */
import { settingsUpdateStart } from 'actions/settingsActions';
import { sourcesFetchStart } from 'actions/sourcesActions';

/** Helpers */
import { fitWindow } from 'helpers/utils';
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

const useStyles = makeStyles(theme => ({}));

const Generator = () => {
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const dispatch = useDispatch();

	const settingsList = useSelector(state => state.settings);

	const SettingsModel = new Settings(settingsList, {
		update: payload => dispatch(settingsUpdateStart(payload)),
	});

	const [cidGeneratorState, setCidGeneratorState] = useState(null);

	const toggleCidGenerator = () => {
		const state =
			cidGeneratorState === null
				? !SettingsModel.cidGenerator
				: !cidGeneratorState;
		setCidGeneratorState(state);
		SettingsModel.cidGenerator = state;
		SettingsModel.saveSettings();
	};

	const noSourceSource = {
		NAME: 'B242GA_NO_SOURCE',
		SORT: 900,
		STATUS_ID: 'B242GA_NO_SOURCE',
	};

	const fetchSourcesList = () => dispatch(sourcesFetchStart());

	const [loadingState, setLoadingState] = useState({
		sources: {},
	});

	useEffect(() => {
		fetchSourcesList();
	}, []);

	const sourcesList = useSelector(state => {
		let sources = JSON.parse(JSON.stringify(state.sources));

		sources.items.push(CONFIG.NO_SOURCE_SOURCE);

		sources.items.forEach((source, i) => {
			sources.items[i] = {
				...sources.items[i],
				...SettingsModel.sources.find(
					setting => setting.STATUS_ID == source.STATUS_ID,
				),
			};
		});

		return sources;
	});

	const isFetching = useCallback(
		(key, id) => {
			let isFetching = false;

			if (key in loadingState && typeof loadingState[key] === 'object') {
				isFetching = id in loadingState[key] ? loadingState[key][id] : false;
			}

			if (
				key in loadingState &&
				typeof loadingState[key] === 'object' &&
				id === false
			) {
				isFetching = Object.keys(loadingState[key]).some(
					id => !!loadingState[key][id],
				);
			}

			if (key in loadingState && typeof loadingState[key] !== 'object')
				isFetching = loadingState[key];

			return isFetching;
		},
		[settingsList, loadingState],
	);

	useEffect(() => {
		if (!settingsList.fetching) {
			Object.keys(loadingState).forEach(settingKey => {
				if (typeof loadingState[settingKey] !== 'object') {
					loadingState[settingKey] = false;
				} else {
					Object.keys(loadingState[settingKey]).forEach(settingId => {
						loadingState[settingKey][settingId] = false;
					});
				}
			});

			setLoadingState(loadingState);
		}
	}, [settingsList]);

	/*
	useEffect(() => {
		setTimeout(() => {
			fitWindow();
		}, 1000);
	}, [settingsList]);
	*/
	console.log('Generator render');

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{' '}
				<Typography variant="h5" component="h5">
					{t('Client Id/UTM generator')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{t('turn off')}
				<Switch
					inputProps={{ 'aria-label': 'primary checkbox' }}
					checked={
						cidGeneratorState !== null
							? cidGeneratorState
							: SettingsModel.cidGenerator
					}
					onChange={toggleCidGenerator}
				/>
				{t('turn on')}
			</Grid>
			<Grid item xs={3}>
				<div className="blockquote-warning">
					<p>ℹ️ {t('Generator description')}</p>
				</div>
				<div className="blockquote-warning">
					<p>⚡{t('Generator caution')}</p>
				</div>
			</Grid>

			<Grid item xs={9}>
				<Table>
					<TableBody>
						{sourcesList.items.map((source, i) => (
							<SourceItem
								key={i}
								source={source}
								SettingsModel={SettingsModel}
								isFetching={isFetching}
								setLoadingState={setLoadingState}
								loadingState={loadingState}
							/>
						))}
					</TableBody>
				</Table>
			</Grid>
		</Grid>
	);
};

export default Generator;
