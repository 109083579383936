import {
  NOTIFICATION_SHOW,
  NOTIFICATION_HIDE
} from "actions/notificationsActions";

export const initialState = {
  type: "",
  show: false,
  message: ""
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case NOTIFICATION_SHOW:
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
        show: true
      };

    case NOTIFICATION_HIDE:
      return {
        ...state,
        type: initialState.type,
        message: initialState.message,
        show: false
      };

    default:
      return state;
  }
};
