export const VK_ADS_ACCOUNTS_FETCH_START = 'VK_ADS_ACCOUNTS_FETCH_START';
export const VK_ADS_ACCOUNTS_FETCH_SUCCESS = 'VK_ADS_ACCOUNTS_FETCH_SUCCESS';
export const VK_ADS_ACCOUNTS_FETCH_ERROR = 'VK_ADS_ACCOUNTS_FETCH_ERROR';

export const vkAdsAccountsFetchStart = payload => {
	return {
		type: VK_ADS_ACCOUNTS_FETCH_START,
		payload,
	};
};

export const vkAdsAccountsFetchSuccess = payload => {
	return {
		type: VK_ADS_ACCOUNTS_FETCH_SUCCESS,
		payload,
	};
};

export const vkAdsAccountsFetchError = payload => {
	return {
		type: VK_ADS_ACCOUNTS_FETCH_ERROR,
		payload,
	};
};
