import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import Main from './components/layouts/Main';
import Placement from './components/layouts/Placement';

import Deals from './components/pages/Crm/Deals/';
import Leads from './components/pages/Crm/Leads';
import GeneralSettings from './components/pages/GeneralSettings/';
import Generator from './components/pages/Generator/';
import Logs from './components/pages/Logs/';
import Costs from './components/pages/Costs';
import EmailTracking from './components/pages/EmailTracking';
import CallTracking from './components/pages/CallTracking';
import CrmDetailTab from './components/pages/CrmDetailTab';
import Notification from './components/common/Notification';
import GaAuth from './components/auth/GaAuth';

import store from './stores/store';

import { settingsGetStart } from './actions/settingsActions';
import { authCheckGaFetchStart } from './actions/authActions';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<Route
		{...rest}
		render={props => (
			<Layout>
				<Component {...props} />
			</Layout>
		)}
	/>
);

const AppWrapped = () => {
	const dispatch = useDispatch();

	const fetchSettings = () => dispatch(settingsGetStart({}));
	const fetchAuthCheck = () => dispatch(authCheckGaFetchStart({}));

	const authList = useSelector(state => state.auth);

	useEffect(() => {
		fetchAuthCheck();
	}, []);

	useEffect(() => {
		fetchSettings();
	}, []);

	if (authList.ga === false && authList.fetching === false) return <GaAuth />;

	return (
		<BrowserRouter>
			<Switch>
				<AppRoute exact path="/" component={Deals} layout={Main} />
				<AppRoute exact path="/deals" component={Deals} layout={Main} />
				<AppRoute exact path="/leads" component={Leads} layout={Main} />
				<AppRoute
					exact
					path="/settings"
					component={GeneralSettings}
					layout={Main}
				/>
				<AppRoute exact path="/generator" component={Generator} layout={Main} />
				<AppRoute exact path="/logs" component={Logs} layout={Main} />
				<AppRoute exact path="/costs" component={Costs} layout={Main} />
				<AppRoute
					exact
					path="/email-tracking"
					component={EmailTracking}
					layout={Main}
				/>
				<AppRoute
					exact
					path="/call-tracking"
					component={CallTracking}
					layout={Main}
				/>
				<AppRoute
					path="/placement"
					component={CrmDetailTab}
					layout={Placement}
				/>
				<AppRoute component={Deals} layout={Main} key="default" />
			</Switch>
		</BrowserRouter>
	);
};

const App = () => {
	return (
		<Provider store={store}>
			<Notification open={true} />
			<AppWrapped />
		</Provider>
	);
};

export default App;
