import {
  GA_ACCOUNT_SUMMARIES_FETCH_START,
  GA_ACCOUNT_SUMMARIES_FETCH_SUCCESS,
  GA_ACCOUNT_SUMMARIES_FETCH_ERROR
} from "actions/gaActions";

export const initialState = {
  username: "",
  items: []
};

export default (state = { ...initialState, fetching: false }, action) => {
  switch (action.type) {
    case GA_ACCOUNT_SUMMARIES_FETCH_START:
      return {
        ...state,
        fetching: true
      };

    case GA_ACCOUNT_SUMMARIES_FETCH_SUCCESS:
      return {
        ...state,
        username: action.payload.username,
        items: action.payload.items,
        fetching: false
      };

    case GA_ACCOUNT_SUMMARIES_FETCH_ERROR:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
};
