import {
  TRACK_ID_FETCH_START,
  TRACK_ID_FETCH_SUCCESS,
  TRACK_ID_FETCH_ERROR,
  TRACK_ID_NOT_FOUND
} from "actions/trackIdActions";

export const initialState = {
  data: {}
};

export default (state = { ...initialState, fetching: false }, action) => {
  switch (action.type) {
    case TRACK_ID_FETCH_START:
      return {
        ...state,
        fetching: true
      };

    case TRACK_ID_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false
      };

    case TRACK_ID_FETCH_ERROR:
      return {
        ...state,
        data: initialState.data,
        fetching: false
      };
    case TRACK_ID_NOT_FOUND:
      return {
        ...state,
        data: initialState.data,
        fetching: false
      };
    default:
      return state;
  }
};
