import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';

/** Other components */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';

/** Sub components */

/** Actions */
import { fbAdsAccountsFetchStart } from 'actions/fbActions';

/** Helpers */
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const FacebookForm = ({
	cost,
	isFetching,
	SettingsModel,
	loadingState,
	setLoadingState,
	setSourceStepsCompletedState,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const [activeStep, setActiveStep] = useState(0);
	const [stepEditState, setStepEditState] = useState(false);
	const [fbAdsAccountIdState, setFbAdsAccountIdState] = useState(false);

	const fbAdsAccountIdSettings = useMemo(
		() => cost.sourceData.accountId || null,
	);

	const fetchAdsAccounts = () => dispatch(fbAdsAccountsFetchStart({}));

	useEffect(() => {
		fetchAdsAccounts();
	}, []);

	const fbAdsAccountsIsFetching = useSelector(state => {
		return !!state.fb_ads_accounts.fetching;
	});

	const fbAdsAccountList = useSelector(state => state.fb_ads_accounts.items);

	const fbAccountIdSave = () => {
		const { name: fbAdsAccountName = '' } = fbAdsAccountList.find(
			account => account.id === fbAdsAccountIdState,
		);

		SettingsModel.costs = {
			id: cost.id,
			source: CONFIG.COST_SOURCE_FACEBOOK,
			sourceData: {
				accountId: fbAdsAccountIdState,
				accountName: fbAdsAccountName,
			},
		};

		setStepEditState(false);

		setLoadingState({
			...loadingState,
			costs: { [cost.id]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(false);
	};

	const backToFbAdsAccount = () => {
		setStepEditState(0);
	};

	const fbAdsAccountIdEdit = useCallback(event => {
		setFbAdsAccountIdState(event.target.value);
	});

	useEffect(() => {
		if (fbAdsAccountIdSettings && stepEditState !== 0 && !isFetching) {
			setActiveStep(1);
		}

		if (stepEditState === 0) setActiveStep(0);
	});

	useEffect(() => {
		setSourceStepsCompletedState(!!fbAdsAccountIdSettings);
	}, [fbAdsAccountIdSettings]);

	const fbAdsAccountValue = useMemo(() => {
		return fbAdsAccountIdState !== false
			? fbAdsAccountIdState
			: fbAdsAccountIdSettings
				? fbAdsAccountIdSettings
				: 'default';
	}, [fbAdsAccountIdState, fbAdsAccountIdSettings]);

	const fbAccountIdSaveDisabled = useMemo(() => {
		return (
			!fbAdsAccountIdState ||
			fbAdsAccountIdState == fbAdsAccountIdSettings ||
			fbAdsAccountIdState == 'default' ||
			isFetching
		);
	}, [fbAdsAccountIdState, fbAdsAccountIdSettings, isFetching]);

	return (
		<Stepper activeStep={activeStep} orientation="vertical">
			<Step key={0}>
				{fbAdsAccountIdSettings &&
					fbAdsAccountList.some(
						account => account.id == fbAdsAccountIdSettings,
					) ? (
					<StepLabel>
						{
							fbAdsAccountList.find(
								account => account.id == fbAdsAccountIdSettings,
							)['name']
						}
						{fbAdsAccountIdSettings ? (
							<Button onClick={backToFbAdsAccount}>
								<EditIcon fontSize="small" />
							</Button>
						) : null}
					</StepLabel>
				) : (
					<StepLabel>Facebook Ads account</StepLabel>
				)}
				<StepContent>
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">
							ads account
						</InputLabel>
						<Select
							label="ads account"
							style={{ width: 450 }}
							onChange={fbAdsAccountIdEdit}
							inputProps={{ 'aria-label': 'Without label' }}
							value={fbAdsAccountValue}
						>
							<MenuItem value="default">-</MenuItem>
							{fbAdsAccountList.map((account, i) => (
								<MenuItem value={account.id} key={account.id}>
									{`${account.name}`}
								</MenuItem>
							))}
							{fbAdsAccountsIsFetching ? (
								<CircularProgressStyled size={24} />
							) : null}
						</Select>
					</FormControl>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={fbAccountIdSave}
								className={classes.button}
								disabled={fbAccountIdSaveDisabled}
							>
								{t('Save')}
								<SaveIcon />
								{isFetching ? <CircularProgressStyled size={24} /> : null}
							</Button>
						</div>
					</div>
				</StepContent>
			</Step>
		</Stepper>
	);
};

export default FacebookForm;
