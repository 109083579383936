const ru = {
	translation: {
		/** Settings and descriptions */
		'Deals Event Settings': 'Настройка событий для сделок',
		'Select the direction (funnel) of deals for setting Google Analytics goals':
			'Выберите направление (воронку) сделок для управления целями Google Analytics.',

		'Leads Event Settings': 'Настройка событий для лидов',
		'Select the direction (funnel) of leads for setting Google Analytics goals':
			'Выберите направление (воронку) лидов для управления целями Google Analytics',

		'Client Id field': 'Поле хранения идентификатора GA CID',
		'Client Id field description': `Поле, в котором будет храниться <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters?hl=ru#cid">Client ID Google Analytics!</a> ⚡ Должно быть заполнено! ⚡`,

		'User Id field': 'Поле хранения идентификатора User Id',
		'User Id function description': `О функции User-ID <a target="_blank" href="https://support.google.com/analytics/answer/3123662?hl=ru">подробнее</a>`,

		'Tid field': 'Поле хранения кода счетчика',
		'Tid field description':
			'Обратите внимание, если в этом параметере выбрано поле, то код счетчика из общих настроек и настроек для каждого направления будуд игнорироваться. Оставьте это поле пустым, если точно не знаете зачем это использовать 🤪',

		'Custom dimension field': 'Специальные параметры для Сделок',
		'Custom dimension field desctiption': `Для передачи пользовательских свойств в measurement protocol установите соотвествие специальному параметру в Google Analytics 
		<a href="https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters?hl=ru#cd_" target=_blank>
			подробнее
		</a>
			`,

		/** GaCid */
		'Required!': '<b>ОБЯЗАТЕЛЬНО ДЛЯ ЗАПОЛНЕНИЯ!</b>',

		/** CustomDimensions */
		Field: 'Свойство',

		/** Category */
		'Category description': `Укажите в левой части аккаунт Google Analytics, ресурс и поток,
	события которого нужно привязать к стадии сделки. И
	просто перетащите нужное событие на нужную стадию в правой части.`,

		'Category GA counter substitution warning': `Обратите внимание! В общих настройках указано поле
			хранения кода счетчика - <u><b>{{ gaCounterUserFieldName }}</b></u>. Если оно
			будет иметь значение в карточке CRM, то текущее значение - <u><b>{{ gaCounterInUse}}</b></u>
			будет игнорироваться при отправке данных в GA`,

		'Expert mode description': `При активации режима параметры целей для стадий
		можно указывать вручную. <b>ВНИМАНИЕ!</b> В случае
		неправильно указанных параметров цель не будет
		фиксироваться в Google Analytics. Рекомендуется
		выбирать цели непосредственно из списка в левой
		части`,

		'Counter code': 'Код счетчика',
		'expert mode': 'эксперт режим',

		/** GaProfile */
		'All relation between goals and stages will be deleted':
			'Будут удалены все привязанные к стадиям цели. Продолжить?',
		'Google Analytics Account': 'Выбор аккаунта Google Analytics',
		'Select counter': 'Выбор ресурса',
		'Select property': 'Выбор ресурса',
		'Select view': 'Выбор представления',
		'Select stream': 'Выбор потока',
		'Select API key Measurement Protocol':
			'Секретный ключ API для Measurement Protocol',
		'Goals choise': 'Выбор целей',
		'Events choise': 'Выбор событий',
		'There is no goals in this view': 'Нет целей в этом VIEW',
		'There are no events in this property': 'Нет событий в этом ресурсе',
		'Need to reset settings to get started with GA4':
			'Необходимо сбросить настройки для начала работы с GA4',

		'Edit goals in Google Analytics account':
			'редактирование целей в Google Analytics',

		/** GeneralSettings */
		'Js code for placing on your website': 'Код для размещения на сайте',

		'Js code description': `Разместите этот код на всех страницах вашего сайта, тогда
		Google Client Id, необходимый для точного анализа данных, будет
		автоматически добвален в результат CRM форм. Также cid будет
		добавлен в лиды\сделки, созданные из всех каналов виджета
		(Битрикс24 чат, Whatsapp, Telegram и т.д). Код необходимо
		разместить для правильной работы функции 
		🔗 <a href="https://b242ga.ru/blog/track_id" target="_blank">
			track id
		</a> и email tracking-а`,

		'External auth': 'Внешнии авторизации',
		'Auth description': `Список внешних авторизаций для Google Analytics и рекламных
		систем-источников`,
		'GA counter by default': 'Код счетчика по умолчанию',

		'GA counter by default description': `Для поддержки нескольких сайтов-источников код счетчика может
		быть передан из разных полей, в зависимости от того, какое из
		них заполнено. <br /> <br />
		Код передается, если :<br />
		1) Есть значение в поле "код счетчика" сделки или лида
		<br />
		2) Есть значение в поле "код счетчика" в настройках направления
		И не заполнено поле из п.1
		<br />
		<br />
		При пустых значениях в п.1 и п.2, будет передано значение из
		поля ниже.`,
		'Track id title': 'Управление <b>TRACK ID</b> в карточке crm лида и сделки',
		'Track id description settings': `При включении опции в карточке лида и сделки появится вкладка
		B242GA. 🙄 Но
		<a target="_blank" href="https://b242ga.ru/blog/track_id">
			что это?
		</a>`,

		/** Auths */
		'The Google Analytics authorization will be removed': `Будет удалена авторизация Google Analytics. Все настройки приложения, все потоки выгрузки!!`,
		'Delete GA auth': `Удалить GA авторизацию`,
		'The Facebook authorization will be removed': `Будет удалена авторизация Facebook. Все потоки выгрузки, связанные с Facebook будут удалены!`,
		'Delete FB auth': `Удалить FB авторизацию`,
		'Delete VK auth': `Удалить VK авторизацию`,
		'The Vk authorization will be removed': `Будет удалена авторизация Vk. После нажатия кнопки "Да" все потоки выгрузки, связанные с Vk будут удалены! Vk API не поддерживает метод отзыва доступа, для удаления доступа B242GA к вашему аккаунту перейдите по ссылке <a href="https://vk.com/settings?act=apps" target="_blank">удалить доступ B242GA</a>`,
		'The Yandex authorization will be removed': `Будет удалена авторизация Yandex.После нажатия кнопки "Да" все потоки выгрузки, связанные с Yandex.Direct будут удалены! Yandex API не поддерживает метод отзыва доступа, для удаления доступа B242GA к вашему аккаунту перейдите страницу Управление аккаунтом -> Входы и устройства -> Устройства, сервисы и программы. <a href="https://passport.yandex.ru/profile" target="_blank">Управление аккаунтом на passport.yandex.ru</a>`,
		/** Costs*/
		'Costs settings title': 'Выгрузка расходов в Google Analytics',
		'Costs settings description': `Подробно о настройке, о том какие utm-метки ставить в объявлениях для корректного отображения данных в отчетах, как анализировать данные - по ссылке 🔗<a target="_blank" href="https://b242ga.ru/blog/costs">выгрузка расходов из рекламных кабинетов в Google Analytics</a><br />
			
`,
		'ADS sources': 'Управление потоками данных о расходах',

		/** CostSettings Form */
		'Create Data Set': ` <a href="{{ link }}" target =_blank > Create Data Set</a> <br /><br />
		Пожалуйста, обратите внимание, при создании новой схемы, поля, указанные ниже, обязательны:<br />
		<ul><li>ga:date</li><li>ga:medium</li><li>ga:source</li><li>ga:adClicks</li><li>ga:impressions</li><li>ga:adCost</li><li>ga:campaign</li><li>ga:keyword</li><li>ga:adContent</li></ul>
		`,
		'Create Data Set Example': ` <a href = "{{ link }}" target=_blank> Create Data Set Example</a> `,
		'Data set required fileds': `Пожалуйста, убедитесь, что выбранная схема содержит все указанные поля:<br />
		<ul><li>ga:date</li><li>ga:medium</li><li>ga:source</li><li>ga:adClicks</li><li>ga:impressions</li><li>ga:adCost</li><li>ga:campaign</li><li>ga:keyword</li><li>ga:adContent</li></ul>`,
		'Mapping Google Analytics API Fields':
			'Соответствие полей Google Analytics Schema ⬅️ <a target="_blank" href="{{link}}">{{source}}</a>',

		'Utm Settings Facebook':
			'Значения для utm меток в настройках объявления в Facebook',
		'Utm Settings Vk':
			'Значения для utm меток в настройках объявления в Vkontakte',
		'Utm Settings Yandex.Direct':
			'Значения для utm меток в настройках объявления в Yandex.Direct',

		'There is no Data set': `Для указанного счетчика нет Data Set.<br />
	Пожалуйста, создайте Data Set(ссылки ниже) <br /> и обновите данные этого шага настройки.`,

		'Data set has no view link': `Указанный Data Set не привязан ни к одному View, <br /> пожалуйста, сделайте это, это обязательно`,
		'Bind Data Set to the desired View': `Привязать Data Set к нужному View`,
		'Select GA account': `Выбор аккаунта Google Analytics`,
		'Google Analytics account': `Аккаунт Google Analytics`,
		'Select counter': `Выбор счетчика`,
		'GA counter': `Счетчик Google Analytics`,
		'Data set for costs upload': `Data Set Google Analytics для загрузки расходов`,
		'Everything is configured, data will be loaded within 24 hours.': `Все настроено, данные будут загружаться в течении суток.`,
		'Google Analytics. Check this out': `GOOGLE ANALYTICS. ОБЯЗАТЕЛЬНО УБЕДИТЕСЬ: `,
		'Import Behavior should be equal Owerwrite': `Import Behavior равен Owerwrite`,
		'Costs will be overwritten': `Расходы будут перезаписываться при каждом новом импорте`,
		'Mandatory Data Set parameters are specified': `Обязательные параметры Data Set указаны`,
		'Set utm tags': `Обязательно указывайте в схеме Data Set следующие поля < br /> <b> ga:date ga:medium ga:source ga:adClicks ga:adCost ga:impressions ga:campaign</b>`,
		'You can edit the current Data Set by following the link': `Отредактировать текущий Data Set можно по ссылке`,
		'Facebook ads. Check this out': `FACEBOOK ADS. ОБЯЗАТЕЛЬНО УБЕДИТЕСЬ`,

		'Use next params': `При создании рекламного объявления используйте как минимум < br />
	следующие параметры, иначе данные по расходам будут неточны: `,

		/** Generator */
		'Client Id/UTM generator': `Генератор Client Id / UTM`,

		'Generator description': `Подробнее о том зачем
	<a href = "https://b242ga.ru/blog/cid_generation" target= "_blank" >
		это
		</a > <br />
Также можно организовать с помощью этой функции
	<a href="https://b242ga.ru/blog/email_tracking" target="_blank" >
		статичный email tracking
		</a> -
	для отслеживания в GA обращений по email`,

		'Generator caution': `<b> ВНИМАНИЕ!</b > Крайне рекомендуется при использовании этой
функции ставить цель на стадию следующую за стадией по умолчанию, в
которой создается лид / сделка.В случае, если у вас CID и UTM метки
добавляются ПОСЛЕ создания лида / сделки, такой подход позволит
избежать отправки сгенерированных, а не реальных CID и UTM - генерация сработает только если у стадии есть привязанная цель в GA.
		<br/> <br />
Что будет происходить при включении:
		<br />
		<br />
		1️⃣  Если у лида / сделки не определен CID, он будет сгенерирован и
сохранен в CRM
	<br /> <br />
	2️⃣  Если у лида / сделки не определен UTM_SOURCE, но есть источник из
справочника Битрикс24, то будут установлены utm метки из
соответсивия по списку справа
	<br /> <br />
	3️⃣ Если у лида / сделки нет источника или для источника в списке не
установлены метки, автоматически будет установлены метки из
источника B242GA_NO_SOURCE(см.последний пункт в списке ниже).`,

		/** Logs */
		ERROR_REDIS: 'Внутренняя ошибка сервиса. ERROR_REDIS',
		ERROR_MP_PARAMS: 'ERROR_MP_PARAMS',
		WARNING_MP_PARAMS_NO_CLA:
			'У стадии нет привязанной цели, данные не переданы в GA. WARNING_MP_PARAMS_NO_CLA',
		ERROR_MP_PARAMS_CID_REQUIRED:
			'Требуется Google Analytics ClientId. ERROR_MP_PARAMS_CID_REQUIRED',
		ERROR_MP_PARAMS_TID_REQUIRED:
			'Требуется код счетчика. ERROR_MP_PARAMS_TID_REQUIRED',
		ERROR_MP_PARAMS_CID_WRONG_FORMAT: 'ERROR_MP_PARAMS_CID_WRONG_FORMAT',
		ERROR_MP_PARAMS_COST_REQUIRED: 'Ошибка отправки данных в Google Analytics. Требуется сумма сделки',
		ERROR_API_DB: 'Внутренняя ошибка сервиса. ERROR_API_DB',
		ERROR_B24_CALL: 'Внутренняя ошибка сервиса. ERROR_B24_CALL',
		ERROR_GET_SETTINGS: 'Внутренняя ошибка сервиса. ERROR_GET_SETTINGS',
		ERROR_SEND_TO_GA:
			'Ошибка отправки данных в Google Analytics. ERROR_SEND_TO_GA',
		ERROR_TRACKER_DATA: 'Внутренняя ошибка сервиса. ERROR_TRACKER_DATA',

		/** CrmDetailTabs */
		'Track id description': `Попросите звонящего нажать Esc и продиктовать вам номер.Введите
его в поле Track id.Как это работает
	<a href="https://b242ga.ru/blog/track_id" target="_blank">
		подробнее
		</a > `,

		/** Email tracking */
		'Add new email': 'Новая запись:',
		'List of emails': 'Список email-ов:',
		'Email tracking description': `<b>Как работает динамический email tracking</b>. <br />
			<p> ✔️ Разместите скрипт b242ga.js из раздела настроек на странице сайта</p>
			<p> ✔️ В блоке справа добавьте пару <b>имя класса контейнера</b> - <b>email</b>.<br />
			<b>Имя класса контейнера</b> - это значение для атрибута class HTML-тега, в который будет подставляться <b>email</b>.
			Например, если имя класса контейнера email-1, то на странице нужно разместить span class="email-1" или div class="email-1".
			В поле <b>email</b> справа ⚡ обязательно ⚡ укажите <b>#ID#</b> для динамической подстановки TRACK ID в адрес почтового ящика.
			Например, order+#ID#@b242ga.ru.<br /><br />В результате, на странице, где размещен скрипт b242ga.js и теги с классом  span class="email-1" или div class="email-1",
			будет показан <b>email</b> order+12345@b242ga.ru где 12345 - идентификатор TRACK ID, по которому приложение B242GA получит все необходимые данные о пользователе и добавит их в CRM </p>
			<p> ✔️ Обработка email с получателем order+12345@b242ga.ru может происходить по двум альтернативным вариантам. <br /><br /><b>1-ый</b>
			Ваш почтовый сервер должен уметь принимать почту на несуществующий адрес и пересылать его на почтовый ящик, который существует <br /><br /> <b>2-ой</b> Или ваш почтовый сервер должен поддерживать плюс-адресацию, чтобы, например, письмо на order+23123@b242ga.ru
			было направлен на ящик order@b242ga.ru. Многие сервисы поддерживают такой функционал. Например, GMAIL - дополнительно ничего настраивать не нужно</p>
			<p> ✔️ 🔗<a href="https://helpdesk.bitrix24.ru/open/8233895/" target=_blank>К Битрикс24 подключите существующий ящик</a> ,
			на который будет приходить письма, по первому или второму варианту, несуществующему адресату order+12345@b242ga.ru или, соответственно, в результате плюс-адресации на order@b242ga.ru</p>
			<p> ✔️ Лид или сделка, которые будут созданы по письму на настроенный адрес, будут содержать CID и UTM-метки, по которым на сайт пришел клиент - об этом позаботится 🧚🧚<b>B242GA</b>🧚🧚</p> `,
		'Class name warning epmty': 'введите имя класса',
		'Email track empty': 'введите email ​',
		'Email track need ID':
			'email дожен содержать #ID#, например, order+#ID#@b242ga.ru',

		/** Call tracking */
		'Add new phone': 'Новая запись:',
		'List of phones': 'Список телефонных номеров:',
		'Call tracking description': `<b>Как настроить динамический call tracking</b>. <br />
					<p> ✔️ Разместите скрипт b242ga.js из раздела "настройки" на странице сайта</p>
					<p> ✔️ 🔗<a href="https://helpdesk.bitrix24.ru/open/9079921" target=_blank>Подключите пакет телефонных номеров к вашему порталу</a>.
					Чем больше номеров в пакете, тем ниже будет погрешность в определении данных трэкинга
					<p> ✔️ В блоке справа добавьте список телефонных номеров из пакета. <br />
					<p> ✔️ На страницах сайта, на которых указан телефон для связи с вами, в class div-ов, в которых размещен телефонный номер, добавьте
					b242ga-call-tracker. Например, если телефон на странице размещается в теге div class="phone", то для динамической подстановки добавьте в класс
					 div class="phone <b>b242ga-call-tracker</b>".
					<p> ✔️ Лид или сделка, которые будут созданы по звонку на номер из списка справа, будут содержать CID и UTM-метки, по которым на сайт пришел клиент - об этом позаботится 🧚🧚<b>B242GA</b>🧚🧚</p> `,
		'Phone track empty': 'введите номер телефона ​',
		'Phone track error': 'введите номер в корректном формате​',

		/** Menu items */
		'M.DEALS': 'Сделки',
		'M.LEADS': 'Лиды',
		'M.SETTINGS': 'Настройки',
		'M.GENERATOR': 'Генератор CID',
		'M.LOGS': 'Логи',
		'M.COSTS': 'Расходы',
		'M.EMAIL-T': 'Email Tracking',
		'M.CALL-T': 'Call Tracking',

		/** Common */
		Save: 'Сохранить',
		Yes: 'Да',
		Cancel: 'Отмена',
		'Attention!': 'Внимание!',
		Login: `Авторизоваться`,
		'turn on': 'включить',
		'turn off': 'выключить',
	},
};

export default ru;
