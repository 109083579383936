import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';

/** Other components */
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

/** Helpers */
import CONFIG from 'helpers/const';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	box: props => ({
		opacity: props.isDragging ? 0.4 : 1,
	}),
	enabledGoal: { cursor: 'grab', margin: 5 },
	disabledGoal: { margin: 5 },
});

const Goal = ({ SettingsModel, goal, streamId, stageSave }) => {
	const goalId = `${goal.internalWebPropertyId}_${goal.id}`;
	const goalName = goal.name;

	let category = false;
	let action = false;
	let label = false;

	if (
		'eventDetails' in goal &&
		'eventConditions' in goal.eventDetails &&
		goal.eventDetails.eventConditions.length > 0
	) {
		const conditions = goal.eventDetails.eventConditions;

		if (conditions.find(event => event.type == 'CATEGORY'))
			category =
				conditions.find(event => event.type == 'CATEGORY').expression || false;

		if (conditions.find(event => event.type == 'ACTION'))
			action =
				conditions.find(event => event.type == 'ACTION').expression || false;

		if (conditions.find(event => event.type == 'LABEL'))
			label =
				conditions.find(event => event.type == 'LABEL').expression || false;
	}

	const isDisabled = stage => {
		return CONFIG.GA_GOAL_ID in stage && stage[CONFIG.GA_GOAL_ID] === goalId;
	};

	let disabled = SettingsModel['dealsStages'].some(isDisabled);
	if (!disabled) disabled = SettingsModel['leadsStages'].some(isDisabled);

	const item = { goal, type: CONFIG.GOAL_DRAG_TYPE };

	const [{ isDragging }, drag] = useDrag({
		item,
		end(item, monitor) {
			const dropResult = monitor.getDropResult();

			if (
				category &&
				dropResult &&
				'stage' in dropResult &&
				'STATUS_ID' in dropResult.stage &&
				dropResult.stage.STATUS_ID
			)
				stageSave({
					STATUS_ID: dropResult.stage.STATUS_ID,
					IS_CONVERSION: dropResult.stage.IS_CONVERSION,
					category,
					action,
					label,
					goalId,
					goalName,
					streamId,
				});
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const classes = useStyles({ isDragging });

	return (
		<>
			{category ? (
				<span ref={disabled || !category ? null : drag}>
					<Chip
						avatar={<Avatar src="/resources/GaLogo.png" />}
						label={`${goal.name}`}
						clickable
						color={goal.name === 'purchase' ? 'warning' : 'primary'}
						disabled={disabled}
						className={classes.enabledGoal}
					/>
				</span>
			) : (
				<Tooltip placement={'top'} title="У этой цели нет меток">
					<Chip
						avatar={<Avatar>{goal.name.slice(0, 1)}</Avatar>}
						label={`${goal.name}`}
						disabled={disabled}
						className={classes.disabledGoal}
					/>
				</Tooltip>
			)}
		</>
	);
};

export default Goal;
