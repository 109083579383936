export const LEADS_CATEGORY_FETCH_START = 'LEADS_CATEGORY_FETCH_START';
export const LEADS_CATEGORY_FETCH_SUCCESS = 'LEADS_CATEGORY_FETCH_SUCCESS';
export const LEADS_CATEGORY_FETCH_ERROR = 'LEADS_CATEGORY_FETCH_ERROR';

export const LEADS_CATEGORY_STAGE_FETCH_START = 'LEADS_CATEGORY_STAGE_FETCH_START';
export const LEADS_CATEGORY_STAGE_FETCH_SUCCESS = 'LEADS_CATEGORY_STAGE_FETCH_SUCCESS';
export const LEADS_CATEGORY_STAGE_FETCH_ERROR = 'LEADS_CATEGORY_STAGE_FETCH_ERROR';

export const LEADS_USER_FIELDS_FETCH_START= 'LEADS_USER_FIELDS_FETCH_START';
export const LEADS_USER_FIELDS_FETCH_SUCCESS = 'LEADS_USER_FIELDS_FETCH_SUCCESS';
export const LEADS_USER_FIELDS_FETCH_ERROR = 'LEADS_USER_FIELDS_FETCH_ERROR';

export const LEAD_CRM_FIELDS_UPDATE_START = 'LEAD_CRM_FIELDS_UPDATE_START';
export const LEAD_CRM_FIELDS_UPDATE_SUCCESS = 'LEAD_CRM_FIELDS_UPDATE_SUCCESS';
export const LEAD_CRM_FIELDS_UPDATE_ERROR = 'LEAD_CRM_FIELDS_UPDATE_ERROR';

/**
 * leads category Fetch
 */
export const leadsCategoryFetchStart = (payload) => {
    return {
        type: LEADS_CATEGORY_FETCH_START,
        payload
    }
}

export const leadsCategoryFetchSuccess = (payload) => {
    return {
        type: LEADS_CATEGORY_FETCH_SUCCESS,
        payload
    }
}

export const leadsCategoryFetchError = (payload) => {
    return {
        type: LEADS_CATEGORY_FETCH_ERROR,
        payload
    }
}

/**
 * leads category stage Fetch
 */

export const leadsCategoryStageFetchStart = (payload) => {
    return {
        type: LEADS_CATEGORY_STAGE_FETCH_START,
        payload
    }
}

export const leadsCategoryStageFetchSuccess = (payload) => {
    return {
        type: LEADS_CATEGORY_STAGE_FETCH_SUCCESS,
        payload
    }
}

export const leadsCategoryStageFetchError = (payload) => {
    return {
        type: LEADS_CATEGORY_STAGE_FETCH_ERROR,
        payload
    }
}


/**
 * leads category user fields
 */

export const leadsUserFieldsFetchStart = (payload) => {
    return {
        type: LEADS_USER_FIELDS_FETCH_START,
        payload
    }
}

export const leadsUserFieldsFetchSuccess = (payload) => {
    return {
        type: LEADS_USER_FIELDS_FETCH_SUCCESS,
        payload
    }
}

export const leadsUserFieldsFetchError = (payload) => {
    return {
        type: LEADS_USER_FIELDS_FETCH_ERROR,
        payload
    }
}

export const leadCrmFieldUpdateStart = (payload) => {
    return {
        type: LEAD_CRM_FIELDS_UPDATE_START,
        payload
    }
}

export const leadCrmFieldUpdateSuccess = (payload) => {
    return {
        type: LEAD_CRM_FIELDS_UPDATE_SUCCESS,
        payload
    }
}

export const leadCrmFieldUpdateError = (payload) => {
    return {
        type: LEAD_CRM_FIELDS_UPDATE_ERROR,
        payload
    }
}








