import React from 'react';

import parse from 'html-react-parser';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
	table: {
		minWidth: 250,
	},
});

function createData(scheme, api) {
	return { scheme, api };
}

const rows = [
	createData('ga:date', 'Date'),
	createData('ga:medium', '"cpc"'),
	createData('ga:source', '"yandex"'),
	createData('ga:adClicks', 'Clicks'),
	createData('ga:impressions', 'Impressions'),
	createData('ga:adCost', 'Cost'),
	createData('ga:campaign', 'CampaignName'),
	createData('ga:keyword', 'Criteria'),
	createData(
		'ga:adContent',
		parse(
			`<a target="_blank" href="https://yandex.com/dev/direct/doc/ref-v5/ads/get.html">TextAd.Title</a>`,
		),
	),
];

export default function YandexApiGaMappingInfo() {
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell align="left">
							<b>Ga Data Set Field</b>
						</TableCell>
						<TableCell align="left">
							<a
								target="_blank"
								href="https://yandex.ru/dev/direct/doc/reports/fields-list.html"
							>
								<b>Yandex.Direct Api Field</b>
							</a>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow key={row.scheme}>
							<TableCell align="left">{row.scheme}</TableCell>
							<TableCell align="left">{row.api}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
