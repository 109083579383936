export class SettingsError extends Error {
	constructor(message = 'SettingsError') {
		super(message);
		this.name = this.constructor.name;
	}
}

export class SettingsNotFoundError extends SettingsError {
	constructor(message = 'SettingsNotFound') {
		super(message);
		this.name = this.constructor.name;
	}
}

export class SettingsNotInitializedError extends SettingsError {
	constructor(message = 'SettingsNotInitialized') {
		super(message);
		this.name = this.constructor.name;
	}
}

export class SettingsModelStructureError extends SettingsError {
	constructor(message = 'SettingsStructureError') {
		super(message);
		this.name = this.constructor.name;
	}
}
