import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const Alert = props => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Notification = ({ open }) => {
	const notificationsStore = useSelector(
		state => state.notifications,
		shallowEqual,
	);

	return (
		<>
			{notificationsStore.show ? (
				<Snackbar
					open={true}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert severity={notificationsStore.type}>
						{notificationsStore.message}
					</Alert>
				</Snackbar>
			) : null}
		</>
	);
};

export default Notification;
