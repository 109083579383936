import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

/** Actions */
import { authCheckYaFetchStart } from 'actions/authActions';

/** Helpers */
import { handleYaAuthWindow } from 'helpers/auth';

const VkAuth = () => {
	const dispatch = useDispatch();

	const fetchAuthCheck = () => dispatch(authCheckYaFetchStart({}));

	window.authSuccess = function () {
		fetchAuthCheck();
	};

	const openYaAuth = useCallback(() => {
		handleYaAuthWindow(fetchAuthCheck);
	}, []);

	return (
		<Grid
			container
			spacing={0}
			alignItems="center"
			justify="center"
			style={{ width: '750px' }}
		>
			<Grid item xs={3}>
				<Button onClick={openYaAuth}>
					<img src="/resources/SignInWithYandex.jpg" width="300" />
				</Button>
			</Grid>
		</Grid>
	);
};

export default VkAuth;
