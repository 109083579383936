import React, { useState } from 'react';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';

/** Other components */
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

/** Custom components */
import ProgressForButton from 'components/common/CircularProgressStyled';

/** Sub components */

/** Actions */

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

const EmailTrackingItem = ({
	emailTrackerItem,
	SettingsModel,
	isFetching,
	setLoadingState,
	loadingState,
}) => {
	const { t } = useTranslationWithParse();

	const [emailTrackingItemEditState, setEmailTrackingItemEditState] = useState(
		{},
	);

	const emailTrackingItemEdit = (field, event) => {
		setEmailTrackingItemEditState({
			...emailTrackingItemEditState,
			[field]: event.target.value,
		});
	};

	const deleteEmailTrackingItem = () => {
		setEmailTrackingItemEditState({});
		setLoadingState({
			...loadingState,
			email_tracking: { [emailTrackerItem.id]: true },
		});
		SettingsModel.deleteEmailTracking(emailTrackerItem.id);
		SettingsModel.saveSettings();
	};

	const emailTrackingSave = () => {
		SettingsModel.emailTracking = {
			id: emailTrackerItem.id || null,
			className:
				emailTrackingItemEditState.className ||
				emailTrackerItem.className ||
				'',
			email: emailTrackingItemEditState.email || emailTrackerItem.email || '',
		};

		if (emailTrackerItem?.id) {
			setLoadingState({
				...loadingState,
				email_tracking: { [emailTrackerItem.id]: true },
			});
		}

		setEmailTrackingItemEditState({});
		SettingsModel.saveSettings();
	};

	const isEmailTrackingFetching = isFetching(
		'email_tracking',
		emailTrackerItem.id,
	);

	const classNameValue =
		'className' in emailTrackingItemEditState
			? emailTrackingItemEditState.className
			: emailTrackerItem.className;

	const emailValue =
		'email' in emailTrackingItemEditState
			? emailTrackingItemEditState.email
			: emailTrackerItem.email;

	const tt = t('Generator description');

	let errorClassNameText =
		classNameValue.length === 0 ? t('Class name warning epmty') : '';

	let errorEmailText = !/(#ID#)/.test(emailValue)
		? t('Email track need ID')
		: '';

	errorEmailText = !/(.+)@(.+){2,}\.(.+){2,}/.test(emailValue)
		? t('Email track empty')
		: errorEmailText;

	return (
		<TableRow style={{ height: 155 }}>
			{isFetching('email_tracking', emailTrackerItem.id) ? (
				<>
					<TableCell>
						<Skeleton animation={'wave'} />
					</TableCell>
					<TableCell>
						<Skeleton animation={'wave'} />
					</TableCell>
				</>
			) : (
				<>
					<TableCell>
						<InputLabel>Class Name</InputLabel>
						<TextField
							error={errorClassNameText}
							value={classNameValue}
							label=""
							margin="normal"
							variant="outlined"
							onChange={emailTrackingItemEdit.bind(this, 'className')}
							style={{ borderColor: 'red' }}
							disabled={false}
							helperText={errorClassNameText}
							style={{ height: 80 }}
						/>
					</TableCell>
					<TableCell>
						<InputLabel>Email</InputLabel>
						<TextField
							error={errorEmailText}
							value={emailValue}
							label=""
							margin="normal"
							variant="outlined"
							onChange={emailTrackingItemEdit.bind(this, 'email')}
							style={{ borderColor: 'red' }}
							disabled={false}
							helperText={errorEmailText}
							style={{ height: 80 }}
						/>
					</TableCell>
				</>
			)}

			<TableCell style={{ width: 30 }}>
				<Button
					onClick={emailTrackingSave}
					color={'secondary'}
					disabled={
						!classNameValue ||
						!emailValue ||
						isEmailTrackingFetching ||
						errorEmailText
					}
				>
					<SaveIcon />
					{isEmailTrackingFetching ? <ProgressForButton size={24} /> : null}
				</Button>
				{emailTrackerItem?.id != 'default' ? (
					<Button color="inherit" onClick={deleteEmailTrackingItem}>
						<ClearIcon />
					</Button>
				) : (
					<Box style={{ height: 32 }} />
				)}
			</TableCell>
		</TableRow>
	);
};

export default EmailTrackingItem;
