import { call, put, take } from 'redux-saga/effects';

import { getAdsAccounts } from 'api/ya';

import {
	yaAdsAccountsFetchSuccess,
	yaAdsAccountsFetchError,
	YA_ADS_ACCOUNTS_FETCH_START,
} from 'actions/yaActions';

export function* yaAdsAccountsFlow() {
	while (true) {
		yield take(YA_ADS_ACCOUNTS_FETCH_START);

		try {
			const data = yield call(getAdsAccounts);
			yield put(yaAdsAccountsFetchSuccess(data));
		} catch (error) {
			yield put(yaAdsAccountsFetchError(error));
		}
	}
}
