import { dealSettingsUpdate, dealSettingsGet, dealCategoryList, dealCategoryStageList } from '../api/deals';

export const SOURCES_FETCH_START = 'SOURCES_FETCH_START';
export const SOURCES_FETCH_SUCCESS = 'SOURCES_FETCH_SUCCESS';
export const SOURCES_FETCH_ERROR = 'SOURCES_FETCH_ERROR';

export const sourcesFetchStart = (payload) => {
    return {
        type: SOURCES_FETCH_START,
        payload
    }
}

export const sourcesFetchSuccess = (payload) => {
    return {
        type: SOURCES_FETCH_SUCCESS,
        payload
    }
}

export const sourcesFetchError = (payload) => {
    return {
        type: SOURCES_FETCH_ERROR,
        payload
    }
}