export class RestApiError extends Error {
  constructor(restApiError) {
    super("Rest Api B24 Error");
    this.name = this.constructor.name;

    this.rest_error = restApiError.data;
    this.error_description = restApiError.error_description;
    this.status = restApiError.status;
  }
}
