import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Tables  */
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

/** Icons  */
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import AttachmentIcon from '@material-ui/icons/Attachment';

/** Other components */
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Skeleton from '@material-ui/lab/Skeleton';

/** Custom component */
import SaveButton from 'components/common/SaveButton';

/** Sub components */
/** Actions */
/** API */

/** Helpers */
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	tableContainer: {
		height: 300,
		maxHeight: 300,
	},
	cellName: {
		width: '80%',
	},
	cellValue: {
		width: '10%',
	},
	cellAction: {
		width: '10%',
		textAlign: 'right',
	},
	option: {
		margin: 10,
		fontSize: '16px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		cursor: 'pointer',
	},
});

const CustomDimensions = ({
	SettingsModel,
	settingKey,
	deleteHandler,
	label,
	description,
	userFiledsList,
	isFetching,
	loadingState,
	setLoadingState,
}) => {
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const [cdUserFieldState, setCdUserFieldState] = useState('');
	const [cdValueState, setCdValueState] = useState('');
	const [saveCdEnabled, setSaveCdEnabled] = useState(false);

	const userFieldsFiltred = useMemo(() => {
		//!!!!! IMMMER
		let userFields = userFiledsList;

		userFields.items.forEach((field, i) => {
			userFields.items[i] = {
				...userFields.items[i],
				...SettingsModel[settingKey].find(setting => setting.ID == field.ID),
			};
		});

		return userFields;
	}, [userFiledsList]);

	const customDimensionsDelete = useCallback(
		id => {
			SettingsModel[deleteHandler](id);

			setLoadingState({
				...loadingState,
				[settingKey]: { [id]: true },
			});

			SettingsModel.saveSettings();
		},
		[SettingsModel],
	);

	const customDimensionsSave = useCallback(() => {
		SettingsModel[settingKey] = [
			{
				name: userFieldsFiltred.items.find(item => item.ID == cdUserFieldState)
					.EDIT_FORM_LABEL[CONFIG.LANG.toLocaleLowerCase()],
				id: cdUserFieldState,
				cd: cdValueState,
			},
		];

		setLoadingState({
			...loadingState,
			[`${settingKey}Btn`]: true,
		});

		setCdUserFieldState('');
		setCdValueState('');

		SettingsModel.saveSettings();
	}, [cdUserFieldState, cdValueState, userFieldsFiltred]);

	const customDimensionsEdit = useCallback((field, event) => {
		if (field == 'userfield') setCdUserFieldState(event.target.value);
		if (field == 'cd') setCdValueState(event.target.value);
	});

	useEffect(() => {
		cdUserFieldState && cdValueState
			? setSaveCdEnabled(true)
			: setSaveCdEnabled(false);
	}, [cdUserFieldState, cdValueState]);
	/** CUSTOM DEMENSIONS */

	console.log('CustomDimensions Render');

	return (
		<span>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h5">
						{label}
					</Typography>
					<div className="blockquote-warning">
						<p> ℹ️ {description}</p>
					</div>
				</Grid>
				<Grid item xs={12}>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell align="left">
										<FormControl variant="outlined">
											<InputLabel htmlFor="outlined-age-native-simple">
												{t('Field')}
											</InputLabel>
											<Select
												native
												value={cdUserFieldState}
												onChange={customDimensionsEdit.bind(this, 'userfield')}
												label={t('Field')}
												style={{ width: 210 }}
												disabled={isFetching(settingKey, false)}
											>
												<option aria-label="None" value="" />
												{userFiledsList.items.map((field, i) =>
													!SettingsModel[settingKey].some(
														item => item.id == field.ID,
													) ? (
															<option
																value={field.ID}
																key={i}
																className={classes.option}
															>
																{
																	field.EDIT_FORM_LABEL[
																	CONFIG.LANG.toLocaleLowerCase()
																	]
																}
															</option>
														) : null,
												)}
											</Select>
										</FormControl>
									</TableCell>
									<TableCell>
										<AttachmentIcon align="center" />
									</TableCell>
									<TableCell>
										<FormControl variant="outlined">
											<InputLabel htmlFor="outlined-age-native-simple">
												CD
											</InputLabel>
											<Select
												native
												value={cdValueState}
												onChange={customDimensionsEdit.bind(this, 'cd')}
												label="CD"
												style={{ width: 90 }}
												disabled={isFetching(settingKey, false)}
											>
												<option aria-label="None" value="" />
												{[
													'cd1',
													'cd2',
													'cd3',
													'cd4',
													'cd5',
													'cd7',
													'cd8',
													'cd9',
													'cd10',
													'cd11',
													'cd12',
													'cd13',
													'cd14',
													'cd15',
													'cd16',
													'cd13',
													'cd14',
													'cd15',
													'cd16',
													'cd17',
													'cd18',
													'cd19',
													'cd20',
												].map((cd, i) =>
													!SettingsModel[settingKey].some(
														item => item.cd === cd,
													) ? (
															<option
																value={cd}
																key={i}
																className={classes.option}
															>
																{cd}
															</option>
														) : null,
												)}
											</Select>
										</FormControl>
									</TableCell>
									<TableCell>
										<SaveButton
											handler={customDimensionsSave}
											disabled={!saveCdEnabled}
											isFetching={isFetching(`${settingKey}Btn`)}
											label={'Сохранить'}
										/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					{SettingsModel[settingKey].length == 0 ? (
						<TableContainer>
							<Table stickyHeader size="small" aria-label="sticky table">
								<TableBody>
									<TableRow>
										<TableCell>
											<div align="center">
												🐼 No data
												<br />
											</div>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					) : (
							<TableContainer>
								<Table stickyHeader size="small" aria-label="sticky table">
									<TableBody>
										{SettingsModel[settingKey].map(row =>
											isFetching(settingKey, row.id, 'debug') ? (
												<TableRow key={row.cd}>
													<TableCell>
														<Skeleton animation={'wave'} />
													</TableCell>
													<TableCell>
														<Skeleton animation={'wave'} />
													</TableCell>
													<TableCell style={{ width: 10 }}>
														<Button color={'default'} size={'small'} disabled>
															<DeleteSweepIcon />
														</Button>
													</TableCell>
												</TableRow>
											) : (
													<TableRow key={row.cd}>
														<TableCell className={classes.cellName}>
															{row.name}
														</TableCell>
														<TableCell className={classes.cellValue}>
															{row.cd}
														</TableCell>
														<TableCell className={classes.cellAction}>
															<Button
																color={'default'}
																size={'small'}
																onClick={customDimensionsDelete.bind(this, row.id)}
																disabled={isFetching(settingKey, false)}
															>
																<DeleteSweepIcon />
															</Button>
														</TableCell>
													</TableRow>
												),
										)}
									</TableBody>
								</Table>
							</TableContainer>
						)}
				</Grid>
			</Grid>
		</span>
	);
};

export default CustomDimensions;
