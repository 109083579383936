import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';

const TableCellStyled = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.common.white,
		fontSize: 12,
		fontWeight: 'bold',
		textAlign: 'center',
	},
	body: {
		fontSize: 14,
		height: 80,
		textAlign: 'center',
	},
}))(TableCell);

export default TableCellStyled;
