import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

import parse from 'html-react-parser';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Custom components */
import { GoalDragLayer } from 'components/common/CustomDragLayer';

/** Sub components */
import Category from 'components/pages/Crm/Category';
import CustomDimensions from 'components/pages/Crm/CustomDimensions';
import GaCid from 'components/pages/Crm/GaCid';
import GaUid from 'components/pages/Crm/GaUid';
import GaCounter from 'components/pages/Crm/GaCounter';

/** Actions */
import {
	dealsCategoryFetchStart,
	dealsUserFieldsFetchStart,
	dealsCategoryStageFetchStart,
} from 'actions/dealsActions';
import { settingsUpdateStart } from 'actions/settingsActions';

/** API */

/** Helpers */
import { Settings } from 'models/settings';
import { checkFetching } from 'helpers/utils';
import { useTranslationWithParse } from 'helpers/translation';

const Deals = () => {
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const fetchDealsCategory = () => dispatch(dealsCategoryFetchStart());
	const fetchDealsUserFields = () => dispatch(dealsUserFieldsFetchStart());
	const fetchDealsCategoryStage = payload =>
		dispatch(dealsCategoryStageFetchStart(payload));

	useEffect(() => {
		fetchDealsCategory();
		fetchDealsUserFields();
	}, []);

	/** IMMER ?? */
	const settingsList = useSelector(state => state.settings);
	const categoryList = useSelector(state => state.deals_category);
	const stageList = useSelector(state => state.deals_category_stage);
	const userFiledsList = useSelector(state => state.deals_user_fields);

	/** !!!!!!!!!!!! https://kentcdodds.com/blog/prop-drilling  */
	const SettingsModel = new Settings(settingsList, {
		update: payload => dispatch(settingsUpdateStart(payload)),
	});

	const userFieldsListInUse = useMemo(() => [
		SettingsModel.dealsGaCidField,
		SettingsModel.dealsGaUidField,
		SettingsModel.dealsGaCounterField,
	]);

	const defaultLoadingState = {
		dealsCd: {},
		dealsCdBtn: false,
		dealsGaCidField: false,
		dealsGaUidField: false,
		dealsGaCounterField: false,
		dealsStages: {},
		dealsCategories: {},

		categoryGaAccount: {},
		categoryWebProperty: {},
		categoryProfile: {},
		categoryGoals: {},
		categoryStream: {},
		categoryMeasurementProtocolSecret: {},
	};

	const [loadingState, setLoadingState] = useState(defaultLoadingState);

	useEffect(() => {
		if (!settingsList.fetching) {
			setLoadingState(defaultLoadingState);
		}
	}, [settingsList.fetching]);

	const isFetching = checkFetching(loadingState);

	console.log('Deals Render');

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<DndProvider
					backend={TouchBackend}
					options={{ enableMouseEvents: true }}
				>
					<GoalDragLayer />
					<Category
						SettingsModel={SettingsModel}
						categoryList={categoryList}
						stageList={stageList}
						fetchCategoryStage={fetchDealsCategoryStage}
						settingKeyCategories={'dealsCategories'}
						settingKeyStages={'dealsStages'}
						settingsKeyCounter={'dealsGaCounterField'}
						label={t('Deals Event Settings')}
						description={t(
							'Select the direction (funnel) of deals for setting Google Analytics goals',
						)}
						userFiledsList={userFiledsList}
						isFetching={isFetching}
						loadingState={loadingState}
						setLoadingState={setLoadingState}
					/>
				</DndProvider>
			</Grid>
			<Grid item xs={6}>
				<GaCid
					SettingsModel={SettingsModel}
					settingKey={'dealsGaCidField'}
					label={t('Client Id field')}
					description={t('Client Id field description')}
					userFiledsList={userFiledsList}
					isFetching={isFetching}
					loadingState={loadingState}
					setLoadingState={setLoadingState}
					userFieldsListInUse={userFieldsListInUse}
				/>
			</Grid>
			{/** 
			<Grid item xs={6}>
				<GaCounter
					SettingsModel={SettingsModel}
					settingKey={'dealsGaCounterField'}
					label={t('Tid field')}
					description={t('Tid field description')}
					userFiledsList={userFiledsList}
					isFetching={isFetching}
					loadingState={loadingState}
					setLoadingState={setLoadingState}
					userFieldsListInUse={userFieldsListInUse}
				/>
			</Grid>
			<Grid item xs={6}>
				<GaUid
					SettingsModel={SettingsModel}
					settingKey={'dealsGaUidField'}
					label={t('User Id field')}
					description={t('User Id function description')}
					userFiledsList={userFiledsList}
					isFetching={isFetching}
					loadingState={loadingState}
					setLoadingState={setLoadingState}
					userFieldsListInUse={userFieldsListInUse}
				/>
			</Grid>
			<Grid item xs={6}>
				<CustomDimensions
					SettingsModel={SettingsModel}
					settingKey={'dealsCd'}
					deleteHandler={'dealsCdDelete'}
					label={t('Custom dimension field')}
					description={t('Custom dimension field desctiption')}
					userFiledsList={userFiledsList}
					isFetching={isFetching}
					loadingState={loadingState}
					setLoadingState={setLoadingState}
				/>
			</Grid>
			*/}
		</Grid>
	);
};

export default Deals;
