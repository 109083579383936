import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware, { END } from 'redux-saga';
import { createLogger } from 'redux-logger';

import defaultReducer from 'reducers/defaultReducer';
import rootSaga from 'sagas/root';

/*
import noticeMiddleware from '../middlewares/noticeMiddleware';
import generalMiddleware from '../middlewares/generalMiddleware';
*/
const logger =
	process.env.NODE_ENV === 'production' ? createLogger() : createLogger();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
	logger,
	thunk,
	sagaMiddleware,
	//generalMiddleware
].filter(Boolean);

const store = createStore(defaultReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);
//store.close = () => store.dispatch(END);

export default store;
