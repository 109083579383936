import {
	GA_MEASUREMENT_PROTOCOL_SECRETS_FETCH_START,
	GA_MEASUREMENT_PROTOCOL_SECRETS_FETCH_SUCCESS,
	GA_MEASUREMENT_PROTOCOL_SECRETS_FETCH_ERROR,
} from 'actions/gaActions';

export const initialState = {
	items: [],
};

export default (state = { ...initialState, fetching: false }, action) => {
	switch (action.type) {
		case GA_MEASUREMENT_PROTOCOL_SECRETS_FETCH_START:
			return {
				...state,
				fetching: true,
			};

		case GA_MEASUREMENT_PROTOCOL_SECRETS_FETCH_SUCCESS:
			return {
				...state,
				items: action.payload,
				fetching: false,
			};

		case GA_MEASUREMENT_PROTOCOL_SECRETS_FETCH_ERROR:
			return {
				...state,
				fetching: false,
			};
		default:
			return state;
	}
};
