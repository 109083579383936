import { takeEvery, fork, call, put, take, delay } from 'redux-saga/effects';

import { get } from '../api/trackId';

import {
	trackIdFetchSuccess,
	trackIdFetchError,
	trackIdNotFound,
	TRACK_ID_FETCH_START,
} from 'actions/trackIdActions';

import { dealCrmFieldUpdateStart } from 'actions/dealsActions';
import { showNotification } from 'helpers/notifications';

export function* trackIdGetFlow() {
	while (true) {
		const { payload } = yield take(TRACK_ID_FETCH_START);

		try {
			const result = yield call(get, payload);

			if (result.data.success == 'true') {
				yield put(trackIdFetchSuccess(result.data));

				yield fork(showNotification, 'SUCCESS', 'Track found!');

				yield put(
					dealCrmFieldUpdateStart({
						data: result.data,
						entity_id: payload.entity_id,
					}),
				);
			}

			if (result.data.success == 'false') {
				yield fork(showNotification, 'WARNING', 'Track Id does not found');
				yield put(trackIdNotFound(result.data));
			}
		} catch (error) {
			yield fork(
				showNotification,
				'ERROR',
				`${error.name}: ${error.error_description}`,
			);
			yield put(trackIdFetchError(error));
		}
	}
}
