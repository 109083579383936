import {
	LOGS_FETCH_START,
	LOGS_FETCH_SUCCESS,
	LOGS_FETCH_ERROR,
} from 'actions/logsActions';

export const initialState = {
	items: [],
};

export default (state = { ...initialState, fetching: false }, action) => {
	switch (action.type) {
		case LOGS_FETCH_START:
			return {
				...state,
				fetching: true,
			};

		case LOGS_FETCH_SUCCESS:
			return {
				...state,
				items: [...action.payload],
				fetching: false,
			};

		case LOGS_FETCH_ERROR:
			return {
				...state,
				fetching: false,
			};
		default:
			return state;
	}
};
