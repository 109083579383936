import { call, put, take, fork } from 'redux-saga/effects';

import { getAdsAccounts } from 'api/fb';

import {
	fbAdsAccountsFetchSuccess,
	fbAdsAccountsFetchError,
	FB_ADS_ACCOUNTS_FETCH_START,
} from 'actions/fbActions';

export function* fbAdsAccountsFlow() {
	while (true) {
		yield take(FB_ADS_ACCOUNTS_FETCH_START);

		try {
			const data = yield call(getAdsAccounts);
			yield put(fbAdsAccountsFetchSuccess(data));
		} catch (error) {
			yield put(fbAdsAccountsFetchError(error));
		}
	}
}
