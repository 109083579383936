import { call, put, take, fork } from 'redux-saga/effects';

import { gaAuthCheck, gaAuthRevoke } from '../api/ga';
import { fbAuthCheck, fbAuthRevoke } from '../api/fb';
import { vkAuthCheck, vkAuthRevoke } from '../api/vk';
import { yaAuthCheck, yaAuthRevoke } from '../api/ya';
import {
	authCheckGaFetchStart,
	authCheckGaFetchSuccess,
	authCheckGaFetchError,
	AUTH_CHECK_GA_FETCH_START,
	authRevokeGaFetchSuccess,
	authRevokeGaFetchError,
	AUTH_REVOKE_GA_FETCH_START,
	authCheckFbFetchStart,
	authCheckFbFetchSuccess,
	authCheckFbFetchError,
	AUTH_CHECK_FB_FETCH_START,
	authRevokeFbFetchSuccess,
	authRevokeFbFetchError,
	AUTH_REVOKE_FB_FETCH_START,
	authCheckVkFetchStart,
	authCheckVkFetchSuccess,
	authCheckVkFetchError,
	AUTH_CHECK_VK_FETCH_START,
	authRevokeVkFetchSuccess,
	authRevokeVkFetchError,
	AUTH_REVOKE_VK_FETCH_START,
	authCheckYaFetchStart,
	authCheckYaFetchSuccess,
	authCheckYaFetchError,
	AUTH_CHECK_YA_FETCH_START,
	authRevokeYaFetchSuccess,
	authRevokeYaFetchError,
	AUTH_REVOKE_YA_FETCH_START,
} from 'actions/authActions';

import { settingsUpdateStart } from 'actions/settingsActions';
import { initialState as defaultSettings } from 'reducers/settingsReducer';

import { showNotification } from 'helpers/notifications';

export function* gaAuthCheckFlow() {
	while (true) {
		yield take(AUTH_CHECK_GA_FETCH_START);

		try {
			yield call(gaAuthCheck);

			yield put(authCheckGaFetchSuccess({}));
		} catch (error) {
			yield put(authCheckGaFetchError(error));

			if (
				'response' in error &&
				'data' in error.response &&
				'error' in error.response.data
			)
				yield fork(showNotification, 'WARNING', error.response.data.error);
		}
	}
}

export function* gaAuthRevokeFlow() {
	while (true) {
		yield take(AUTH_REVOKE_GA_FETCH_START);

		try {
			yield call(gaAuthRevoke);
			yield put(authCheckGaFetchStart({}));

			/**
			 * Reset all settings
			 */
			yield put(settingsUpdateStart(defaultSettings));

			yield put(authRevokeGaFetchSuccess({}));
		} catch (error) {
			yield put(authRevokeGaFetchError(error));
		}
	}
}

export function* fbAuthCheckFlow() {
	while (true) {
		yield take(AUTH_CHECK_FB_FETCH_START);

		try {
			yield call(fbAuthCheck);

			yield put(authCheckFbFetchSuccess({}));
		} catch (error) {
			yield put(authCheckFbFetchError(error));
		}
	}
}

export function* fbAuthRevokeFlow() {
	while (true) {
		yield take(AUTH_REVOKE_FB_FETCH_START);

		try {
			yield call(fbAuthRevoke);
			yield put(authCheckFbFetchStart({}));

			yield put(authRevokeFbFetchSuccess({}));
		} catch (error) {
			yield put(authRevokeFbFetchError(error));
		}
	}
}

export function* vkAuthCheckFlow() {
	while (true) {
		yield take(AUTH_CHECK_VK_FETCH_START);

		try {
			yield call(vkAuthCheck);

			yield put(authCheckVkFetchSuccess({}));
		} catch (error) {
			yield put(authCheckVkFetchError(error));
		}
	}
}

export function* vkAuthRevokeFlow() {
	while (true) {
		yield take(AUTH_REVOKE_VK_FETCH_START);

		try {
			yield call(vkAuthRevoke);
			yield put(authCheckVkFetchStart({}));

			yield put(authRevokeVkFetchSuccess({}));
		} catch (error) {
			yield put(authRevokeVkFetchError(error));
		}
	}
}

export function* yaAuthCheckFlow() {
	while (true) {
		yield take(AUTH_CHECK_YA_FETCH_START);

		try {
			yield call(yaAuthCheck);

			yield put(authCheckYaFetchSuccess({}));
		} catch (error) {
			yield put(authCheckYaFetchError(error));
		}
	}
}

export function* yaAuthRevokeFlow() {
	while (true) {
		yield take(AUTH_REVOKE_YA_FETCH_START);

		try {
			yield call(yaAuthRevoke);
			yield put(authCheckYaFetchStart({}));

			yield put(authRevokeYaFetchSuccess({}));
		} catch (error) {
			yield put(authRevokeYaFetchError(error));
		}
	}
}
