import { RestApiError } from 'error/restError';
import axios from 'axios';
import qs from 'qs';
import cookie from 'helpers/cookie';

export function leadCrmFieldUpdate(payload) {
	const params = {
		id: payload.id,
		fields: payload.fields,
	};

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/lead_crm_field_update`,
		data: qs.stringify({ params }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}

export function leadCategoryList() {
	const params = {
		order: { SORT: 'ASC' },
		filter: {},
		select: ['ID', 'NAME', 'SORT'],
	};

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/lead_category_list`,
		data: qs.stringify({ params }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}

export function leadCategoryStageList() {
	const params = {
		order: { SORT: 'ASC' },
		filter: { ENTITY_ID: 'STATUS' },
	};

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/lead_category_stage_list`,
		data: qs.stringify({ params }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}

export function leadUserFieldsList(supportedTypes = false) {
	const params = { order: { ID: 'ASC' } };
	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/lead_crm_user_field_list`,
		data: qs.stringify({ params }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			let data = response.data.data;
			if (supportedTypes) {
				for (let i = 0; i <= data.length - 1; i++) {
					if (supportedTypes.indexOf(data[i].USER_TYPE_ID) === -1)
						data.splice(i, 1);
				}
			}

			response.data.data = data;

			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}

export function leadUserFieldGetBatch(ids) {
	const batchParams = ids.map(id => ({ id }));

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_B242GA_API}/lead_crm_user_field_get_batch`,
		data: qs.stringify({ batchParams }),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
	}).then(
		response => {
			return response.data;
		},
		error => {
			throw new RestApiError(error);
		},
	);
}
