export const LOGS_FETCH_START = 'LOGS_FETCH_START';
export const LOGS_FETCH_SUCCESS = 'LOGS_FETCH_SUCCESS';
export const LOGS_FETCH_ERROR = 'LOGS_FETCH_ERROR';

export const logsFetchStart = (payload) => {
    return {
        type: LOGS_FETCH_START,
        payload
    }
}

export const logsFetchSuccess = (payload) => {
    return {
        type: LOGS_FETCH_SUCCESS,
        payload
    }
}

export const logsFetchError = (payload) => {
    return {
        type: LOGS_FETCH_ERROR,
        payload
    }
}






