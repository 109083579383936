import {
	takeEvery,
	call,
	put,
	take,
	fork,
	cancel,
	select,
	cancelled,
	delay,
} from 'redux-saga/effects';

import {
	SettingsNotFoundError,
	SettingsNotInitializedError,
} from 'error/settingsError';

import { settingsGet, settingsInit, settingsUpdate } from 'api/settings';
import { placementBind, placementUnbind, placementsGet } from 'api/placement';

import {
	settingsGetSuccess,
	settingsGetError,
	settingsGetStart,
	settingsInitSuccess,
	settingsInitError,
	settingsInitStart,
	settingsUpdateSuccess,
	settingsUpdateError,
	SETTINGS_GET_START,
	SETTINGS_INIT_START,
	SETTINGS_UPDATE_START,
	SETTINGS_GET_SUCCESS,
} from 'actions/settingsActions';

export function* settingsInitFlow() {
	while (true) {
		yield take(SETTINGS_INIT_START);

		try {
			const data = yield call(settingsInit);
			yield put(settingsInitSuccess(data));
			yield put(settingsGetStart({}));
		} catch (error) {
			yield put(settingsInitError(error.message));
		}
	}
}

export function* settingsListFlow() {
	while (true) {
		yield take(SETTINGS_GET_START);

		try {
			const data = yield call(settingsGet);

			yield put(settingsGetSuccess(data));
		} catch (error) {
			if (error instanceof SettingsNotFoundError)
				yield put(settingsInitStart({}));

			if (error instanceof SettingsNotInitializedError)
				yield put(settingsInitStart({}));

			yield put(settingsGetError(error));
		}
	}
}

export function* settingsPlacementFlow() {
	while (true) {
		yield take(SETTINGS_GET_SUCCESS);

		try {
			const state = yield select();
			const placements = yield call(placementsGet);
			const CRM_LEAD_DETAIL_TAB = 'CRM_LEAD_DETAIL_TAB';
			const CRM_DEAL_DETAIL_TAB = 'CRM_DEAL_DETAIL_TAB';

			if (
				'settings' in state &&
				'track_id' in state.settings &&
				state.settings.track_id
			) {
				if (!placements.some(item => item.placement == CRM_LEAD_DETAIL_TAB))
					yield call(placementBind, CRM_LEAD_DETAIL_TAB);

				if (!placements.some(item => item.placement == CRM_DEAL_DETAIL_TAB))
					yield call(placementBind, CRM_DEAL_DETAIL_TAB);
			}

			if (
				'settings' in state &&
				'track_id' in state.settings &&
				!state.settings.track_id
			) {
				if (placements.some(item => item.placement == CRM_LEAD_DETAIL_TAB))
					yield call(placementUnbind, CRM_LEAD_DETAIL_TAB);

				if (placements.some(item => item.placement == CRM_DEAL_DETAIL_TAB))
					yield call(placementUnbind, CRM_DEAL_DETAIL_TAB);
			}
		} catch (error) {
			yield put(settingsGetError(error));
		}
	}
}

export function* settingsUpdateFlow() {
	while (true) {
		let { payload } = yield take(SETTINGS_UPDATE_START);

		try {
			const result = yield call(settingsUpdate, payload);

			yield put(settingsGetStart({}));
			yield take(SETTINGS_GET_SUCCESS);
			yield put(settingsUpdateSuccess(result.data));
		} catch (error) {
			yield put(settingsUpdateError(error));
		}
	}
}
