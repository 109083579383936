export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE';

export const notificationSuccess = (payload) => {
    payload.type = 'success';
    return {
        type: NOTIFICATION_SHOW,
        payload
    }
}

export const notificationWarning = (payload) => {
    payload.type = 'warning';

    return {
        type: NOTIFICATION_SHOW,
        payload
    }
}

export const notificationError = (payload) => {
    payload.type = 'error';
    return {
        type: NOTIFICATION_SHOW,
        payload
    }
}

export const notificationHide = () => {
    return {
        type: NOTIFICATION_HIDE
    }
}








