import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';

/** Other components */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';

/** Sub components */

/** Actions */
import { yaAdsAccountsFetchStart } from 'actions/yaActions';

/** Helpers */
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const YandexForm = ({
	cost,
	isFetching,
	SettingsModel,
	loadingState,
	setLoadingState,
	setSourceStepsCompletedState,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const [activeStep, setActiveStep] = useState(0);
	const [stepEditState, setStepEditState] = useState(false);
	const [yaAdsAccountIdState, setYaAdsAccountIdState] = useState(false);

	const yaAdsAccountIdSettings = useMemo(
		() => cost.sourceData.accountId || null,
	);

	const fetchAdsAccounts = () => dispatch(yaAdsAccountsFetchStart({}));

	useEffect(() => {
		fetchAdsAccounts();
	}, []);

	const yaAdsAccountsIsFetching = useSelector(state => {
		return !!state.ya_ads_accounts.fetching;
	});

	const yaAdsAccountList = useSelector(state => state.ya_ads_accounts.items);

	useEffect(() => {
		if ('0' in yaAdsAccountList) {
			setYaAdsAccountIdState(yaAdsAccountList[0].id);
			SettingsModel.costs = {
				id: cost.id,
				source: CONFIG.COST_SOURCE_YANDEX,
				sourceData: {
					accountId: yaAdsAccountList[0].id,
					accountName: yaAdsAccountList[0].name,
				},
			};

			setStepEditState(false);

			setLoadingState({
				...loadingState,
				costs: { [cost.id]: true },
			});

			SettingsModel.saveSettings();
			setStepEditState(false);
		}
	}, [yaAdsAccountList]);

	const yaAccountIdSave = () => {
		const { name: yaAdsAccountName = '' } = yaAdsAccountList.find(
			account => account.id === yaAdsAccountIdState,
		);

		SettingsModel.costs = {
			id: cost.id,
			source: CONFIG.COST_SOURCE_YANDEX,
			sourceData: {
				accountId: yaAdsAccountIdState,
				accountName: yaAdsAccountName,
			},
		};

		setStepEditState(false);

		setLoadingState({
			...loadingState,
			costs: { [cost.id]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(false);
	};

	const backToYaAdsAccount = () => {
		setStepEditState(0);
	};

	const yaAdsAccountIdEdit = useCallback(event => {
		setYaAdsAccountIdState(event.target.value);
	});

	useEffect(() => {
		if (yaAdsAccountIdSettings && stepEditState !== 0 && !isFetching) {
			setActiveStep(1);
		}

		if (stepEditState === 0) setActiveStep(0);
	});

	useEffect(() => {
		setSourceStepsCompletedState(!!yaAdsAccountIdSettings);
	}, [yaAdsAccountIdSettings]);

	const yaAdsAccountValue = useMemo(() => {
		return yaAdsAccountIdState !== false
			? yaAdsAccountIdState
			: yaAdsAccountIdSettings
				? yaAdsAccountIdSettings
				: 'default';
	}, [yaAdsAccountIdState, yaAdsAccountIdSettings]);

	const yaAccountIdSaveDisabled = useMemo(() => {
		return (
			!yaAdsAccountIdState ||
			yaAdsAccountIdState == yaAdsAccountIdSettings ||
			yaAdsAccountIdState == 'default' ||
			isFetching
		);
	}, [yaAdsAccountIdState, yaAdsAccountIdSettings, isFetching]);

	return (
		<Stepper activeStep={activeStep} orientation="vertical">
			<Step key={0}>
				{yaAdsAccountIdSettings &&
					yaAdsAccountList.some(
						account => account.id == yaAdsAccountIdSettings,
					) ? (
					<StepLabel>
						{
							yaAdsAccountList.find(
								account => account.id == yaAdsAccountIdSettings,
							)['name']
						}
						{yaAdsAccountIdSettings ? (
							<Button onClick={backToYaAdsAccount}>
								<EditIcon fontSize="small" />
							</Button>
						) : null}
					</StepLabel>
				) : (
					<StepLabel>Yandex Direct Ads account</StepLabel>
				)}
				<StepContent>
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">
							ads account
						</InputLabel>
						<Select
							label="ads account"
							style={{ width: 450 }}
							onChange={yaAdsAccountIdEdit}
							inputProps={{ 'aria-label': 'Without label' }}
							value={yaAdsAccountValue}
						>
							<MenuItem value="default">-</MenuItem>
							{yaAdsAccountList.map((account, i) => (
								<MenuItem value={account.id} key={account.id}>
									{`${account.name}`}
								</MenuItem>
							))}
							{yaAdsAccountsIsFetching ? (
								<CircularProgressStyled size={24} />
							) : null}
						</Select>
					</FormControl>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={yaAccountIdSave}
								className={classes.button}
								disabled={yaAccountIdSaveDisabled}
							>
								{t('Save')}
								<SaveIcon />
								{isFetching ? <CircularProgressStyled size={24} /> : null}
							</Button>
						</div>
					</div>
				</StepContent>
			</Step>
		</Stepper>
	);
};

export default YandexForm;
