export const YA_ADS_ACCOUNTS_FETCH_START = 'YA_ADS_ACCOUNTS_FETCH_START';
export const YA_ADS_ACCOUNTS_FETCH_SUCCESS = 'YA_ADS_ACCOUNTS_FETCH_SUCCESS';
export const YA_ADS_ACCOUNTS_FETCH_ERROR = 'YA_ADS_ACCOUNTS_FETCH_ERROR';

export const yaAdsAccountsFetchStart = payload => {
	return {
		type: YA_ADS_ACCOUNTS_FETCH_START,
		payload,
	};
};

export const yaAdsAccountsFetchSuccess = payload => {
	return {
		type: YA_ADS_ACCOUNTS_FETCH_SUCCESS,
		payload,
	};
};

export const yaAdsAccountsFetchError = payload => {
	return {
		type: YA_ADS_ACCOUNTS_FETCH_ERROR,
		payload,
	};
};
