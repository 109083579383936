import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

/** Actions */
import { authCheckGaFetchStart } from 'actions/authActions';

const GaAuth = () => {
	const dispatch = useDispatch();

	const fetchAuthCheck = () => dispatch(authCheckGaFetchStart({}));

	window.authSuccess = function () {
		fetchAuthCheck();
	};

	const handleOpenGaAuth = useCallback(() => {
		let b24Auth = window.BX24.getAuth();

		window.localStorage.setItem('memeberId', '');

		if ('member_id' in b24Auth)
			window.localStorage.setItem('memeberId', b24Auth.member_id);

		window.localStorage.setItem(
			'gaClientId',
			process.env.REACT_APP_GA_CLIENT_ID,
		);

		window.localStorage.setItem(
			'gaRedirectUri',
			process.env.REACT_APP_GA_REDIRECT_URI,
		);

		let windowFeatures =
			'menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=500,height=500';
		window.open('/ga.html?memeberId=' + b24Auth.member_id + '&gaRedirectUri=' + process.env.REACT_APP_GA_REDIRECT_URI + '&gaClientId=' + process.env.REACT_APP_GA_CLIENT_ID, 'GoogleAuth', windowFeatures);
	});

	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justify="center"
			style={{ minHeight: '30vh' }}
		>
			<Grid item xs={3}>
				<Button onClick={handleOpenGaAuth}>
					<img src="/resources/SignInWithGoogle.png" width="300" />
				</Button>
			</Grid>
		</Grid>
	);
};

export default GaAuth;
